import { Utils } from '@riseart/utils';
import {
  Seller as SellerBaseModel,
  SellerPaginationResult as SellerPaginationResultBaseModel,
  SellerShippingTable as SellerShippingTableBaseModel,
  SellerShippingTableListResult as SellerShippingTableListResultBaseModel,
  SellerShippingTableRate as SellerShippingTableRateBaseModel,
  SellerShippingTableRateListResult as SellerShippingTableRateListResultBaseModel,
  SellerPaymentMethodType,
  SellerPaymentMethodTypeListResult as SellerPaymentMethodTypeListResultBaseModel,
  SellerPaymentMethodAttribute,
  SellerPaymentMethod as SellerPaymentMethodBaseModel,
  SellerPaymentMethodListResult as SellerPaymentMethodListResultBaseModel,
} from '@riseart/models';
import { FormDataMixin } from './Core';
import { ApiInvalidData } from '../errors/ApiInvalidData';
import { PaginationInfo, ListInfo } from './List';
import { seller as SELLER_ENUM } from '../../config/enumeration.js';

/**
 * Seller
 */
class Seller extends FormDataMixin(SellerBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {Seller}
   */
  public hydrateFromApiData(data?: Record<string, any>): Seller {
    try {
      // Deconstruct
      const { shipsCountry: shipsCountryData, seller: sellerData, ...entityData } = data || {};

      // Seller detail data
      const {
        store: storeData,
        country: countryData,
        defaultShippingOriginCountry: defaultShippingOriginCountryData,
        vatCountry: vatCountryData,
        postcode,
        ...sellerBaseData
      } = sellerData || {};
      const sellerFields: Array<string> = [
        'type',
        'status',
        'taxStatus',
        'street1',
        'street2',
        'city',
        'region',
        'vatCode',
        'companyName',
        'companyNumber',
        'contactName',
        'contactPhone',
        'contactEmail',
        'billingName',
        'billingPhone',
        'billingEmail',
        'shipsIn',
        'offers',
        'offerAutomaticAccept',
        'offerMaximumDiscount',
        'away',
        'awayFrom',
        'awayTo',
        'isCurrentlyAway',
        'shippingService',
        'canUseShippingTables',
      ];
      const sellerCoreData: Record<string, any> = {};

      Object.keys(sellerBaseData || {})
        .filter((key) => sellerFields.indexOf(key) >= 0)
        .forEach((key) => (sellerCoreData[key] = sellerBaseData[key]));

      // Hydrate model
      return this.hydrate({
        shipsCountryCode:
          (shipsCountryData && shipsCountryData.code) ||
          (defaultShippingOriginCountryData && defaultShippingOriginCountryData.code) ||
          null,
        storeCode: (storeData && storeData.code) || null,
        countryCode: (countryData && countryData.code) || null,
        postCode: postcode,
        vatCountryCode: (vatCountryData && vatCountryData.code) || null,
        ...sellerCoreData,
        ...entityData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Seller from provided data');
    }
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {Seller}
   */
  public hydrateFromFormData(formData: Record<string, any>): Seller {
    return this.hydrate({ id: this.id, ...formData });
  }

  /**
   * mapInputToApiPut
   *
   * @param {Record<string, any>} input
   * @returns {Record<string, any>}
   */
  public static mapInputToApiPut(input: Record<string, any>): Record<string, any> {
    const { inputSeller, ...inputData } = input || {};
    return { ...(inputData || {}), ...(inputSeller || {}) };
  }

  /**
   * toFormData
   *
   * @param {Record<string, any>[]} formFields
   * @returns {Record<string, any>}
   */
  public toFormData(formFields: Record<string, any>[]): Record<string, any> {
    return {
      ...formFields.reduce((accumulator, { name }) => {
        return {
          ...accumulator,
          [name]: typeof this[name] !== 'undefined' ? this[name] : null,
        };
      }, {}),
    };
  }

  /**
   * mapToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const apiData = Seller.mapFormToApiData(data, fieldsSchema, includeNullValues);

    // extract actual object id if entityId is object in form data
    if (apiData && apiData.entityId && typeof apiData.entityId === 'object') {
      apiData.entityId = apiData.entityId.id;
    }

    // Remove entityId if seller type is managed
    if (apiData.type === SELLER_ENUM.type.MANAGED) {
      apiData.entityId = null;
    }

    if (!apiData.away) {
      apiData.awayFrom = null;
      apiData.awayTo = null;
    }

    if (apiData.taxStatus !== SELLER_ENUM.tax.vat.status.REGISTERED) {
      apiData.vatCode = null;
      apiData.vatCountryCode = null;
    }

    return apiData;
  }
}

/**
 * SellerPaginationResult
 */
class SellerPaginationResult extends SellerPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {SellerPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): SellerPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<Seller> = itemsData
        ? itemsData.map((item: Record<string, any>) => new Seller().hydrateFromApiData(item))
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({ items, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load SellerPaginationResult from provided data');
    }
  }
}

/**
 * SellerShippingTable
 */
class SellerShippingTable extends FormDataMixin(SellerShippingTableBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {SellerShippingTable}
   */
  public hydrateFromApiData(data?: Record<string, any>): SellerShippingTable {
    try {
      // Deconstruct parameters
      const { parent: parentData, store: storeData, ...tableData } = data || {};

      // Parent table information
      let parentTable = {};
      if (parentData) {
        const { store: parentStoreData, ...parentTableData } = parentData;
        const parent = parentTableData
          ? Object.keys(parentTableData).reduce(
              (accumulator: Record<string, any>, key: string): Record<string, any> => {
                accumulator[`parent${Utils.capitalize(key)}`] = parentTableData[key];
                return accumulator;
              },
              {},
            )
          : {};

        parentTable = {
          parentStoreCode: (parentStoreData && parentStoreData.code) || null,
          ...parent,
        };
      }

      // Hydrate model
      return this.hydrate({
        storeCode: (storeData && storeData.code) || null,
        ...tableData,
        ...parentTable,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load SellerShippingTable from provided data');
    }
  }

  /**
   * mapInputToApiPut
   *
   * @param {Record<string, any>} input
   * @returns {Record<string, any>}
   */
  public static mapInputToApiPut(input: Record<string, any>): Record<string, any> {
    const { parentId, name } = input || {};
    return { parentId: parentId || null, name };
  }

  /**
   * toFormData
   *
   * @param {Record<string, any>[]} formFields
   * @returns {Record<string, any>}
   */
  public toFormData(formFields: Record<string, any>[]): Record<string, any> {
    return {
      ...formFields.reduce((accumulator, { name }) => {
        return {
          ...accumulator,
          [name]: typeof this[name] !== 'undefined' ? this[name] : null,
        };
      }, {}),
    };
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    // eslint-disable-next-line
    const { default: isDefault, currency, ...restData } = data;

    return super.mapFormToApiData(
      { ...restData, ...(!data.id ? { default: isDefault } : null) },
      fieldsSchema,
      includeNullValues,
    );
  }
}

/**
 * SellerShippingTableListResult
 */
class SellerShippingTableListResult extends SellerShippingTableListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {SellerShippingTableListResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): SellerShippingTableListResult {
    try {
      // Deconstruct
      const { items: itemsData, collection: collectionData } = data || {};

      // Items
      const items: Array<SellerShippingTable> = itemsData
        ? itemsData.map((item: Record<string, any>) =>
            new SellerShippingTable().hydrateFromApiData(item),
          )
        : [];

      // Collection
      const collection: ListInfo = new ListInfo().hydrateFromApiData(collectionData);

      // Hydrate model
      return this.hydrate({
        items: items,
        collection: collection || {},
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load SellerShippingTableListResult from provided data');
    }
  }

  /**
   * filterTables
   *
   * @param {number[]} excludedTables
   * @returns {SellerShippingTable[]}
   */
  public filterTables(excludedTables: number[] = []): SellerShippingTable[] {
    // @ts-ignore
    return this.items
      ? // @ts-ignore
        this.items.filter(({ id }: SellerShippingTable) => excludedTables.indexOf(id) === -1)
      : [];
  }
}

/**
 * SellerShippingTableRate
 */
class SellerShippingTableRate extends SellerShippingTableRateBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {SellerShippingTableRate}
   */
  public hydrateFromApiData(data?: Record<string, any>): SellerShippingTableRate {
    try {
      // Deconstruct parameters
      const { country: countryData, region: regionData, ...rateData } = data || {};

      // Hydrate model
      return this.hydrate({
        countryCode: (countryData && countryData.code) || null,
        regionCode: (regionData && regionData.code) || null,
        ...rateData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load SellerShippingTableRate from provided data');
    }
  }
}

/**
 * SellerShippingTableRateListResult
 */
class SellerShippingTableRateListResult extends SellerShippingTableRateListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {SellerShippingTableRateListResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): SellerShippingTableRateListResult {
    try {
      // Deconstruct
      const { items: itemsData, collection: collectionData } = data || {};

      // Items
      const items: Array<SellerShippingTableRate> = itemsData
        ? itemsData.map((item: Record<string, any>) =>
            new SellerShippingTableRate().hydrateFromApiData(item),
          )
        : [];

      // Collection
      const collection: ListInfo = new ListInfo().hydrateFromApiData(collectionData);

      // Hydrate model
      return this.hydrate({ items, collection });
    } catch (error) {
      throw new ApiInvalidData(
        'Unable to load SellerShippingTableRateListResult from provided data',
      );
    }
  }
}

/**
 * SellerPaymentMethodTypeListResult
 */
class SellerPaymentMethodTypeListResult extends SellerPaymentMethodTypeListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {SellerPaymentMethodTypeListResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): SellerPaymentMethodTypeListResult {
    try {
      // Deconstruct
      const { items: itemsData, collection: collectionData } = data || {};

      // Items
      const items: Array<SellerPaymentMethodType> = itemsData
        ? itemsData.map((item: Record<string, any>) => new SellerPaymentMethodType().hydrate(item))
        : [];

      // Collection
      const collection: ListInfo = new ListInfo().hydrateFromApiData(collectionData);

      // Hydrate model
      return this.hydrate({ items, collection });
    } catch (error) {
      throw new ApiInvalidData(
        'Unable to load SellerPaymentMethodTypeListResult from provided data',
      );
    }
  }
}

/**
 * SellerPaymentMethod
 */
class SellerPaymentMethod extends FormDataMixin(SellerPaymentMethodBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {SellerPaymentMethod}
   */
  public hydrateFromApiData(data?: Record<string, any>): SellerPaymentMethod {
    try {
      // Deconstruct parameters
      const { attributes: attributesData, ...methodData } = data || {};

      // Attributes
      const attributes: Array<SellerPaymentMethodAttribute> =
        typeof attributesData === 'object'
          ? Object.keys(attributesData).map((name: string) =>
              new SellerPaymentMethodAttribute().hydrate({ name, value: attributesData[name] }),
            )
          : [];

      // Hydrate model
      return this.hydrate({
        attributes,
        ...methodData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load SellerPaymentMethod from provided data');
    }
  }

  public mapApiToFormData(): Record<string, any> {
    const { id, sellerId, typeCode, attributes } = this;

    return {
      id,
      sellerId,
      typeCode,
      ...(attributes
        ? attributes.reduce((accumulator: Record<string, any>, item: Record<string, any>) => {
            return { ...accumulator, [item.name]: item.value };
          }, {})
        : null),
    };
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const {
      typeCode,
      default: isDefault,
      ...rest
    } = super.mapFormToApiData(data, fieldsSchema, includeNullValues);

    return {
      ...(data.id ? null : { typeCode }),
      ...(isDefault !== undefined ? { default: !!isDefault } : null),
      attributes: rest,
    };
  }
}

/**
 * SellerPaymentMethodListResult
 */
class SellerPaymentMethodListResult extends SellerPaymentMethodListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {SellerPaymentMethodListResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): SellerPaymentMethodListResult {
    try {
      // Deconstruct
      const { items: itemsData, collection: collectionData } = data || {};

      // Items
      const items: Array<SellerPaymentMethod> = itemsData
        ? itemsData.map((item: Record<string, any>) =>
            new SellerPaymentMethod().hydrateFromApiData(item),
          )
        : [];

      // Collection
      const collection: ListInfo = new ListInfo().hydrateFromApiData(collectionData);

      // Hydrate model
      return this.hydrate({ items, collection });
    } catch (error) {
      throw new ApiInvalidData('Unable to load SellerPaymentMethodListResult from provided data');
    }
  }
}

export {
  Seller,
  SellerPaginationResult,
  SellerShippingTable,
  SellerShippingTableListResult,
  SellerShippingTableRate,
  SellerShippingTableRateListResult,
  SellerPaymentMethodTypeListResult,
  SellerPaymentMethod,
  SellerPaymentMethodListResult,
};
