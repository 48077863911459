export const FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'name', apiField: 'name', type: 'string' },
  { columnField: 'alias', apiField: 'alias', type: 'string' },
  {
    columnField: 'status',
    apiField: 'status',
    type: 'discrete',
    filterType: 'basic',
  },
  { columnField: 'livesCountry', apiField: 'livesCountry', type: 'discrete' },
  { columnField: 'gender', apiField: 'gender', type: 'discrete' },
];

export const SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'name', apiField: 'name' },
  { columnField: 'alias', apiField: 'alias' },
  { columnField: 'status', apiField: 'status' },
  { columnField: 'livesCountry', apiField: 'lives' },
  { columnField: 'gender', apiField: 'gender' },
];
