import {
  Collection as CollectionBaseModel,
  CollectionLocale as CollectionLocaleBaseModel,
  CollectionPaginationResult as CollectionPaginationResultBaseModel,
  CollectionListResult as CollectionListResultBaseModel,
  CollectionMeta as CollectionMetaBaseModel,
  CollectionArt as CollectionArtBaseModel,
  CollectionArtListResult as CollectionArtListResultBaseModel,
  CollectionArtPaginationResult as CollectionArtPaginationResultBaseModel,
  CollectionArtLocale as CollectionArtLocaleBaseModel,
} from '@riseart/models';
import { FormDataMixin, LocaleMixin } from './Core';
import { ApiInvalidData } from '../errors/ApiInvalidData';
import { Author } from './Author';
import { ImageHierarchicalArray } from './Image';
import { PaginationInfo } from './List';
import { Art } from './Art';

/**
 * CollectionLocale
 */
class CollectionLocale extends CollectionLocaleBaseModel {
  id: number;
  localeCode: string;
  slug: string;
  name: string;
  description: string;

  /**
   * constructor
   */
  constructor() {
    super();
  }
}

/**
 * Collection
 */
class Collection extends FormDataMixin(LocaleMixin(CollectionBaseModel, CollectionLocale)) {
  id: number;
  status: number;
  hide: boolean;
  slug: string;
  name: string;
  description: string;
  summary: string;
  author: Author;
  images: ImageHierarchicalArray;
  locales: Array<CollectionLocale>;

  /**
   * Constructor
   */
  constructor() {
    super();
  }

  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {Art}
   */
  public hydrateFromApiData(data?: Record<string, any>): Collection {
    try {
      const { locales, author: authorData, images: imagesList, ...collectionData } = data || {};

      // Locales
      super.hydrateFromApiData({ locales });

      // Author
      const author: Author | null = authorData ? new Author().hydrateFromApiData(authorData) : null;

      // Images
      const images: ImageHierarchicalArray = new ImageHierarchicalArray().hydrateFromApiData(
        imagesList || [],
      );

      return this.hydrate({
        images,
        author,
        ...collectionData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Collection from provided data');
    }
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {ArtCollectionist}
   */
  public hydrateFromFormData(formData: Record<string, any>): Collection {
    return this.hydrate({ id: this.id, ...formData });
  }

  /**
   * hydrateFromLocaleFormData
   *
   * @param {Record<string, any>} formData
   * @param {string} localeCode
   * @returns {CollectionLocale}
   */
  public hydrateFromLocaleFormData(
    formData: Record<string, any>,
    localeCode: string,
  ): CollectionLocale {
    const existingLocale = this.locales.find(
      (localeItem: Record<string, any>) => localeItem.localeCode === localeCode,
    );
    const SHOULD_GENERATE_ID = !formData.id;
    const NewLocale = new CollectionLocale().hydrate(
      { localeCode, ...formData },
      SHOULD_GENERATE_ID,
    );

    // @ts-ignore
    this.locales = existingLocale
      ? this.locales.map((localeItem: Record<string, any>) => {
          if (localeItem.localeCode === localeCode) {
            return NewLocale;
          }

          return localeItem;
        })
      : [...this.locales, NewLocale];

    return NewLocale;
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const mappedData = super.mapFormToApiData(data, fieldsSchema, includeNullValues);
    // eslint-disable-next-line
    const { locales, ...restData } = mappedData;
    return restData;
  }

  /**
   * toFormData
   *
   * @param {Record<string, any>[]} formFields
   * @returns {Record<string, any>}
   */
  public toFormData(formFields: Record<string, any>[]): Record<string, any> {
    return {
      ...formFields.reduce((accumulator, { name }) => {
        if (name === 'authorId') {
          return {
            ...accumulator,
            [name]: (this.author && this.author.id) || null,
          };
        }

        return {
          ...accumulator,
          [name]: typeof this[name] !== 'undefined' ? this[name] : null,
        };
      }, {}),
    };
  }
}

/**
 * CollectionPaginationResult
 */
class CollectionPaginationResult extends CollectionPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {SellerPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): CollectionPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<Collection> = itemsData
        ? itemsData.map((item: Record<string, any>) => new Collection().hydrateFromApiData(item))
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({ items, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load CollectionPaginationResult from provided data.');
    }
  }
}

/**
 * CollectionListResult
 */
class CollectionListResult extends CollectionListResultBaseModel {
  /**
   * Constructor
   */
  constructor() {
    super();
  }
}

/**
 * CollectionMeta
 */
class CollectionMeta extends CollectionMetaBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): CollectionMeta {
    try {
      return this.hydrate(data || {});
    } catch (error) {
      throw new ApiInvalidData('Unable to load CollectionMeta from provided data.');
    }
  }
}

/**
 * CollectionArtLocale
 */
class CollectionArtLocale extends CollectionArtLocaleBaseModel {
  id: number;
  localeCode: string;
  description: string;

  /**
   * constructor
   */
  constructor() {
    super();
  }
}

class CollectionArt extends FormDataMixin(
  LocaleMixin(CollectionArtBaseModel, CollectionArtLocale),
) {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): CollectionArt {
    try {
      const { art, locales, ...restData } = data || {};

      super.hydrateFromApiData({ locales });

      return this.hydrate({ art: art ? new Art().hydrateFromApiData(art) : null, ...restData });
    } catch (error) {
      throw new ApiInvalidData('Unable to load CollectionArt from provided data.');
    }
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const { status, description } = super.mapFormToApiData(data, fieldsSchema, includeNullValues);

    return { status, description };
  }
}

/**
 * CollectionArtListResult
 */
class CollectionArtListResult extends CollectionArtListResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): CollectionArtListResult {
    try {
      const { items } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const CollectionArtModel = new CollectionArt();
            CollectionArtModel.hydrateFromApiData(item);

            return CollectionArtModel;
          })
        : {};

      return this.hydrate({ items: listData });
    } catch (error) {
      throw new ApiInvalidData('Unable to load CollectionArt from provided data.');
    }
  }
}

/**
 * CollectionArtPaginationResult
 */
class CollectionArtPaginationResult extends CollectionArtPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): CollectionArtPaginationResult {
    try {
      const { items, ...paginationData } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const CollectionArtModel = new CollectionArt();
            CollectionArtModel.hydrateFromApiData(item);

            return CollectionArtModel;
          })
        : {};

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      return this.hydrate({ items: listData, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load CollectionArt from provided data.');
    }
  }
}

export {
  Collection,
  CollectionLocale,
  CollectionListResult,
  CollectionPaginationResult,
  CollectionMeta,
  CollectionArt,
  CollectionArtListResult,
  CollectionArtPaginationResult,
};
