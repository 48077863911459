export const DIMENSIONS = [
  {
    name: 'medium',
    values: [
      { name: 'collage' },
      { name: 'drawings' },
      { name: 'installation' },
      { name: 'paintings' },
      { name: 'photography' },
      { name: 'prints' },
      { name: 'sculpture' },
    ],
  },
  {
    name: 'style',
    values: [
      { name: 'abstract' },
      { name: 'art-deco' },
      { name: 'cartoon' },
      { name: 'conceptual' },
      { name: 'cubism' },
      { name: 'dada' },
      { name: 'expressionistic' },
      { name: 'minimalistic' },
      { name: 'figurative' },
      { name: 'geometric' },
      { name: 'illustrative' },
      { name: 'impressionistic' },
      { name: 'pop-art' },
      { name: 'realistic' },
      { name: 'street-art' },
      { name: 'surrealist' },
    ],
  },
  {
    name: 'subject',
    values: [
      { name: 'animals' },
      { name: 'architecture' },
      { name: 'botanical' },
      { name: 'celebrities' },
      { name: 'cityscapes' },
      { name: 'documentary' },
      { name: 'fashion' },
      { name: 'food' },
      { name: 'landscapes' },
      { name: 'maps' },
      { name: 'nudes' },
      { name: 'portraits' },
      { name: 'science-technology' },
      { name: 'seascapes' },
      { name: 'spirituality' },
      { name: 'sports' },
      { name: 'still-life' },
      { name: 'transport-auto' },
      { name: 'typography' },
      { name: 'urban' },
    ],
  },
];
