import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { grey } from '@mui/material/colors';
import { FormViewField, FormTableCell } from '@riseart/dashboard';
import { countries as COUNTRIES_LIST } from '../../config/directory.js';
import {
  stores as STORES_ENUM,
  seller as SELLER_ENUM,
  currency as CURRENCY_ENUM,
} from '../../config/enumeration.js';
import { compareTime } from '../../services/riseart/utils/Utils';
import { AdminPicker, PartnerPicker, UserPicker } from './pickers';

export const INLINE_FORM_TABLE_CELL_STYLES = { background: grey.A200, p: 2 };

/**
 * countriesListOptions
 *
 * @param {{(options: Record<string, any>) => any}} options
 * @returns {JSX.Element[]}
 */
export function countriesListOptions({
  formatMessage,
  initialOption,
}: {
  formatMessage: (options: Record<string, any>) => any;
  initialOption: JSX.Element | null | undefined;
}): JSX.Element[] {
  return [
    ...(initialOption ? [initialOption] : []),
    ...COUNTRIES_LIST.all.map((code: string) => (
      <MenuItem key={code} value={code}>
        {formatMessage({ id: `countries.${code}` })}
      </MenuItem>
    )),
  ];
}

/**
 * storeCodeOptions
 *
 * @param {{(options: Record<string, any>) => any}} options
 * @returns {JSX.Element[]}
 */
export function storeCodeOptions({
  formatMessage,
}: {
  formatMessage: (options: Record<string, any>) => any;
}): JSX.Element[] {
  return STORES_ENUM.map((code) => (
    <MenuItem key={code} value={code}>
      {formatMessage({ id: `stores.${code}` })}
    </MenuItem>
  ));
}

/**
 * currencyListOptions
 *
 * @param {{ formatMessage: (options: Record<string, any>) => any; initialOption: JSX.Element | null | undefined; }}
 * @returns {JSX.Element[]}
 */
export function currencyListOptions({
  initialOption,
}: {
  formatMessage: (options: Record<string, any>) => any;
  initialOption: JSX.Element | null | undefined;
}): JSX.Element[] {
  return [
    ...(initialOption ? [initialOption] : []),
    ...Object.keys(CURRENCY_ENUM.signs).map((sign: string) => {
      // @ts-ignore
      const code = CURRENCY_ENUM.codes[sign];

      return (
        <MenuItem key={sign} value={code}>
          {/* @ts-ignore  */}
          {CURRENCY_ENUM.signs[sign]} ({code})
        </MenuItem>
      );
    }),
  ];
}

/**
 * workingDaysOptions
 *
 * @param {{(options: Record<string, any>, variables?: Record<string, any>) => any}} options
 * @returns {JSX.Element[]}
 */
export function workingDaysOptions({
  formatMessage,
}: {
  formatMessage: (options: Record<string, any>, variables?: Record<string, any>) => any;
}): JSX.Element[] {
  return Array.apply(0, Array(15)).map((day: any, idx: number) => {
    const dayNumber = idx + 1;
    return (
      <MenuItem key={dayNumber} value={dayNumber}>
        {formatMessage({ id: 'forms.seller.options.workingDays' }, { day: dayNumber })}
      </MenuItem>
    );
  });
}

/**
 * vatStatusOptions
 *
 * @param {formatMessage: (options: Record<string, any>, variables?: Record<string, any>) => any} options
 * @returns
 */
export function vatStatusOptions({
  formatMessage,
}: {
  formatMessage: (options: Record<string, any>, variables?: Record<string, any>) => any;
}): JSX.Element[] {
  return Object.keys(SELLER_ENUM.tax.vat.status).map((key: string) => {
    // @ts-ignore
    const value = SELLER_ENUM.tax.vat.status[key];
    return (
      <MenuItem
        key={value}
        value={value}
        {...(value === 0 ? { sx: { fontStyle: 'italic' } } : null)}
      >
        {formatMessage({ id: `components.tax.vatStatus.${value}` })}
      </MenuItem>
    );
  });
}

/**
 * shouldRenderTaxFields
 *
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @returns
 */
export function shouldRenderTaxFields(
  customData: Record<string, any>,
  formState: Record<string, any>,
): boolean {
  return (
    formState &&
    formState.data &&
    formState.data.taxStatus === SELLER_ENUM.tax.vat.status.REGISTERED
  );
}

/**
 * datePickerInput
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @param {(options: Record<string, any>) => any} register
 * @returns {JSX.Element | null}
 */
export function datePickerInput(
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
  register: (options: Record<string, any>) => any,
): JSX.Element | null {
  const { value, onChange } = register(field);
  const { away: awayValue = false } = (formState && formState.data) || {};
  const fieldErrors = formState && formState.errors && formState.errors[field.name];
  const isRequired =
    field &&
    field.rules &&
    field.rules.some(({ required }: Record<string, any>) => required === true);

  // Trigger onChange to reset field data when away mode is disabled
  if (awayValue === false && value !== null) {
    onChange({ target: { value: null } });
  }

  const fieldError =
    awayValue === true && fieldErrors && fieldErrors.length
      ? fieldErrors.map((error: string) => formatMessage({ id: error })).join(' ')
      : null;

  return (
    <FormViewField
      key={field.name}
      label={label}
      sx={{ justifyContent: 'center' }}
      error={fieldError}
      required={isRequired}
    >
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="yyyy/MM/dd"
            disabled={!awayValue}
            value={awayValue === true && value ? value : null}
            onChange={(value: any) => onChange({ target: { value } })}
            renderInput={(startProps: Record<string, any>) => {
              return <TextField variant="standard" {...startProps} error={!!fieldError} />;
            }}
          />
        </LocalizationProvider>
      </Box>
    </FormViewField>
  );
}

/**
 * timePickerInput
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param {Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @param {(options: Record<string, any>) => any} register
 * @returns {JSX.Element | null}
 */
export function timeDurationPickerInput(
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
  register: (options: Record<string, any>) => any,
): JSX.Element | null {
  const { value, onChange } = register(field);
  const [startTime, endTime] = value;
  const { [field.name]: formfieldError } = (formState && formState.errors) || {};
  const fieldError: Array<string> = [...(formfieldError || [])];
  const isRequired =
    field &&
    field.rules &&
    field.rules.some(({ required }: Record<string, any>) => required === true);
  const areFieldsRequired = !!(startTime || endTime);
  const areValidTimes = startTime && endTime ? compareTime(startTime, endTime) : null;

  // startTime is lower than endTime
  if (areValidTimes !== null && areValidTimes > -1) {
    fieldError.push('forms.validation.invalidTime');
  }

  const hasErrors = !!(fieldError && fieldError.length);

  return (
    <FormViewField
      key={field.name}
      // @ts-ignore
      error={hasErrors ? fieldError.map((error: string) => formatMessage({ id: error })) : null}
      required={isRequired}
    >
      <Box sx={{ display: 'flex' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            {...field.inputFieldData}
            // Convert date to string to get the time in date format, because timepicker will not work otherise
            value={
              typeof startTime === 'string'
                ? new Date(`${new Date().getFullYear()} ${startTime}`)
                : startTime || null
            }
            onChange={(value) => onChange({ target: { value: [value, endTime] } })}
            label={formatMessage({ id: 'forms.event.label.startTime' })}
            renderInput={(params) => (
              <TextField
                variant="standard"
                sx={{ maxWidth: '166px' }}
                required={areFieldsRequired}
                {...params}
                error={hasErrors}
              />
            )}
          />
          <Box sx={{ mx: 2, mt: '18px' }}> {formatMessage({ id: 'common.to' })} </Box>
          <TimePicker
            {...field.inputFieldData}
            // Convert date to string to get the time in date format, because timepicker will not work otherise
            value={
              typeof endTime === 'string'
                ? new Date(`${new Date().getFullYear()} ${endTime}`)
                : endTime || null
            }
            onChange={(value) => onChange({ target: { value: [startTime, value] } })}
            label={formatMessage({ id: 'forms.event.label.endTime' })}
            renderInput={(params) => (
              <TextField
                variant="standard"
                sx={{ maxWidth: '166px' }}
                required={areFieldsRequired}
                {...params}
                error={hasErrors}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    </FormViewField>
  );
}

/**
 * localeLabelRenderer
 *
 * @param {Record<string, any>} fieldProps
 * @param {Record<string, any>} customData
 * @returns {JSX.Element}
 */
export function localeLabelRenderer(
  fieldProps: Record<string, any>,
  customData: Record<string, any>,
): JSX.Element {
  return (
    <FormTableCell width={fieldProps.width || '20%'} key="localeLabel">
      <FormViewField>
        <FormattedMessage id={`locales.${customData.currentLocale.toLowerCase()}`} />
      </FormViewField>
    </FormTableCell>
  );
}

/**
 * blankLabelRenderer
 *
 * @param {{ valueRenderer: (value: any, formState?: Record<string, any>) => any; }} options
 * @returns {Function}
 */
export function blankLabelRenderer(
  {
    valueRenderer,
  }: {
    valueRenderer: (value: any, formState?: Record<string, any>) => any;
  } = {
    valueRenderer: (value: any) => value,
  },
) {
  return (
    field: Record<string, any>,
    customData: Record<string, any> | null,
    formState: Record<string, any>,
  ): JSX.Element => {
    return (
      <FormTableCell
        width={
          customData &&
          customData.generatedFieldWrapperProps &&
          customData.generatedFieldWrapperProps.width
        }
        key={field.name}
      >
        {typeof valueRenderer === 'function'
          ? valueRenderer(formState.data[field.name], formState)
          : ''}
      </FormTableCell>
    );
  };
}

/**
 * booleanValueRenderer
 *
 * @param {boolean | string | number | Record<string, any> | null | undefined} value
 * @returns {JSX.Element}
 */
export function booleanValueRenderer(
  value: boolean | string | number | Record<string, any> | null | undefined,
): JSX.Element {
  return <FormattedMessage id={!!value === true ? 'common.yes' : 'common.no'} />;
}

/**
 * textFieldRenderer
 *
 * @param {Record<string, any>} fieldProps
 * @param {Record<string, any>} customData
 * @returns {JSX.Element}
 */
export function textFieldRenderer(
  fieldProps: Record<string, any>,
  customData: Record<string, any>,
): JSX.Element {
  const { data } = customData;

  return (
    <FormTableCell width={fieldProps.width} key={fieldProps.name}>
      <FormViewField>
        {typeof fieldProps.viewDataRenderer === 'function'
          ? fieldProps.viewDataRenderer(data)
          : data[fieldProps.name]}
      </FormViewField>
    </FormTableCell>
  );
}

/**
 * entityIdRenderer
 *
 * @param {Record<string, any>} field
 * @param {Record<string, any>} customData
 * @param { Record<string, any>} formState
 * @param {string | JSX.Element} label
 * @param {(options: Record<string, any>) => any} formatMessage
 * @param {(options: Record<string, any>) => any} register
 * @returns {JSX.Element | null}
 */
export function entityIdRenderer(
  field: Record<string, any>,
  customData: Record<string, any>,
  formState: Record<string, any>,
  label: string | JSX.Element,
  formatMessage: (options: Record<string, any>) => any,
  register: (options: Record<string, any>) => any,
): JSX.Element | null {
  const errors = formState.errors[field.name];
  const hasErrors = errors && errors.length;
  const selectedEntityData =
    (formState.data[field.name] &&
      (typeof formState.data[field.name] === 'object'
        ? formState.data[field.name]
        : { id: formState.data[field.name], name: customData[field.name] })) ||
    null;
  const isRequired =
    field &&
    field.rules &&
    field.rules.some(({ required }: Record<string, any>) => required === true);
  const entityType = formState.data[field.customData.relatedTypeField];
  const { onChange } = register(field);

  if (
    !entityType ||
    (typeof field.customData.hasEntityType === 'function' &&
      !field.customData.hasEntityType(entityType))
  ) {
    return <Fragment key={field.name}></Fragment>;
  }

  const selectLabel = field.customData.label(entityType, formatMessage);

  return (
    <FormViewField
      key={field.name}
      label={selectLabel}
      required={isRequired}
      error={hasErrors && errors.map((error: string) => formatMessage({ id: error }))}
    >
      {entityType === 'admin' ? (
        <AdminPicker
          initialValue={selectedEntityData}
          chipColor={hasErrors ? 'error' : null}
          onChange={(selectedItem: Record<string, any>) => {
            if (!selectedItem) {
              onChange({ target: { value: null } });
              return;
            }

            if (customData.prefillFieldsFromArtist) {
              customData
                .prefillFieldsFromArtist(selectedItem.id, formState.data)
                .then((prefilledData: Record<string, any>) => {
                  onChange({ target: { value: selectedItem } }, () => {
                    return prefilledData;
                  });
                });
            } else {
              onChange(
                { target: { value: selectedItem || null } },
                customData.prefillFieldsFromAdmin
                  ? (prevData: any) => {
                      return customData.prefillFieldsFromAdmin(selectedItem, prevData);
                    }
                  : null,
              );
            }
          }}
          formatMessage={formatMessage}
        />
      ) : null}
      {entityType === 'partner' ? (
        <PartnerPicker
          initialValue={selectedEntityData}
          chipColor={hasErrors ? 'error' : null}
          onChange={(selectedItem: Record<string, any>) => {
            if (!selectedItem) {
              onChange({ target: { value: null } });
              return;
            }

            onChange(
              { target: { value: selectedItem } },
              customData.prefillFieldsFromPartner
                ? (prevData: any) => {
                    return customData.prefillFieldsFromPartner(selectedItem, prevData);
                  }
                : null,
            );
          }}
          formatMessage={formatMessage}
        />
      ) : null}
      {entityType === 'user' ? (
        <UserPicker
          initialValue={
            selectedEntityData
              ? {
                  ...selectedEntityData,
                  fullName:
                    (selectedEntityData &&
                      (selectedEntityData.fullName || selectedEntityData.name)) ||
                    null,
                }
              : null
          }
          chipColor={hasErrors ? 'error' : null}
          onChange={(selectedItem: Record<string, any>) => {
            onChange(
              { target: { value: selectedItem || null } },
              customData.prefillFieldsFromAdmin
                ? (prevData: any) => {
                    return customData.prefillFieldsFromAdmin(selectedItem, prevData);
                  }
                : null,
            );
          }}
          formatMessage={formatMessage}
        />
      ) : null}
    </FormViewField>
  );
}

/**
 * localePlaceholder
 *
 * @param {string} id
 * @param {Record<string, any>} variables
 * @returns {(customData: Record<string, any>, formatMessage: (options: Record<string, any>, vars: Record<string, any>) => string) => string}
 */
export const localePlaceholder =
  (id: string, variables = {}) =>
  (
    { locale }: Record<string, any>,
    formatMessage: (options: Record<string, any>, vars?: Record<string, any>) => string,
  ): string =>
    `${formatMessage({ id }, variables)}${
      locale
        ? ` ${formatMessage(
            { id: 'common.localeTag' },
            { locale: formatMessage({ id: `locales.${locale.toLowerCase()}` }) },
          )}`
        : ''
    }`;

/**
 * storeOptionsList
 *
 * @returns {JSX.Element[]}
 */
export function storeOptionsList(): JSX.Element[] {
  return STORES_ENUM.map((item: string) => (
    <MenuItem key={item} value={item}>
      <FormattedMessage id={`stores.${item.toLowerCase()}`} />
    </MenuItem>
  ));
}

/*
 * Custom field validations
 */
export const CUSTOM_VALIDATORS = {
  percentage: (value: number): boolean =>
    // @ts-ignore
    [null, undefined].indexOf(value) === -1 &&
    `${value}`.trim() !== '' &&
    !(value && value >= 0 && value <= 100 && `${value}`.match(/^[0-9]{1,3}$/)),
};

/**
 * phonePropsMapper
 *
 * @returns {Record<string, any>}
 */
export function phonePropsMapper(): Record<string, any> {
  return { countries: COUNTRIES_LIST.dialing };
}
