export const ME_ACTIONS = {
  ME_FETCHED: 'ME_FETCHED',
  ME_UPDATED: 'ME_UPDATED',
  ME_PATCH: 'ME_PATCH',
  ME_CLEAR: 'ME_CLEAR',
};

type ActionType = {
  type: string;
  payload: Record<string, any>;
};

// Action Creators
export function meFetched({ admin }: Record<string, any>): ActionType {
  return {
    type: ME_ACTIONS.ME_FETCHED,
    payload: { admin },
  };
}

export function mePatch({ admin }: Record<string, any>): ActionType {
  return {
    type: ME_ACTIONS.ME_PATCH,
    payload: { admin },
  };
}
