import trimEnd from 'lodash/trimEnd';
import * as Sentry from '@sentry/browser';
import { ApiClient } from '@riseart/api-client-react';
import {
  api as API_CONFIG,
  version as VERSION_CONFIG,
  environment as ENV_CONFIG,
  application as APP_CONFIG,
} from '../../config/config.js';
import { environments as ENVIRONMENTS_ENUM } from '../../config/enumeration.js';
import { MESSAGE_TYPES, RiseartLogger } from './Logger';
import configureStore from '../redux/store';
import { Cookies } from './utils/Cookies/Cookies';
import { GTMService } from './GTM';
import { NavigatorService } from './/Navigator';
import { LocationManager } from './Location';
import { setHeaders, logRequest, logResponse, handleError } from './api/interceptors';

// Error message type
const { ERROR } = MESSAGE_TYPES;

/**
 * setupEnvironment
 *
 * @param { Record<string, any> } config
 * @param { Record<string, any> } store
 */
const setupEnvironment = (config: Record<string, any>, store: Record<string, any>) => {
  const { environment } = config;

  switch (environment) {
    case ENVIRONMENTS_ENUM.development:
      // Expose store for development purposes
      window.store = store;
      break;

    case ENVIRONMENTS_ENUM.test:
    case ENVIRONMENTS_ENUM.production:
      break;

    default:
      RiseartLogger.message({
        message: `Unsupported environment '${environment}' supplied during application setup`,
        level: ERROR,
      });
      break;
  }
};

/**
 * setGlobalInfo: sets general environment and build information
 *
 * @param config
 */
const setGlobalInfo = (config: Record<string, any>) => {
  window.RiseArt = window.RiseArt || {};
  window.RiseArt.applicationInfo = {
    version: config.version.number,
    environment: config.environment,
    api: API_CONFIG.endpoint,
    hash: config.version.hash,
  };
};

/**
 * setupConsole: display rise art branding and build information in console
 *
 * @param config
 */
const setupConsole = (config: Record<string, any>) => {
  const element = document.createElement('script');
  element.type = 'text/javascript';
  element.async = true;
  element.src = `${trimEnd(process.env.PUBLIC_URL, '/')}${
    config.application.environmentLoggerScript
  }`;

  const tag = document.getElementsByTagName('script')[0];
  tag.parentNode && tag.parentNode.insertBefore(element, tag);
};

/**
 * initClientEnvironment
 *
 * @returns {Object}
 */
export const initClientEnvironment = (): Record<string, any> => {
  // Configure cookie library
  Cookies.config();

  // Configure redux store and run the sagas
  const reduxStore = configureStore({});

  // Create and configure api client
  const apiClient = new ApiClient(
    { apiHost: API_CONFIG.endpoint, version: API_CONFIG.version },
    {
      request: [setHeaders(reduxStore), logRequest],
      response: [{ success: logResponse, error: handleError(reduxStore) }],
    },
  );

  // Configure NavigatorService
  NavigatorService.config({
    userAgent: window.navigator.userAgent,
    language:
      window.navigator.language ||
      // @ts-ignore
      window.navigator.userLanguage ||
      // @ts-ignore
      window.navigator.browserLanguage,
  });

  // Configure LocationService
  // window.location is passes by reference because it is an object,
  // so there is no need to update it each time when client routing
  LocationManager.config(window.location);

  // Configure logger
  RiseartLogger.config(Sentry).init(reduxStore);

  // Setup GTMService dataLayer
  GTMService.config();

  // Initial client side environment setup
  const config = {
    version: VERSION_CONFIG,
    environment: ENV_CONFIG,
    application: APP_CONFIG,
  };
  setGlobalInfo(config);
  setupConsole(config);
  setupEnvironment(config, reduxStore);

  return { reduxStore, apiClient };
};
