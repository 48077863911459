import {
  Admin as AdminBaseModel,
  AdminPaginationResult as AdminPaginationResultBaseModel,
} from '@riseart/models';
import { FormDataMixin } from './Core';

/**
 * Admin
 */
class Admin extends FormDataMixin(AdminBaseModel) {
  /**
   * constructor
   */
  constructor() {
    super();
  }

  /**
   * fullName
   *
   * @returns {string | null}
   */
  public get fullName(): string | null {
    return this.firstName || this.lastName
      ? [this.firstName, this.lastName].filter((name: string) => !!name).join(' ')
      : null;
  }

  /**
   * name
   *
   * @returns {string | null}
   */
  public get name(): string | null {
    return this.fullName;
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {Seller}
   */
  public hydrateFromFormData(formData: Record<string, any>): Admin {
    return this.hydrate({ id: this.id, ...formData });
  }
}

/**
 * AdminPaginationResult
 */
class AdminPaginationResult extends AdminPaginationResultBaseModel {
  /**
   * constructor
   */
  constructor() {
    super();
  }
}

export { Admin, AdminPaginationResult };
