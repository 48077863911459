import omit from 'lodash/omit';
import { ME_ACTIONS } from '../../actions/container';

const DEFAULT_ME_STATE = { admin: null };

/**
 * meReducer
 *
 * @param {Record<string, any>} state
 * @param {Record<string, any>} action
 * @returns {Object} Updated me state
 */
export function meReducer(
  state = DEFAULT_ME_STATE,
  action: Record<string, any>,
): Record<string, any> {
  switch (action.type) {
    case ME_ACTIONS.ME_FETCHED:
      return {
        data: { ...DEFAULT_ME_STATE, ...omit(action.payload, 'trigger') },
      };
    case ME_ACTIONS.ME_PATCH:
      return {
        data: { ...action.payload },
      };
    case ME_ACTIONS.ME_CLEAR:
      return {
        data: { ...DEFAULT_ME_STATE },
      };
    default:
      return state;
  }
}
