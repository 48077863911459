import { DATE_FILTER_TYPE_MAPPER } from '../utils';

export const FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'type', apiField: 'type', type: 'discrete' },
  { columnField: 'title', apiField: 'title', type: 'string' },
  {
    columnField: 'status',
    apiField: 'status',
    type: 'discrete',
    filterType: 'basic',
    operators: [{ isDefault: true, name: 'is' }],
  },
  { columnField: 'authorName', apiField: 'authorName', type: 'string' },
  {
    columnField: 'publishedDate',
    apiField: 'published',
    type: 'date',
    mapper: DATE_FILTER_TYPE_MAPPER,
  },
  { columnField: 'locales', apiField: 'locale', type: 'string' },
  { columnField: 'showcase', type: 'discrete', filterType: 'basic' },
];

export const SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'type' },
  { columnField: 'title' },
  { columnField: 'status' },
  { columnField: 'authorName' },
  { columnField: 'publishedDate', apiField: 'published' },
  { columnField: 'showcase' },
];

export const FILTER_CATEGORY_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'name', apiField: 'name', type: 'string' },
  { columnField: 'uri', apiField: 'uri', type: 'string' },
  { columnField: 'type', apiField: 'type', type: 'discrete' },
  { columnField: 'locales', apiField: 'locale', type: 'string' },
];

export const SORT_CATEGORY_CONFIG = [
  { columnField: 'id' },
  { columnField: 'name' },
  { columnField: 'uri' },
  { columnField: 'type' },
];
