import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import CollectionsIcon from '@mui/icons-material/Collections';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ImageIcon from '@mui/icons-material/Image';
import PaletteIcon from '@mui/icons-material/Palette';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PeopleIcon from '@mui/icons-material/People';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import EventIcon from '@mui/icons-material/Event';
import TocIcon from '@mui/icons-material/Toc';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { Logo, MainMenu, menuItemsFinder } from '@riseart/dashboard';
import { gui as GUI_CONFIG } from '../../../config/config.js';
import { ROUTER_CONFIG } from '../../../config/router.js';
import { gui as GUI_ENUM } from '../../../config/enumeration.js';
import { UrlAssembler } from '../../../services/riseart/utils/UrlAssembler';
import { selectAuth } from '../../../services/redux/selectors/auth';
import { isRoleAllowedForACL } from '../../../services/riseart/utils/Auth';

type Props = {
  window?: () => Record<string, any>;
  onClose: (open: boolean) => void;
  onClick?: (poperties?: any) => void;
  open: boolean;
  drawerVariant: 'permanent' | 'temporary';
};

/**
 * Menu
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Menu = ({ open, onClick, drawerVariant, onClose }: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const auth = useSelector(selectAuth);
  const userRole = get(auth, 'payload.acl_role');
  const MENU_ICONS: Record<string, any> = {
    home: DashboardIcon,
    artList: ImageIcon,
    collectionList: CollectionsIcon,
    userList: PeopleIcon,
    artistList: PaletteIcon,
    partnerList: HandshakeIcon,
    sellerList: BusinessCenterIcon,
    affiliateList: GroupWorkIcon,
    offerList: PriceChangeIcon,
    authorList: HistoryEduIcon,
    articleList: NewspaperIcon,
    eventList: EventIcon,
    fileList: FileIcon,
    cmsPageList: TocIcon,
    cache: SettingsIcon,
    signout: LogoutIcon,
  };

  return (
    <MainMenu
      logo={
        <Logo
          type={drawerVariant === GUI_ENUM.drawer.type.PERMANENT ? 'initials' : 'default'}
          size="small"
          href={UrlAssembler.byRouteKey('home')}
        />
      }
      open={open}
      onClick={onClick}
      drawerVariant={drawerVariant}
      onClose={onClose}
      drawerWidth={GUI_CONFIG.drawerWidth}
      mainMenuItems={menuItemsFinder(
        ROUTER_CONFIG,
        'main',
        userRole,
        {
          isRoleAllowedForACL,
          UrlAssembler,
          formatMessage: ({ id }) => formatMessage({ id: `components.mainMenu.${id}` }),
        },
        MENU_ICONS,
      )}
      secondaryMenuItems={menuItemsFinder(
        ROUTER_CONFIG,
        'secondary',
        userRole,
        {
          isRoleAllowedForACL,
          UrlAssembler,
          formatMessage: ({ id }) => formatMessage({ id: `components.secondaryMenu.${id}` }),
        },
        MENU_ICONS,
      )}
    />
  );
};
