import { Utils } from '@riseart/utils';
import {
  Account as AccountBaseModel,
  User as UserBaseModel,
  UserAddress as UserAddressBaseModel,
  PrivacySetting,
} from '@riseart/models';
import { ApiInvalidData } from '../errors/ApiInvalidData';
import { FormDataMixin } from './Core';
import { ImageArray } from './Image';
import { isCountryExcluded } from '../../services/riseart/utils/Utils';

/**
 * Account
 */
class Account extends FormDataMixin(AccountBaseModel) {
  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    return super.mapFormToApiData(data, fieldsSchema, includeNullValues);
  }
}

/**
 * User
 */
class User extends FormDataMixin(UserBaseModel) {
  visitorLocale: string;
  /**
   * hydrateFromApiData
   *
   * @param {{}} parameters
   * @returns {User}
   */
  public hydrateFromApiData(data?: Record<string, any>): User {
    try {
      // List of fields for each object
      const fields = {
        visitor: ['id', 'locale', 'email'],
        marketing: ['id', 'code', 'type'],
      };

      // Deconstruct parameters
      const {
        birthdate: birthDate = null,
        country: countryData = {},
        visitor: visitorData,
        marketingCampaign: marketingData,
        accounts: accountsData,
        avatars: avatarsData,
        privacySettings: privacySettingsData,
        ...userData
      }: Record<string, any> = data || {};
      const rootData = { ...userData };

      // Visitor data
      Object.keys(visitorData || {})
        .filter((key) => fields['visitor'].indexOf(key) >= 0)
        .forEach((key) => (rootData[`visitor${Utils.capitalize(key)}`] = visitorData[key]));

      // Marketing data
      Object.keys(marketingData || {})
        .filter((key) => fields['marketing'].indexOf(key) >= 0)
        .forEach(
          (key) => (rootData[`marketingCampaign${Utils.capitalize(key)}`] = marketingData[key]),
        );

      // Avatars
      const avatars: ImageArray = new ImageArray().hydrateFromApiData(avatarsData || []);

      // Accounts
      const accounts: Array<Account> = accountsData
        ? accountsData.map((item: Record<string, any>) => new Account().hydrate(item))
        : [];

      // Privacy Settings
      const privacySettings: Array<PrivacySetting> = privacySettingsData
        ? privacySettingsData.map((item: Record<string, any>) => new PrivacySetting().hydrate(item))
        : [];

      // User
      return this.hydrate({
        ...rootData,
        birthDate,
        countryCode: countryData ? countryData.code : null,
        avatars,
        accounts,
        privacySettings,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load User from provided data');
    }
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {Article}
   */
  public hydrateFromFormData(formData: Record<string, any>): User {
    const { countryCode, ...restFormData } = formData;

    return this.hydrate({
      id: this.id,
      ...(isCountryExcluded(countryCode) ? null : { countryCode }),
      ...restFormData,
    });
  }

  /**
   * toFormData
   *
   * @param {Record<string, any>[]} formFields
   * @returns {Record<string, any>}
   */
  public toFormData(formFields: Record<string, any>[]): Record<string, any> {
    return {
      ...formFields.reduce((accumulator, { name }) => {
        return {
          ...accumulator,
          [name]: typeof this[name] !== 'undefined' ? this[name] : null,
        };
      }, {}),
    };
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const { birthDate, countryCode, ...mappedData } = super.mapFormToApiData(
      data,
      fieldsSchema,
      includeNullValues,
    );

    return {
      birthdate: birthDate,
      ...(isCountryExcluded(countryCode) ? null : { country: countryCode }),
      ...mappedData,
    };
  }

  /**
   * fullName
   *
   * @returns {string | null}
   */
  public get fullName(): string | null {
    return this.firstName || this.lastName
      ? [this.firstName, this.lastName].filter((name: string) => !!name).join(' ')
      : null;
  }

  /**
   * name
   *
   * @returns {string | null}
   */
  public get name(): string | null {
    return this.fullName;
  }
}

/**
 * UserAddress
 */
class UserAddress extends FormDataMixin(UserAddressBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param data {Record<string, any>}
   */
  public hydrateFromApiData(data?: Record<string, any>): UserAddress {
    try {
      const { country, ...rootData } = data || {};

      // UserAddress
      return this.hydrate({
        ...rootData,
        countryCode: country ? country.code : null,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load UserAddress from provided data');
    }
  }
  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const { countryCode, ...mappedData } = super.mapFormToApiData(
      data,
      fieldsSchema,
      includeNullValues,
    );

    return { country: countryCode || null, ...mappedData };
  }
}

export { User, UserAddress, Account };
