export const FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'firstName', apiField: 'firstName', type: 'string' },
  { columnField: 'lastName', apiField: 'lastName', type: 'string' },
  { columnField: 'email', apiField: 'email', type: 'string' },
  { columnField: 'role', apiField: 'role', type: 'discrete' },
  {
    columnField: 'status',
    apiField: 'status',
    type: 'discrete',
    filterType: 'basic',
    operators: [{ isDefault: true, name: 'is' }],
  },
];

export const SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'firstName' },
  { columnField: 'lastName' },
  { columnField: 'email' },
  { columnField: 'role' },
  { columnField: 'status' },
];
