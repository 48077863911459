import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { Link } from '@riseart/dashboard';
import { admin as ADMIN_ENUM } from '../../../../config/enumeration.js';
import { DEFAULT_COLUMN_PROPS, dataPickerActionColumn } from '../utils';
import { Status } from '../../../common/Status';
import { filterSelectOptions, DISCRETE_TYPE_OPERATORS } from '../../../data/tables/filters/utils';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';

export const ADMIN_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    renderCell: ({ row }: Record<string, any>) =>
      showNameAsLink ? (
        <Link to={UrlAssembler.adminDetails(row.id)} title={row.fullName}>
          {row.id}
        </Link>
      ) : (
        row.fullName
      ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'singleSelect',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.common.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="admin" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    // @ts-ignore
    valueOptions: filterSelectOptions(ADMIN_ENUM.status, 'components.admin.status', formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'role',
    type: 'singleSelect',
    width: 120,
    headerName: formatMessage({ id: 'components.tables.columns.common.role' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      formatMessage({ id: `components.admin.role.${data.role}` }),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(ADMIN_ENUM.role, 'components.admin.role', formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'firstName',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.firstName' }),
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'lastName',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.lastName' }),
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'email',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.email' }),
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
];

export const ADMIN_DATAGRID_TABLE_SCHEMA: (properties: Record<string, any>) => GridColumns<any> = ({
  formatMessage,
  showNameAsLink,
}) => [
  ...ADMIN_BASE_DATAGRID_TABLE_SCHEMA({ formatMessage, showNameAsLink }),
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'workTitle',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.admin.workTitle' }),
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'workCompany',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.admin.workCompany' }),
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
];

export const ADMIN_PICKER_TABLE_SCHEMA: (properties: Record<string, any>) => GridColumns<any> = ({
  formatMessage,
}) => [
  ...ADMIN_BASE_DATAGRID_TABLE_SCHEMA({ formatMessage }),
  dataPickerActionColumn(formatMessage),
];
