import { ReactChild } from 'react';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { gui as GUI_ENUM } from '../../../config/enumeration.js';
import { Menu } from './Menu';
import { HeaderSettings } from './Settings';
import { UserAvatar } from '../../common/Avatar';

type Props = {
  showMenu?: boolean;
  drawerWidth: number;
  menuOpen: boolean;
  drawerVariant: 'permanent' | 'temporary';
  onMenuToggle: () => void;
  onMenuItemClick?: (properties?: any) => void;
  title?: string | JSX.Element | ReactChild | ReactChild[] | null;
};

/**
 * Header
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Header = ({
  drawerWidth,
  drawerVariant,
  menuOpen,
  onMenuToggle,
  onMenuItemClick,
  showMenu,
  title,
}: Props): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <AppBar
        position="fixed"
        sx={
          drawerVariant === GUI_ENUM.drawer.type.PERMANENT
            ? {
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
                ...(menuOpen && {
                  marginLeft: drawerWidth,
                  width: `calc(100% - ${drawerWidth}px)`,
                }),
              }
            : null
        }
      >
        <Toolbar>
          {showMenu && (!menuOpen || drawerVariant === 'temporary') ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={onMenuToggle}
              sx={{ marginRight: 2 }}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <Box component="div" width="100%">
            {title ? (
              <Typography
                variant="h6"
                noWrap
                component="h1"
                textAlign={drawerVariant !== GUI_ENUM.drawer.type.PERMANENT ? 'center' : 'left'}
              >
                {title}
              </Typography>
            ) : null}
          </Box>
          <HeaderSettings />
          {showMenu ? <UserAvatar /> : null}
        </Toolbar>
      </AppBar>
      {showMenu ? (
        <Menu
          open={menuOpen}
          drawerVariant={drawerVariant}
          onClose={onMenuToggle}
          {...(onMenuItemClick ? { onClick: onMenuItemClick } : null)}
        />
      ) : null}
    </>
  );
};

Header.defaultProps = {
  showMenu: true,
};
