import { art as ENUM_ART, product as ENUM_PRODUCT } from '../../config/enumeration.js';

/**
 * formatDimensions
 *
 * @param {Array<number>} dimensions
 * @param {string} units
 * @param {{ type?: 'long' | 'short', precision?: number, separator?: string }} options
 * @returns {string}
 */
export function formatDimensions(
  dimensions: Array<number>,
  units: string,
  options: {
    type?: 'long' | 'short';
    precision?: number;
    separator?: string;
  } = {},
): string {
  const { type, precision, separator } = {
    type: 'long',
    precision: 1,
    separator: ' x ',
    ...options,
  };
  const dimensionsLabel = dimensions
    .reduce<Array<string>>((accumulator, dimension) => {
      if (dimension) {
        accumulator.push(`${dimension.toFixed(precision)}${type === 'long' ? units : ''}`);
      }

      return accumulator;
    }, [])
    .join(type === 'long' ? ` ${separator} ` : separator);

  return type === 'long' ? dimensionsLabel : dimensionsLabel + ' ' + units;
}

/**
 * isEditionType
 *
 * @param {string} productType
 * @returns {boolean}
 */
export function isEditionType(productType: string): boolean {
  const EDITION_TYPES = [
    ENUM_PRODUCT.type.LIMITED_EDITION,
    ENUM_PRODUCT.type.ARTIST_PROOF,
    ENUM_PRODUCT.type.GALLERY_EDITION,
  ];

  return EDITION_TYPES.indexOf(productType) > -1;
}

/**
 * listProductActions
 *
 * @param {Record<string, any>} data
 * @returns {Record<string, boolean>}
 */
export function listArtActions({ product, status }: Record<string, any>): {
  approve: boolean;
  activate: boolean;
  disapprove: boolean;
  delete: boolean;
  unlist: boolean;
  submit: boolean;
  sellOut: boolean;
  enquire: boolean;
  archive: boolean;
  sync: boolean;
} {
  return {
    approve: !!(product && product.canApprove && product.isPendingApproval),
    disapprove: !!(product && product.canDisapprove && product.isPendingApproval),
    activate: !!(product && (!product.isActive || product.canActivate)),
    delete:
      (product && !product.isDeleted && product.canDelete) ||
      (!product && status !== ENUM_ART.status.DELETED),
    unlist: !!(product && !product.isUnlisted),
    submit: false,
    sellOut: !!(product && !product.isSoldOut && product.canSellOut),
    enquire: !!(product && !product.isEnquire && product.canEnquire),
    archive: !!(product && !product.isArchived && product.canArchive),
    sync: !!product,
  };
}
