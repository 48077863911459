import { Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { RiseArtApiClientProvider } from '@riseart/api-client-react';
import { CircularLoader } from '@riseart/dashboard';
import { initClientEnvironment } from './services/riseart/client';
import { RouterRoutes } from './components/router/Routes';
import { TokenProvider } from './data/token/Token';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import './styles/print.css';
import styles from './styles/breakpoints.module.css';

// Setup client application environment
const { reduxStore, apiClient } = initClientEnvironment();

function App(): JSX.Element {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: parseInt(styles.raScreenSm, 10),
        md: parseInt(styles.raScreenMd, 10),
        lg: parseInt(styles.raScreenLg, 10),
        xl: parseInt(styles.raScreenXl, 10),
      },
    },
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            '.MuiTableCell-root.MuiTableCell-body': { textOverflow: 'unset' },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: { padding: '10px' },
          head: { padding: '0 10px' },
          root: {
            '> .MuiFormControl-root': {
              marginTop: 0,
              marginBottom: 0,
            },
          },
        },
      },
    },
  });

  return (
    <ReduxProvider store={reduxStore}>
      <RiseArtApiClientProvider client={apiClient}>
        <BrowserRouter>
          <Suspense fallback={<CircularLoader active fullHeight />}>
            <ThemeProvider theme={theme}>
              <TokenProvider>
                {({ payload = {} }) => (
                  <>
                    <CssBaseline />
                    <RouterRoutes tokenPayload={payload} />
                  </>
                )}
              </TokenProvider>
            </ThemeProvider>
          </Suspense>
        </BrowserRouter>
      </RiseArtApiClientProvider>
    </ReduxProvider>
  );
}

export default App;
