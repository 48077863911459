export const stores = ['uk', 'eu', 'us'];
export const units = {
  centimeter: 'CENTIMETER',
  inch: 'INCH',
  system: { imperial: 'imperial', metric: 'metric' },
};
export const currency = {
  signs: { uk: '£', eu: '€', us: '$' },
  codes: { uk: 'GBP', eu: 'EUR', us: 'USD' },
};
export const auth = { modules: { ADMIN_ADMIN: 'admin/admin' } };
export const acl = { role: { SELLER_PARTNER: 'seller_partner', PARTNER_ADMIN: 'partner_admin' } };
export const gui = { drawer: { type: { PERMANENT: 'permanent', TEMPORARY: 'temporary' } } };
export const errors = {
  handlers: { SILENT: 'silent', ERROR_PAGE: 'errorPage', NOTIFICATION: 'notification' },
  placeholders: { APP: 'app', PAGE: 'page', SNACKBAR: 'snackbar' },
  levels: { ERROR: 10, WARNING: 20, INFO: 30, SUCCESS: 40 },
  types: { auth: 'AuthError' },
};
export const pagination = { type: { INFINITE: 'infinite', SLIDER: 'slider' } };
export const data = {
  type: { integer: 'integer', float: 'float', string: 'string', discrete: 'discrete' },
  operators: {
    api: {
      '=': '=',
      '!=': '!=',
      '>': '>',
      '>=': '>=',
      '<': '<',
      '<=': '<=',
      equals: '=',
      contains: 'contains',
      startsWith: 'starts_with',
      endsWith: 'ends_with',
      isEmpty: 'is_empty',
      isNotEmpty: 'is_not_empty',
      is: '=',
      not: '!=',
      isAnyOf: 'is_any',
      after: '>',
      onOrAfter: '>=',
      before: '<',
      onOrBefore: '<=',
    },
  },
};
export const user = {
  mailingList: {
    status: { STATUS_INACTIVE: 0, STATUS_ACTIVE: 1 },
    type: { NEWSLETTER: 'newsletter' },
  },
  privacySetting: {
    category: {
      CATEGORY_PRIVACY: 'privacy',
      CATEGORY_EMAIL: 'email',
      CATEGORY_FACEBOOK: 'facebook',
    },
    name: {
      PRIVACY_SHOW_PERSONAL_DATA: 'show_personal_data',
      EMAIL_GENERAL: 'general',
      EMAIL_ACTIVITY_UPDATE: 'activity_update',
      EMAIL_ACTIVITY_WEEKLY: 'activity_weekly',
      FACEBOOK_ACTION_FOLLOW: 'action_follows',
      FACEBOOK_ACTION_FAVORITE: 'action_favorite',
      FACEBOOK_ACTION_VOTE: 'action_vote',
      FACEBOOK_ACTION_TAKE: 'action_take',
    },
  },
  role: { USER: 'user', ARTIST: 'artist', CURATOR: 'curator' },
  status: { PENDING: 0, ACTIVE: 1, SUSPENDED: 2, DELETED: 3 },
  image: { category: { MAIN: 'main' }, type: { ORIGINAL: 'original', SQUARE: 'square' } },
  account: {
    status: { INACTIVE: 0, ACTIVE: 1, SUSPENDED: 2, DELETED: 3 },
    domain: { RISEART: 'ra', FACEBOOK: 'fb', GOOGLE: 'goog', TWITTER: 'twttr' },
  },
  payment: {
    method: { DATACASH: 'datacash', STRIPE: 'stripe_payments' },
    cardType: {
      CARD_TYPE_AMEX: 'amex',
      CARD_TYPE_MASTERCARD: 'master',
      CARD_TYPE_MASTERCARD_DEBIT: 'master_debit',
      CARD_TYPE_MAESTRO: 'maestro',
      CARD_TYPE_VISA: 'visa',
      CARD_TYPE_VISA_DEBIT: 'visa_debit',
      CARD_TYPE_VISA_ELECTRON: 'visa_electron',
      CARD_TYPE_DINERS: 'diners',
      CARD_TYPE_DISCOVER: 'discover',
      CARD_TYPE_JCB: 'jcb',
      CARD_TYPE_UNIONPAY: 'unionpay',
      CARD_TYPE_UNKNOWN: 'unknown',
    },
  },
  address: { type: { BILLING: 'billing', SHIPPING: 'shipping' } },
};
export const author = {
  type: { ADMIN: 'admin', ARTIST: 'artist', AUTHOR: 'author', PARTNER: 'partner', USER: 'user' },
  internalType: { ADMIN: 'admin', AUTHOR: 'author', PARTNER: 'partner', USER: 'user' },
  image: { category: { MAIN: 'main' }, type: { ORIGINAL: 'original', SQUARE: 'square' } },
};
export const admin = {
  status: { INACTIVE: 0, ACTIVE: 1, DELETED: 3 },
  role: { ADMIN: 'admin', SUPER_ADMIN: 'super_admin' },
};
export const art = {
  state: {
    ALL: 'all',
    ART: 'art',
    ACTIVE: 'active',
    ARCHIVED: 'archived',
    DELETED: 'deleted',
    DISABLED: 'disabled',
    PENDING: 'pending',
    PRODUCT: 'product',
    SOLD: 'sold',
    UNLISTED: 'unlisted',
  },
  status: { APPROVED: 1, DELETED: 4, DISAPPROVED: 2, FOR_SALE: 3, PENDING_APPROVAL: 0 },
  image: {
    category: { MAIN: 'main', DETAIL: 'detail' },
    type: {
      MAIN_MASTER: 'main-master',
      MAIN_ORIGINAL: 'main-original',
      MAIN_SQUARE: 'main-square',
      DETAIL_MASTER: 'detail-master',
      DETAIL_ORIGINAL: 'detail-original',
      DETAIL_SQUARE: 'detail-square',
    },
  },
  bump: { DOWN: -1, NEUTRAL: 0, UP: 1 },
  meta: {
    color: {
      COLORFUL: 'colourful',
      BLACK_WHITE: 'black-white',
      WHITE: 'white',
      GRAY: 'grey',
      BLACK: 'black',
      MAROON_NEUTRAL: 'maroon-neutral',
      ORANGE: 'orange',
      YELLOW: 'yellow',
      GREEN: 'green',
      BLUE: 'blue',
      PURPLE: 'purple',
      PINK: 'pink',
      RED: 'red',
    },
    space: {
      BEDROOM: 'bedroom',
      DECORATIVE: 'decorative',
      KIDS_ROOM: 'kids-room',
      KITCHEN: 'kitchen',
      LIVING_ROOM: 'living-room',
    },
  },
  sort: { ARTIST: 'artist' },
  substrate: {
    PAPER: 'paper',
    CANVAS: 'canvas',
    WOOD: 'wood',
    GLASS: 'glass',
    PLASTIC: 'plastic',
    DIBOND: 'dibond',
    ALUMINIUM: 'aluminium',
    METAL: 'metal',
    OTHER: 'other',
  },
  packageMethod: { FLAT: 'flat', ROLLED: 'rolled' },
  media: {
    type: { VIDEO: 'video' },
    platform: { YOUTUBE: 'youtube', VIMEO: 'vimeo' },
    status: { INACTIVE: 0, ACTIVE: 1 },
  },
};
export const artist = {
  region: {
    REGION_AFRICAN: 'african',
    NORTH_AMERICAN: 'north-american',
    LATIN_AMERICAN: 'latin-american',
    CARIBBEAN: 'caribbean',
    EUROPEAN: 'european',
    SCANDINAVIAN: 'scandinavian',
    EASTERN_EUROPEAN: 'eastern-european',
    ASIAN: 'asian',
    SOUTH_ASIAN: 'south-asian',
    MIDDLE_EASTERN: 'middle-eastern',
    OCEANIAN: 'oceanian',
  },
  careerStage: { NEW: 'new', EMERGING: 'emerging', ESTABLISHED: 'established' },
  exhibition: { type: { SOLO: 'solo', GROUP: 'group' } },
  feed: {
    image: { type: { ORIGINAL: 'original', SQUARE: 'square' }, category: { MAIN: 'main' } },
    media: {
      type: { IMAGE: 'image', VIDEO: 'video', TEXT: 'text' },
      platform: { YOUTUBE: 'youtube', VIMEO: 'vimeo', INSTAGRAM: 'instagram' },
    },
  },
  image: {
    category: { BANNER: 'banner', ADDITIONAL: 'additional', DETAIL: 'detail' },
    type: {
      BANNER_ORIGINAL: 'banner-original',
      BANNER_MASTER: 'banner-master',
      ADDITIONAL_ORIGINAL: 'additional-original',
      ADDITIONAL_MASTER: 'additional-master',
      DETAIL_ORIGINAL: 'detail-original',
      DETAIL_MASTER: 'detail-master',
    },
  },
  status: { PENDING: 0, ACTIVE: 1, SUSPENDED: 2, DELETED: 3, PURGED: 4 },
  action: { APPROVE: 'approve', DISAPPROVE: 'disapprove' },
  editorial: {
    type: {
      CURATOR_THOUGHT: 'curator_thought',
      ARTIST_REASON: 'artist_reason',
      ARTIST_QUOTE: 'artist_quote',
    },
  },
};
export const article = {
  type: { POST: 'post', GUIDE: 'guide' },
  status: { UNPUBLISHED: 0, PUBLISHED: 1 },
  category: { type: { POST: 'post', GUIDE: 'guide' } },
  image: {
    category: { MAIN: 'main', BANNER: 'banner', INLINE: 'inline' },
    type: {
      MAIN_MASTER: 'main-master',
      MAIN_ORIGINAL: 'main-original',
      MAIN_SQUARE: 'main-square',
      BANNER_MASTER: 'banner-master',
      BANNER_ORIGINAL: 'banner-original',
      INLINE_MASTER: 'inline-master',
    },
  },
};
export const product = {
  type: { ORIGINAL: 'OR', LIMITED_EDITION: 'LE', ARTIST_PROOF: 'AP', GALLERY_EDITION: 'GE' },
  sku: { type: { ART: 'ART', RENT: 'REN' } },
  status: {
    INACTIVE: 0,
    ACTIVE: 1,
    SOLD_OUT: 2,
    DELETED: 3,
    RENTED: 4,
    PENDING_APPROVAL: 5,
    UNLISTED: 6,
    ENQUIRE: 7,
    ARCHIVED: 8,
  },
  action: {
    APPROVE: 'approve',
    ACTIVATE: 'activate',
    DISAPPROVE: 'disapprove',
    UNLIST: 'unlist',
    DELETE: 'delete',
    SUBMIT: 'submit',
    ENQUIRE: 'enquire',
    ARCHIVE: 'archive',
    SELL_OUT: 'sell_out',
    SYNC: 'sync',
    COPY: 'copy',
  },
  image: {
    type: {
      ORIGINAL_MASTER: 'original-master',
      ORIGINAL_DETAIL: 'original-detail',
      ORIGINAL_SQUARE: 'original-square',
    },
  },
  editorial: { type: { THOUGHT: 'thought', TEXT: 'text', NOTE: 'note', LINK: 'link' } },
};
export const url = {
  meta: {
    parentType: {
      ARTIST: 'artist',
      ARTICLE: 'article',
      ARTICLE_CATEGORY: 'article_category',
      COLLECTION: 'collection',
      EVENT: 'event',
      CMS_PAGE: 'page',
    },
  },
};
export const file = {
  object: {
    type: {
      ART: 'art',
      ARTICLE: 'article',
      EVENT: 'event',
      FILE: 'file',
      ARTIST: 'artist',
      ARTIST_FEED: 'artist-feed',
      COLLECTION: 'collection',
      USER_AVATAR: 'user-avatar',
    },
  },
  status: { DISABLED: 0, ENABLED: 1 },
};
export const settings = { type: { STRING: 0, INTEGER: 1, BOOLEAN: 2, FLOAT: 3, TEXT: 4 } };
export const seller = {
  tax: { vat: { status: { UNDEFINED: 0, REGISTERED: 1, NOT_REGISTERED: 2 } } },
  type: { ARTIST: 1, GALLERY: 2, MANAGED: 3 },
  status: { DELETED: 0, ACTIVE: 1, INACTIVE: 2 },
  locale: { EN: 'en-GB', DE: 'de-DE', FR: 'fr-FR' },
  shippingService: { MANAGED: 'managed', SELLER: 'seller' },
};
export const collection = {
  status: { INACTIVE: 0, ACTIVE: 1 },
  art: { status: { ACTIVE: 1, INACTIVE: 2 } },
  image: {
    category: { MAIN: 'main', BANNER: 'banner' },
    type: {
      MAIN_ORIGINAL: 'main-original',
      MAIN_MASTER: 'main-master',
      MAIN_SQUARE: 'main-square',
      BANNER_ORIGINAL: 'banner-original',
      BANNER_LARGE: 'banner-large',
      BANNER_MEDIUM: 'banner-medium',
      BANNER_SMALL: 'banner-small',
    },
  },
};
export const event = {
  type: { SOLO_SHOW: 1, ART_FAIR: 3, TALK: 4, GROUP_SHOW: 5, OTHER: 6 },
  status: { ACTIVE: 1, SUSPENDED: 2, CANCELED: 3 },
  state: { ACTIVE: 1, SUSPENDED: 2, CANCELED: 3, FINISHED: 4 },
  rsvp: {
    type: {
      ATTENDEE_NONE: 0,
      ATTENDEE_ALL: 8,
      ATTENDEE_LEADERS: 4,
      ATTENDEE_FOLLOWERS: 2,
      ATTENDEE_CONTACTS: 1,
    },
  },
  visibility: { PUBLIC: 'public', PRIVATE: 'private' },
  creator: { ADMIN: 'admin', USER: 'user', PARTNER: 'partner' },
  image: {
    category: { MAIN: 'main', SQUARE: 'square' },
    type: { MASTER: 'master', ORIGINAL: 'original', SQUARE: 'square' },
  },
};
export const cms = {
  page: { status: { INACTIVE: 0, ACTIVE: 1, DISABLED: 2 } },
  module: { status: { INACTIVE: 0, ACTIVE: 1 }, instance: { status: { INACTIVE: 0, ACTIVE: 1 } } },
  attribute: {
    type: { TEXT: 0, INT: 1, BOOL: 2, HTML: 3, FLOAT: 4, STRUCTURED: 5 },
    structure: {
      type: {
        ARRAY: 'array',
        MAP: 'map',
        BOOL: 'bool',
        FLOAT: 'float',
        INT: 'int',
        STRING: 'string',
      },
    },
  },
};
export const partner = {
  type: {
    GALLERY: 'gallery',
    MUSEUM: 'museum',
    COLLECTIVE: 'collective',
    FAIR: 'fair',
    ARTIST_FAIR: 'artist-fair',
    PUBLISHER: 'publisher',
  },
  status: { INACTIVE: 0, ACTIVE: 1 },
  artist: { status: { INACTIVE: 2, ACTIVE: 1 } },
  admin: { status: { INACTIVE: 2, ACTIVE: 1 } },
};
export const affiliate = { status: { INACTIVE: 0, ACTIVE: 1 }, type: { PARTNER: 'partner' } };
export const offer = {
  status: { PENDING: 'pending', ACTIVE: 'active', INACTIVE: 'inactive' },
  state: {
    CREATED: 'created',
    SENT: 'sent',
    ACCEPTED: 'accepted',
    COUNTER: 'counter',
    DECLINED: 'declined',
    EXPIRED: 'expired',
    UNANSWERED: 'unanswered',
    USED: 'used',
    CANCELED: 'canceled',
    ERROR: 'error',
  },
  action: {
    ACCEPT: 'accept',
    COUNTER: 'counter',
    DECLINE: 'decline',
    EXTEND: 'extend',
    CANCEL: 'cancel',
  },
};
export const validation = { type: { SELLER_INVITATION: 4 } };
export const profile = { gender: ['M', 'F'] };
export const legal = { type: { SELLER_TERMS: 'seller_terms' } };
export const httpStatus = {
  MOVED_PERMANENTLY: 301,
  MOVED_TEMPORARILY: 302,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};
export const environments = { development: 'development', test: 'test', production: 'production' };
