import { useRef, ReactChild, MutableRefObject } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CircularLoader } from '@riseart/dashboard';
import { gui as GUI_CONFIG } from '../../config/config.js';
import { gui as GUI_ENUM, errors as ERRORS_ENUM } from '../../config/enumeration.js';
import { Header } from './header/Header';
import { guiUpdate } from '../../services/redux/actions/application/gui';
import { selectMainMenuState } from '../../services/redux/selectors/gui';
import {
  HOCMessagesNotificationsManager,
  NotificationMessages,
} from '../common/messages/notifications/Manager';
import style from './Default.module.css';

// @ts-ignore
const NotificationMessagesContainer = HOCMessagesNotificationsManager(NotificationMessages);

type Props = {
  hideHeader?: boolean;
  showMenu?: boolean;
  title?: string | JSX.Element | ReactChild | ReactChild[] | null;
  loading?: boolean;
  breadcrumbs?: ReactChild[];
  topHint?: ReactChild | ReactChild[] | null;
  loadingContent?: JSX.Element | ReactChild | ReactChild[] | null;
  includesFab?: boolean;
  containerStyles?: Record<string, any> | null;
  children?: ReactChild | (ReactChild | null)[] | null;
  boxRefCallback?: (ref: MutableRefObject<Record<string, any> | null>) => void;
};

/**
 * DefaultLayout
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const DefaultLayout = ({
  children,
  hideHeader = false,
  showMenu,
  breadcrumbs,
  topHint = null,
  loading = false,
  loadingContent = null,
  containerStyles = null,
  includesFab = false,
  title = null,
  boxRefCallback,
}: Props): JSX.Element => {
  const isMenuOpen = useSelector(selectMainMenuState);
  const dispatch = useDispatch();
  const boxRef = useRef(null);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  // @ts-ignore
  const drawerVariant: 'permanent' | 'temporary' = isLargeScreen
    ? GUI_ENUM.drawer.type.PERMANENT
    : GUI_ENUM.drawer.type.TEMPORARY;
  const isOpened = (isLargeScreen && isMenuOpen === null) || isMenuOpen;

  /**
   * handleMenuToggle
   */
  const handleMenuToggle = () => {
    dispatch(guiUpdate('menuOpen', !isOpened));
  };

  /**
   * handleMenuClose
   */
  const handleMenuClose = () => {
    dispatch(guiUpdate('menuOpen', false));
  };

  if (typeof boxRefCallback === 'function' && boxRef) {
    boxRefCallback(boxRef);
  }

  return (
    <div>
      <CircularLoader active={loading} content={loadingContent} isMainAppLoader>
        <Box sx={{ display: 'flex' }}>
          {hideHeader ? null : (
            <Header
              drawerWidth={showMenu ? GUI_CONFIG.drawerWidth : 0}
              menuOpen={!!showMenu && !!isOpened}
              onMenuToggle={handleMenuToggle}
              showMenu={showMenu}
              {...(isLargeScreen ? null : { onMenuItemClick: handleMenuClose })}
              drawerVariant={drawerVariant}
              title={title}
            />
          )}
          <Box
            ref={boxRef}
            component="main"
            className={style.mainBox}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              ...(!hideHeader && drawerVariant === GUI_ENUM.drawer.type.PERMANENT
                ? {
                    transition: theme.transitions.create(['width', 'margin'], {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                    }),
                    marginLeft: showMenu
                      ? isOpened
                        ? `${GUI_CONFIG.drawerWidth}px`
                        : `calc(${theme.spacing(7)} + 1px)`
                      : 0,
                  }
                : null),
            }}
          >
            {hideHeader ? null : <Toolbar />}
            {/* @ts-ignore */}
            <NotificationMessagesContainer
              {...(hideHeader ? null : { className: style.appNotificationsContainer })}
              placeholder={ERRORS_ENUM.placeholders.APP}
              fixed
            />
            {/* @ts-ignore */}
            <NotificationMessagesContainer placeholder={ERRORS_ENUM.placeholders.SNACKBAR} />
            <Container
              maxWidth="xl"
              disableGutters
              sx={{
                pl: 3,
                pr: 3,
                mt: 6,
                mb: 6,
                ...(includesFab
                  ? {
                      mb: { xs: 12, lg: 6 },
                      pl: { xs: 3, lg: 5 },
                    }
                  : null),
                ...containerStyles,
              }}
            >
              {topHint || null}
              {/* @ts-ignore */}
              <NotificationMessagesContainer placeholder={ERRORS_ENUM.placeholders.PAGE} />
              {breadcrumbs ? (
                <Breadcrumbs separator="|" sx={{ fontSize: '14px', mt: 1, mb: 3 }}>
                  {breadcrumbs}
                </Breadcrumbs>
              ) : null}
              {children}
            </Container>
          </Box>
        </Box>
      </CircularLoader>
    </div>
  );
};

DefaultLayout.defaultProps = {
  showMenu: true,
};
