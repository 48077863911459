import { FormattedMessage } from 'react-intl';
import Chip from '@mui/material/Chip';
import { components as COMPONENTS_CONFIG } from '../../config/config.js';

type Props = {
  type: string;
  status: string | number;
  translationPrefix?: string;
};

/**
 * Status
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export function Status({ type, status, translationPrefix }: Props): JSX.Element {
  const TRANSLATION_PREFIX: Record<string, string> = {
    admin: 'components.admin.status',
    affiliate: 'components.affiliate.status',
    article: 'components.article.status',
    artist: 'components.artist.status',
    collection: 'components.collection.status',
    partner: 'components.partner.status',
    partnerArtist: 'components.partner.artist.status',
    partnerUser: 'components.partner.user.status',
    seller: 'components.seller.status',
    user: 'components.user.status',
    userAccount: 'components.user.account.status',
    event: 'components.event.status',
    file: 'components.file.status',
    cmsPage: 'components.cms.page.status',
    cmsModule: 'components.cms.module.status',
    cmsModuleInstance: 'components.cms.module.instance.status',
    offer: 'components.offer.status',
  };
  // @ts-ignore
  const statusConfig: Record<string, any> = COMPONENTS_CONFIG.status[type];

  return (
    <Chip
      color={(statusConfig && statusConfig[`${status}`]) || 'default'}
      label={<FormattedMessage id={`${translationPrefix || TRANSLATION_PREFIX[type]}.${status}`} />}
    />
  );
}
