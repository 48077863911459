import { FormattedDate, FormattedMessage } from 'react-intl';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { Link } from '@riseart/dashboard';
import { event as EVENT_ENUM } from '../../../../config/enumeration.js';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { DEFAULT_COLUMN_PROPS, dataPickerActionColumn, DataTableSwitch } from '../utils';
import { Status } from '../../../common/Status';
import {
  filterSelectOptions,
  filterSelectBoolean,
  DISCRETE_TYPE_OPERATORS,
} from '../../../data/tables/filters/utils';

const SHOWCASE = ({ formatMessage, updateRequest, refetchListQuery }: Record<string, any>) => [
  {
    field: 'showcase',
    type: 'singleSelect',
    headerName: formatMessage({ id: 'components.tables.columns.common.showcase' }),
    filterable: true,
    sortable: true,
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
      <DataTableSwitch
        updateRequest={updateRequest}
        refetchListQuery={refetchListQuery}
        valueKey="showcase"
        value={data.showcase}
        resourceId={data.id}
      />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    valueOptions: filterSelectBoolean(formatMessage),
  },
];

const EVENTLIST_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink, updateRequest, refetchListQuery }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.eventDetails(data.id)} title={data.name}>
                {data.name}
              </Link>
            );
          },
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'type',
    type: 'singleSelect',
    width: 100,
    headerName: formatMessage({ id: 'components.tables.columns.common.type' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <FormattedMessage id={`components.event.type.${data.type}`} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    // @ts-ignore
    valueOptions: filterSelectOptions(EVENT_ENUM.type, 'components.event.type', formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'creatorId',
    type: 'singleSelect',
    width: 150,
    sortable: false,
    filterable: false,
    headerName: formatMessage({ id: 'components.tables.columns.event.creator' }),
    renderCell: ({ row: data }: Record<string, any>) => {
      if (data.creatorId && data.creatorName) {
        if (showNameAsLink) {
          const creatorUrl = UrlAssembler.eventCreator(
            data.creatorType,
            data.creatorRole,
            data.creatorId,
          );

          return creatorUrl ? (
            <Link to={creatorUrl} title={data.creatorName}>
              {data.creatorName}
            </Link>
          ) : (
            data.creatorName
          );
        }

        return data.creatorName;
      }

      return null;
    },
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'singleSelect',
    width: 100,
    headerName: formatMessage({ id: 'components.tables.columns.common.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="event" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    // @ts-ignore
    valueOptions: filterSelectOptions(EVENT_ENUM.status, 'components.event.status', formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'venueName',
    type: 'string',
    width: 150,
    sortable: false,
    filterable: false,
    headerName: formatMessage({ id: 'components.tables.columns.event.venueName' }),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'startDate',
    type: 'singleSelect',
    width: 180,
    sortable: false,
    filterable: false,
    headerName: formatMessage({ id: 'components.tables.columns.event.dates' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      data.startDate || data.endDate ? (
        <>
          {data.startDate ? <FormattedDate value={data.startDate} /> : null}
          {data.endDate ? (
            <>
              {' '}
              - <FormattedDate value={data.endDate} />
            </>
          ) : null}
        </>
      ) : null,
  },
  ...SHOWCASE({ formatMessage, showNameAsLink, updateRequest, refetchListQuery }),
];

export const EVENTLIST_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (options: Record<string, any>) => [
  ...EVENTLIST_BASE_DATAGRID_TABLE_SCHEMA(options),
];

export const EVENTLIST_PICKER_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (options: Record<string, any>) => [
  ...EVENTLIST_BASE_DATAGRID_TABLE_SCHEMA(options),
  dataPickerActionColumn(options.formatMessage),
];
