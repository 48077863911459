export const FILTER_CONFIG = [
  { columnField: 'id', apiField: 'id', type: 'integer' },
  { columnField: 'title', apiField: 'title', type: 'string' },
  { columnField: 'name', apiField: 'name', type: 'string' },
  {
    columnField: 'state',
    apiField: 'state',
    type: 'discrete',
    filterType: 'basic',
    operators: [{ isDefault: true, name: 'is' }],
  },
  { columnField: 'type', apiField: 'type', type: 'discrete' },
  { columnField: 'sku', apiField: 'sku', type: 'string' },
  { columnField: 'medium', apiField: 'medium', type: 'string' },
  { columnField: 'subject', apiField: 'subject', type: 'string' },
  { columnField: 'price', apiField: 'price', type: 'float' },
];

export const SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'title' },
  { columnField: 'name' },
  { columnField: 'state' },
  { columnField: 'type' },
  { columnField: 'sku' },
  { columnField: 'medium' },
  { columnField: 'subject' },
  { columnField: 'price' },
];
