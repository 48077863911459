export const FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'firstName', apiField: 'firstName', type: 'string' },
  { columnField: 'lastName', apiField: 'lastName', type: 'string' },
  {
    columnField: 'status',
    apiField: 'status',
    type: 'discrete',
    filterType: 'basic',
  },
  { columnField: 'role', apiField: 'role', type: 'discrete' },
  { columnField: 'email', apiField: 'email', type: 'string' },
];

export const SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'firstName', apiField: 'first' },
  { columnField: 'lastName', apiField: 'last' },
  { columnField: 'role', apiField: 'role' },
  { columnField: 'status', apiField: 'status' },
  { columnField: 'email', apiField: 'email' },
];

export const FILTER_PRIVACY_SETTINGS_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'category', apiField: 'category', type: 'discrete', filterType: 'basic' },
];
