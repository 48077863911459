import { FormattedMessage } from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import { application as APP_CONFIG } from '../../../config/config.js';
import { storeOptionsList } from '../utils';

export function localeOptionsList({ initialOption }: { initialOption: any }): JSX.Element[] {
  return [
    ...(initialOption ? [initialOption] : []),
    ...APP_CONFIG.i18n.locales.map(({ name }: Record<string, any>) => (
      <MenuItem key={name} value={name}>
        {<FormattedMessage id={`locales.${name.toLowerCase()}`} />}
      </MenuItem>
    )),
  ];
}

export const SettingsFormModel = {
  settings: {},
  fields: [
    {
      tag: 'select',
      type: 'text',
      name: 'store',
      selectOptions: storeOptionsList,
      placeholder: 'forms.settings.label.store',
    },
    {
      tag: 'select',
      type: 'text',
      name: 'locale',
      selectOptions: localeOptionsList,
      placeholder: 'forms.settings.label.language',
    },
  ],
};
