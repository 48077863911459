import { FormattedDate, FormattedMessage } from 'react-intl';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import Switch from '@mui/material/Switch';
import { Link, SplitButton } from '@riseart/dashboard';
import { article as ARTICLE_ENUM } from '../../../../config/enumeration.js';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { delay } from '../../../../services/riseart/utils/Utils';
import {
  DEFAULT_COLUMN_PROPS,
  dataPickerActionColumn,
  renderLocalesList,
  DataTableSwitch,
} from '../utils';
import { filterSelectOptions, DISCRETE_TYPE_OPERATORS } from '../filters/utils';
import { filterSelectBoolean, filterSelectLocale } from '../../../data/tables/filters/utils';
import { Status } from '../../../common/Status';
import { SchemaType } from './types';

const FIELD_PUBLISH_DATE = ({ formatMessage }: Record<string, any>) => ({
  ...DEFAULT_COLUMN_PROPS,
  field: 'publishedDate',
  type: 'date',
  width: 180,
  headerName: formatMessage({ id: 'components.tables.columns.article.publishedDate' }),
  renderCell: ({ row: data }: Record<string, any>) => (
    <FormattedDate
      value={data.publishedDate}
      year="numeric"
      month="short"
      day="numeric"
      weekday="short"
    />
  ),
});

const SHOWCASE = ({ formatMessage, updateRequest, refetchListQuery }: Record<string, any>) => ({
  field: 'showcase',
  type: 'singleSelect',
  headerName: formatMessage({ id: 'components.tables.columns.common.showcase' }),
  filterable: true,
  sortable: true,
  renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
    <DataTableSwitch
      updateRequest={updateRequest}
      refetchListQuery={refetchListQuery}
      valueKey="showcase"
      value={data.showcase}
      resourceId={data.id}
    />
  ),
  filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
  valueOptions: filterSelectBoolean(formatMessage),
});

const ARTICLE_FIELDS = ({
  formatMessage,
  showTitleAsLink,
  updateRequest,
  refetchListQuery,
}: Record<string, any>) => ({
  ID: {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    ...(showTitleAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.articleDetails(data.id)} title={data.id}>
                {data.id}
              </Link>
            );
          },
        }
      : null),
  },
  TYPE: {
    field: 'type',
    type: 'string',
    minWidth: 120,
    filterable: true,
    headerName: formatMessage({ id: 'components.tables.columns.common.type' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      data && data.type ? <FormattedMessage id={`components.article.type.${data.type}`} /> : null,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(ARTICLE_ENUM.type, 'components.article.type', formatMessage),
  },
  TITLE: {
    ...DEFAULT_COLUMN_PROPS,
    field: 'title',
    type: 'string',
    minWidth: 250,
    flex: 2,
    headerName: formatMessage({ id: 'components.tables.columns.common.title' }),
    filterable: true,
    ...(showTitleAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.articleDetails(data.id)} title={data.title}>
                {data.title}
              </Link>
            );
          },
        }
      : null),
  },
  STATUS: {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'string',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.article.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="article" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      ARTICLE_ENUM.status,
      'components.article.status',
      formatMessage,
    ),
  },
  LOCALE: {
    ...DEFAULT_COLUMN_PROPS,
    field: 'locales',
    type: 'string',
    flex: 1,
    sortable: false,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectLocale(formatMessage),
    headerName: formatMessage({ id: 'components.tables.columns.common.inLocales' }),
    renderCell: ({ row: data }: Record<string, any>) => renderLocalesList({ data }),
  },
  AUTHOR: {
    ...DEFAULT_COLUMN_PROPS,
    field: 'authorName',
    type: 'string',
    minWidth: 150,
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.article.author' }),
    renderCell: ({ row: data }: Record<string, any>) => {
      if (!data.author) {
        return null;
      }

      return showTitleAsLink ? (
        <Link to={UrlAssembler.authorDetails(data.author.id)} title={data.author.fullName}>
          {data.author.fullName}
        </Link>
      ) : (
        data.author.fullName
      );
    },
  },
  PUBLISH_DATE: {
    ...DEFAULT_COLUMN_PROPS,
    field: 'publishedDate',
    type: 'date',
    width: 180,
    headerName: formatMessage({ id: 'components.tables.columns.article.publishedDate' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <FormattedDate
        value={data.publishedDate}
        year="numeric"
        month="short"
        day="numeric"
        weekday="short"
      />
    ),
  },
  SHOWCASE: {
    field: 'showcase',
    type: 'singleSelect',
    headerName: formatMessage({ id: 'components.tables.columns.common.showcase' }),
    filterable: true,
    sortable: true,
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
      <DataTableSwitch
        updateRequest={updateRequest}
        refetchListQuery={refetchListQuery}
        valueKey="showcase"
        value={data.showcase}
        resourceId={data.id}
      />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    valueOptions: filterSelectBoolean(formatMessage),
  },
});

export const ARTICLELIST_BASIC_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showTitleAsLink = true }) => [
  ARTICLE_FIELDS({ formatMessage, showTitleAsLink }).ID,
  {
    field: 'type',
    type: 'string',
    minWidth: 120,
    filterable: true,
    headerName: formatMessage({ id: 'components.tables.columns.common.type' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      data && data.type ? <FormattedMessage id={`components.article.type.${data.type}`} /> : null,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(ARTICLE_ENUM.type, 'components.article.type', formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'title',
    type: 'string',
    minWidth: 250,
    flex: 2,
    headerName: formatMessage({ id: 'components.tables.columns.common.title' }),
    filterable: true,
    ...(showTitleAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.articleDetails(data.id)} title={data.title}>
                {data.title}
              </Link>
            );
          },
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'string',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.article.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="article" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      ARTICLE_ENUM.status,
      'components.article.status',
      formatMessage,
    ),
  },
];

const ARTICLELIST_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showTitleAsLink = true }) => [
  ...ARTICLELIST_BASIC_DATAGRID_TABLE_SCHEMA({ formatMessage, showTitleAsLink }),
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'authorName',
    type: 'string',
    minWidth: 150,
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.article.author' }),
    renderCell: ({ row: data }: Record<string, any>) => {
      if (!data.author) {
        return null;
      }

      return showTitleAsLink ? (
        <Link to={UrlAssembler.authorDetails(data.author.id)} title={data.author.fullName}>
          {data.author.fullName}
        </Link>
      ) : (
        data.author.fullName
      );
    },
  },
];

export const ARTICLELIST_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (options: Record<string, any>) => [
  ...ARTICLELIST_BASE_DATAGRID_TABLE_SCHEMA(options),
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'locales',
    type: 'string',
    flex: 1,
    sortable: false,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectLocale(options.formatMessage),
    headerName: options.formatMessage({ id: 'components.tables.columns.common.inLocales' }),
    renderCell: ({ row: data }: Record<string, any>) => renderLocalesList({ data }),
  },
  FIELD_PUBLISH_DATE(options),
  SHOWCASE(options),
];

export const ARTICLE_DATAPICKER_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (options: Record<string, any>) => [
  ...ARTICLELIST_BASE_DATAGRID_TABLE_SCHEMA(options),
  FIELD_PUBLISH_DATE(options),
  SHOWCASE(options),
  dataPickerActionColumn(options.formatMessage),
];

export const ARTICLE_CATEGORY_BASIC_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showTitleAsLink = true }: Record<string, any>) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    minWidth: 250,
    flex: 2,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    filterable: true,
    ...(showTitleAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.articleCategoryDetails(data.id)} title={data.name}>
                {data.name}
              </Link>
            );
          },
        }
      : null),
  },
  {
    field: 'uri',
    type: 'string',
    minWidth: 200,
    headerName: formatMessage({ id: 'components.tables.columns.common.uri' }),
    filterable: true,
    sortable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    field: 'type',
    type: 'string',
    minWidth: 100,
    headerName: formatMessage({ id: 'components.tables.columns.common.type' }),
    filterable: true,
    sortable: true,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      ARTICLE_ENUM.category.type,
      'components.article.category.type',
      formatMessage,
    ),
    valueFormatter: (params: GridValueFormatterParams) =>
      formatMessage({ id: `components.article.category.type.${params.value}` }),
  },
  {
    field: 'parent',
    type: 'string',
    minWidth: 150,
    headerName: formatMessage({ id: 'components.tables.columns.common.parent' }),
    filterable: false,
    sortable: false,
    renderCell: ({ row: data }: Record<string, any>) => {
      if (data && data.parent) {
        return showTitleAsLink ? (
          <Link to={UrlAssembler.articleCategoryDetails(data.parent.id)} title={data.parent.name}>
            {data.parent.name}
          </Link>
        ) : (
          data.parent.name
        );
      }

      return null;
    },
  },
];

export const ARTICLE_CATEGORY_LIST_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (options: Record<string, any>) => [
  ...ARTICLE_CATEGORY_BASIC_TABLE_SCHEMA(options),
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'locales',
    type: 'string',
    flex: 1,
    minWidth: 170,
    sortable: false,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectLocale(options.formatMessage),
    headerName: options.formatMessage({ id: 'components.tables.columns.common.inLocales' }),
    renderCell: ({ row: data }: Record<string, any>) => renderLocalesList({ data }),
  },
];

export const ARTICLE_CATEGORY_DATAPICKER_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (options: Record<string, any>) => [
  ...ARTICLE_CATEGORY_BASIC_TABLE_SCHEMA(options),
  dataPickerActionColumn(options.formatMessage),
];

export const RELATED_ARTICLES_SCHEMA: (properties: Record<string, any>) => GridColumns<any> = ({
  formatMessage,
  showTitleAsLink = true,
  deleteLoading,
  deleteMutation,
  refetchListQuery,
}) => {
  const { ID, TYPE, TITLE, AUTHOR } = ARTICLE_FIELDS({
    formatMessage,
    showTitleAsLink,
    deleteLoading,
    deleteMutation,
    refetchListQuery,
  });

  return [
    ID,
    TYPE,
    TITLE,
    AUTHOR,
    {
      field: 'customData',
      headerName: '',
      filterable: false,
      sortable: false,
      renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
        return (
          <SplitButton
            disabled={deleteLoading}
            mainButtonProps={{
              actionKey: 'delete',
              onClick: () => {
                deleteMutation({ resourceId: data.id })
                  .then(() => typeof refetchListQuery === 'function' && delay(refetchListQuery))
                  .catch(() => null);
              },
              requireConfirmation: true,
              children: <FormattedMessage id="common.delete" />,
            }}
            translationPrefix="components.dialog.article.action"
            confirmationDialogProps={splitButtonConfirmationProps}
            options={[]}
          />
        );
      },
    },
  ];
};

export const RELATED_CATEGORY_ARTICLES_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({
  formatMessage,
  showTitleAsLink = true,
  deleteLoading,
  deleteMutation,
  refetchListQuery,
}) => {
  const { ID, TYPE, TITLE, STATUS, LOCALE, SHOWCASE } = ARTICLE_FIELDS({
    formatMessage,
    showTitleAsLink,
    deleteLoading,
    deleteMutation,
    refetchListQuery,
  });

  return [
    ID,
    TYPE,
    TITLE,
    STATUS,
    LOCALE,
    SHOWCASE,
    {
      field: 'customData',
      headerName: '',
      renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
        return (
          <SplitButton
            disabled={deleteLoading}
            mainButtonProps={{
              actionKey: 'delete',
              onClick: () => {
                deleteMutation({ resourceId: data.id })
                  .then(() => typeof refetchListQuery === 'function' && delay(refetchListQuery))
                  .catch(() => null);
              },
              requireConfirmation: true,
              children: <FormattedMessage id="common.delete" />,
            }}
            translationPrefix="components.dialog.article.action"
            confirmationDialogProps={splitButtonConfirmationProps}
            options={[]}
          />
        );
      },
    },
  ];
};

// Related articles
export const RELATED_ARTICLE_SCHEMA: SchemaType = [
  { key: 'id' },
  {
    key: 'type',
    render: ({ data }: Record<string, any>) =>
      data && data.type ? <FormattedMessage id={`components.article.type.${data.type}`} /> : null,
  },
  { key: 'title' },
  { key: 'author', render: ({ data: { author } }) => author && author.fullName },
  {
    key: 'actions',
    render: ({
      data: { id },
      customData: { deleteMutation, refetchListQuery, deleteLoading },
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          actionKey: 'delete',
          onClick: () => {
            deleteMutation({ resourceId: id })
              .then(() => typeof refetchListQuery === 'function' && delay(refetchListQuery))
              .catch(() => null);
          },
          requireConfirmation: true,
          children: <FormattedMessage id="common.delete" />,
        }}
        translationPrefix="components.dialog.article.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[]}
      />
    ),
  },
];

// Article category table schema
export const ARTICLE_CATEGORY_TABLE_SCHEMA: SchemaType = [
  { key: 'id' },
  { key: 'name' },
  { key: 'uri' },
  {
    key: 'parent',
    render: ({ data }) => <Switch checked={!!data.parent} disabled />,
  },
  {
    key: 'actions',
    render: ({
      data: { id },
      customData: { deleteMutation, refetchListQuery, entityId, endpoint },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          actionKey: 'deleteCategory',
          requireConfirmation: true,
          onClick: () => {
            deleteMutation({ endpoint: `${endpoint}/${id}/articles/${entityId}` })
              .then(() => typeof refetchListQuery === 'function' && delay(() => refetchListQuery()))
              .catch(() => null);
          },
          children: <FormattedMessage id="common.delete" />,
        }}
        translationPrefix="components.dialog.article.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[]}
      />
    ),
  },
];

//  Articles artist
export const ARTICLE_ARTIST_SCHEMA: SchemaType = [
  { key: 'id' },
  {
    key: 'relationId',
    render: ({
      data: { relationType, relationId, relationName },
    }: Record<string, any>): JSX.Element => {
      switch (relationType) {
        case 'artist':
          return (
            <Link to={UrlAssembler.artistDetails(relationId)} title={relationName}>
              {relationName}
            </Link>
          );
        case 'collection':
          return (
            <Link to={UrlAssembler.collectionDetails(relationId)} title={relationName}>
              {relationName}
            </Link>
          );
        default:
          return relationName;
      }
    },
  },
  {
    key: 'showInRelation',
    render: ({ data, customData: { updateRequest, refetchListQuery } }) => (
      <Switch
        checked={data.showInRelation}
        onChange={(e: Record<string, any>) => {
          updateRequest &&
            updateRequest({ resourceId: data.id, parameters: { showInRelation: e.target.checked } })
              .then(() => typeof refetchListQuery === 'function' && delay(refetchListQuery))
              .catch(() => null);
        }}
      />
    ),
  },
  {
    key: 'actions',
    render: ({
      data: { relationId },
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          actionKey: 'delete',
          onClick: () => {
            deleteMutation({ resourceId: relationId })
              .then(() => typeof refetchListQuery === 'function' && delay(refetchListQuery))
              .catch(() => null);
          },
          requireConfirmation: true,
          children: <FormattedMessage id="common.delete" />,
        }}
        translationPrefix="components.dialog.article.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[]}
      />
    ),
  },
];
