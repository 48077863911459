import {
  Partner as PartnerBaseModel,
  PartnerPaginationResult as PartnerPaginationResultBaseModel,
  PartnerArtist as PartnerArtistBaseModel,
  PartnerArtistPaginationResult as PartnerArtistPaginationResultBaseModel,
  PartnerAdmin as PartnerAdminBaseModel,
  PartnerAdminPaginationResult as PartnerAdminPaginationResultBaseModel,
  PartnerInvitation as PartnerInvitationBaseModel,
  PartnerInvitationPaginationResult as PartnerInvitationPaginationResultBaseModel,
} from '@riseart/models';
import { FormDataMixin } from './Core';
import { ApiInvalidData } from '../errors/ApiInvalidData';
import { PaginationInfo } from './List';

/**
 * Partner
 */
class Partner extends FormDataMixin(PartnerBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {Partner}
   */
  public hydrateFromApiData(data?: Record<string, any>): Partner {
    try {
      // Deconstruct
      const { country: countryData, ...entityData } = data || {};

      // Hydrate model
      return this.hydrate({
        countryCode: (countryData && countryData.code) || null,
        ...entityData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Partner from provided data');
    }
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {Partner}
   */
  public hydrateFromFormData(formData: Record<string, any>): Partner {
    return this.hydrate({ id: this.id, ...formData });
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const { countryCode, ...restData } = super.mapFormToApiData(
      data,
      fieldsSchema,
      includeNullValues,
    );
    return { country: countryCode, ...restData };
  }
}

/**
 * PartnerPaginationResult
 */
class PartnerPaginationResult extends PartnerPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {PartnerPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<Partner> = itemsData
        ? itemsData.map((item: Record<string, any>) => new Partner().hydrateFromApiData(item))
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({ items, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load PartnerPaginationResult from provided data');
    }
  }
}

/**
 * PartnerArtist
 */
class PartnerArtist extends FormDataMixin(PartnerArtistBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerArtist {
    try {
      return this.hydrate(data);
    } catch (error) {
      throw new ApiInvalidData('Unable to load PartnerArtist from provided data.');
    }
  }
}

/**
 * PartnerArtistPaginationResult
 */
class PartnerArtistPaginationResult extends PartnerArtistPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerArtistPaginationResult {
    try {
      const { items, ...paginationData } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const PartnerArtistModel = new PartnerArtist();
            PartnerArtistModel.hydrateFromApiData(item);

            return PartnerArtistModel;
          })
        : {};

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      return this.hydrate({ items: listData, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load PartnerArtist from provided data.');
    }
  }
}

/**
 * PartnerAdmin
 */
class PartnerAdmin extends FormDataMixin(PartnerAdminBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerAdmin {
    try {
      const { user, ...rootProps } = data || {};

      // Flatten user properties
      const userData = user
        ? {
            userStatus: user.status,
            userEmail: user.email,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userRole: user.role,
          }
        : {};

      return this.hydrate({ ...rootProps, ...userData });
    } catch (error) {
      throw new ApiInvalidData('Unable to load PartnerAdmin from provided data.');
    }
  }

  /**
   * fullName
   *
   * @returns {string | null}
   */
  public get fullName(): string | null {
    return this.userFirstName || this.userLastName
      ? [this.userFirstName, this.userLastName].filter((name: string) => !!name).join(' ')
      : null;
  }
}

/**
 * PartnerAdminPaginationResult
 */
class PartnerAdminPaginationResult extends PartnerAdminPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerAdminPaginationResult {
    try {
      const { items, ...paginationData } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const PartnerAdminModel = new PartnerAdmin();
            PartnerAdminModel.hydrateFromApiData(item);

            return PartnerAdminModel;
          })
        : {};

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      return this.hydrate({ items: listData, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load PartnerAdminPaginationResult from provided data.');
    }
  }
}

/**
 * PartnerInvitation
 */
class PartnerInvitation extends FormDataMixin(PartnerInvitationBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerInvitation {
    try {
      return this.hydrate(data);
    } catch (error) {
      throw new ApiInvalidData('Unable to load PartnerInvitation from provided data.');
    }
  }
}

/**
 * PartnerInvitationPaginationResult
 */
class PartnerInvitationPaginationResult extends PartnerInvitationPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): PartnerInvitationPaginationResult {
    try {
      const { items, ...paginationData } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const PartnerInvitationModel = new PartnerInvitation();
            PartnerInvitationModel.hydrateFromApiData(item);

            return PartnerInvitationModel;
          })
        : {};

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      return this.hydrate({ items: listData, pagination });
    } catch (error) {
      throw new ApiInvalidData(
        'Unable to load PartnerInvitationPaginationResult from provided data.',
      );
    }
  }
}

export {
  Partner,
  PartnerPaginationResult,
  PartnerArtist,
  PartnerArtistPaginationResult,
  PartnerAdmin,
  PartnerAdminPaginationResult,
  PartnerInvitation,
  PartnerInvitationPaginationResult,
};
