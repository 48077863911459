import { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ROUTER_CONFIG } from '../../config/router.js';
import { RouterPage } from './Page';

// List of view components
const VIEWS_COMPONENTS: any = {
  Home: lazy(() => import('../pages/Home')),
  Login: lazy(() => import('../pages/Login')),
  Signout: lazy(() => import('../pages/Signout')),
  NotFound: lazy(() => import('../pages/error/NotFound')),
  ChangePassword: lazy(() => import('../pages/me/ChangePassword')),
  ArtList: lazy(() => import('../pages/art/List')),
  ArtDetails: lazy(() => import('../pages/art/Details')),
  ArtAdd: lazy(() => import('../pages/art/Add')),
  ArtEdit: lazy(() => import('../pages/art/Edit')),
  ArtistList: lazy(() => import('../pages/artist/List')),
  ArtistDetails: lazy(() => import('../pages/artist/Details')),
  ArtistAdd: lazy(() => import('../pages/artist/Add')),
  ArtistEdit: lazy(() => import('../pages/artist/Edit')),
  SellerList: lazy(() => import('../pages/seller/List')),
  SellerDetails: lazy(() => import('../pages/seller/Details')),
  SellerAdd: lazy(() => import('../pages/seller/Add')),
  SellerEdit: lazy(() => import('../pages/seller/Edit')),
  SellerShippingRates: lazy(() => import('../pages/seller/shipping/Rates')),
  PartnerList: lazy(() => import('../pages/partner/List')),
  PartnerDetails: lazy(() => import('../pages/partner/Details')),
  PartnerAdd: lazy(() => import('../pages/partner/Add')),
  PartnerEdit: lazy(() => import('../pages/partner/Edit')),
  CollectionList: lazy(() => import('../pages/collection/List')),
  CollectionDetails: lazy(() => import('../pages/collection/Details')),
  CollectionAdd: lazy(() => import('../pages/collection/Add')),
  CollectionEdit: lazy(() => import('../pages/collection/Edit')),
  ArticleList: lazy(() => import('../pages/article/List')),
  ArticleDetails: lazy(() => import('../pages/article/Details')),
  ArticleAdd: lazy(() => import('../pages/article/Add')),
  ArticleEdit: lazy(() => import('../pages/article/Edit')),
  ArticleEditBody: lazy(() => import('../pages/article/EditBody')),
  UserList: lazy(() => import('../pages/user/List')),
  UserDetails: lazy(() => import('../pages/user/Details')),
  UserEdit: lazy(() => import('../pages/user/Edit')),
  EventList: lazy(() => import('../pages/event/List')),
  EventDetails: lazy(() => import('../pages/event/Details')),
  EventAdd: lazy(() => import('../pages/event/Add')),
  EventEdit: lazy(() => import('../pages/event/Edit')),
  FileList: lazy(() => import('../pages/file/List')),
  FileDetails: lazy(() => import('../pages/file/Details')),
  FileAdd: lazy(() => import('../pages/file/Add')),
  FileEdit: lazy(() => import('../pages/file/Edit')),
  AuthorList: lazy(() => import('../pages/author/List')),
  AuthorDetails: lazy(() => import('../pages/author/Details')),
  AuthorAdd: lazy(() => import('../pages/author/Add')),
  AuthorEdit: lazy(() => import('../pages/author/Edit')),
  AdminList: lazy(() => import('../pages/system/admin/List')),
  AdminDetails: lazy(() => import('../pages/system/admin/Details')),
  AdminAdd: lazy(() => import('../pages/system/admin/Add')),
  AdminEdit: lazy(() => import('../pages/system/admin/Edit')),
  CacheList: lazy(() => import('../pages/system/cache/List')),
  ArticleCategoryList: lazy(() => import('../pages/system/category/List')),
  ArticleCategoryDetails: lazy(() => import('../pages/system/category/Details')),
  ArticleCategoryAdd: lazy(() => import('../pages/system/category/Add')),
  ArticleCategoryEdit: lazy(() => import('../pages/system/category/Edit')),
  SettingsList: lazy(() => import('../pages/system/settings/List')),
  SettingsDetails: lazy(() => import('../pages/system/settings/Details')),
  SettingsAdd: lazy(() => import('../pages/system/settings/Add')),
  SettingsEdit: lazy(() => import('../pages/system/settings/Edit')),
  CmsPageList: lazy(() => import('../pages/cms/page/List')),
  CmsPageDetails: lazy(() => import('../pages/cms/page/Details')),
  CmsPageAdd: lazy(() => import('../pages/cms/page/Add')),
  CmsPageEdit: lazy(() => import('../pages/cms/page/Edit')),
  CmsViewList: lazy(() => import('../pages/cms/view/List')),
  CmsViewDetails: lazy(() => import('../pages/cms/view/Details')),
  CmsViewAdd: lazy(() => import('../pages/cms/view/Add')),
  CmsViewEdit: lazy(() => import('../pages/cms/view/Edit')),
  CmsModuleInstanceList: lazy(() => import('../pages/cms/instance/module/List')),
  CmsModuleInstanceDetails: lazy(() => import('../pages/cms/instance/module/Details')),
  CmsModuleInstanceAdd: lazy(() => import('../pages/cms/instance/module/Add')),
  CmsModuleInstanceEdit: lazy(() => import('../pages/cms/instance/module/Edit')),
  OfferList: lazy(() => import('../pages/offer/List')),
  OfferDetails: lazy(() => import('../pages/offer/Details')),
  OfferAdd: lazy(() => import('../pages/offer/Add')),
  OfferEdit: lazy(() => import('../pages/offer/Edit')),
  AffiliateList: lazy(() => import('../pages/affiliate/List')),
  AffiliateDetails: lazy(() => import('../pages/affiliate/Details')),
};

/**
 * RouterRoutes
 *
 * @param {Record<string, any>} appProps
 * @returns {JSX.Element}
 */
export const RouterRoutes = (appProps: Record<string, any>): JSX.Element => (
  <Switch>
    {ROUTER_CONFIG.map((route: Record<string, any>) => (
      <Route
        exact={route.exact}
        path={route.path}
        key={route.key}
        render={(props: Record<string, any>) => {
          const ViewComponent = VIEWS_COMPONENTS[route.component];

          if (!ViewComponent) {
            throw new Error(`View Component ${route.component} is not implemented yet.`);
          }

          return (
            <RouterPage
              {...appProps}
              location={props.location}
              match={props.match}
              pageRouteConfig={route}
            >
              {({ currentLocale }: { currentLocale: Record<string, any> | null | undefined }) => (
                <ViewComponent
                  {...props}
                  routeConfig={route}
                  currentLocale={currentLocale}
                  {...(route.fallbackComponent && VIEWS_COMPONENTS[route.fallbackComponent]
                    ? { fallbackComponent: VIEWS_COMPONENTS[route.fallbackComponent] }
                    : {})}
                />
              )}
            </RouterPage>
          );
        }}
      />
    ))}
  </Switch>
);
