import { FormattedMessage } from 'react-intl';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { Country, Link, SplitButton } from '@riseart/dashboard';
import { artist as ARTIST_ENUM, profile as PROFILE_ENUM } from '../../../../config/enumeration.js';
import { countries as COUNTRIES_LIST } from '../../../../config/directory.js';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { filterSelectOptions, DISCRETE_TYPE_OPERATORS } from '../../../data/tables/filters/utils';
import {
  DEFAULT_COLUMN_PROPS,
  dataPickerActionColumn,
  renderCountryCell,
  renderLocalesList,
} from '../utils';
import { Status } from '../../../common/Status';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { SchemaType } from './types';

export const ARTISTLIST_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink = false }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.artist.id' }),
    sortingOrder: ['desc', 'asc', null],
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.artist.name' }),
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.artistDetails(data.id)} title={data.name}>
                {data.name}
              </Link>
            );
          },
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'alias',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.artist.alias' }),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.artist.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="artist" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      ARTIST_ENUM.status,
      'components.artist.status',
      formatMessage,
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'livesCountry',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.artist.livesCountry' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
      return <Country code={data.livesCountry} />;
    },
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(COUNTRIES_LIST.all, 'countries', formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'gender',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.gender' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      data.gender ? <FormattedMessage id={`common.gender.${data.gender.toUpperCase()}`} /> : null,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(PROFILE_ENUM.gender, 'common.gender', formatMessage),
  },
];

export const ARTISTLIST_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage }) => [
  ...ARTISTLIST_BASE_DATAGRID_TABLE_SCHEMA({ formatMessage }),
  dataPickerActionColumn(formatMessage),
];

// Awards
export const ARTIST_AWARD_SCHEMA: SchemaType = [
  { key: 'name' },
  { key: 'body' },
  { key: 'city' },
  {
    key: 'countryCode',
    render: renderCountryCell,
  },
  { key: 'year' },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.award.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

// Bibliography
export const ARTIST_BIBLIOGRAPHY_SCHEMA: SchemaType = [
  { key: 'title' },
  { key: 'author' },
  { key: 'publication' },
  { key: 'publisher' },
  { key: 'pages' },
  { key: 'year' },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.bibliography.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

// Degree
export const ARTIST_DEGREE_SCHEMA: SchemaType = [
  { key: 'description' },
  { key: 'university' },
  { key: 'city' },
  {
    key: 'countryCode',
    render: renderCountryCell,
  },
  { key: 'year' },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.degree.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

// Collection
export const ARTIST_COLLECTION_SCHEMA: SchemaType = [
  { key: 'collection' },
  { key: 'city' },
  {
    key: 'countryCode',
    render: renderCountryCell,
  },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.collection.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

// Exhibition
export const ARTIST_EXHIBITION_SCHEMA: SchemaType = [
  { key: 'title' },
  { key: 'type' },
  { key: 'venue' },
  { key: 'year' },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.exhibition.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

// Editorial Curator Thought
export const ARTIST_EDITORIAL_CURATOR_THOUGHT_SCHEMA: SchemaType = [
  {
    key: 'authorName',
    render: ({ data }) => (data && data.author && data.author.fullName) || null,
  },
  {
    key: 'text',
  },
  { key: 'locales', render: renderLocalesList },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.exhibition.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

// Editorial Artist Reason
export const ARTIST_EDITORIAL_REASON_SCHEMA: SchemaType = [
  { key: 'text' },
  { key: 'locales', render: renderLocalesList },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.exhibition.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

// Editorial Artist Quote
export const ARTIST_EDITORIAL_QUOTE_SCHEMA: SchemaType = [
  { key: 'authorName' },
  { key: 'text' },
  { key: 'locales', render: renderLocalesList },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.exhibition.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];
