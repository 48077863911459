import { GUI_ACTIONS } from '../../actions/container';
import { ActionType } from '../../actions/application/gui';
import { application as APP_CONFIG, cookies as COOKIES_CONFIG } from '../../../../config/config.js';
import { getLocaleConfig } from '../../../riseart/utils/Route';
import { Cookies } from '../../../riseart/utils/Cookies/Cookies';

const DEFAULT_STATE = {
  menuOpen: true,
  store: APP_CONFIG.store.default,
  locale: getLocaleConfig(true, 'isDefault').name,
};

/**
 * guiReducer
 *
 * @param {Record<string, any>} state
 * @param {ActionType} action
 * @returns {Record<string, any>}
 */
export function guiReducer(
  state: Record<string, any> = {},
  action: ActionType,
): Record<string, any> {
  switch (action.type) {
    case GUI_ACTIONS.UPDATE_PROPERTY:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };
    case GUI_ACTIONS.UPDATE_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      // Set initial data from cookie or DEFAULT_STATE
      if (!state || !Object.keys(state).length) {
        const guiCookie = Cookies.get(COOKIES_CONFIG.gui.name) || null;
        return { ...DEFAULT_STATE, ...state, ...(guiCookie ? JSON.parse(guiCookie) : null) };
      }
      return { ...state };
  }
}
