import { FormattedMessage } from 'react-intl';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { Link, SplitButton } from '@riseart/dashboard';
import { components as COMPONENTS_CONFIG } from '../../../../config/config.js';
import { art as ART_ENUM, collection as COLLECTION_ENUM } from '../../../../config/enumeration.js';
import {
  DEFAULT_COLUMN_PROPS,
  dataPickerActionColumn,
  renderLocalesList,
  DataTableSwitch,
} from '../utils';
import {
  filterSelectOptions,
  filterSelectBoolean,
  filterSelectLocale,
  DISCRETE_TYPE_OPERATORS,
} from '../../../data/tables/filters/utils';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { Status } from '../../../common/Status';
import { SchemaType } from './types';
import { Picture } from '../../../common/artdirection/Picture';

export const COLLECTIONLIST_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink, updateRequest, refetchListQuery }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.collectionDetails(data.id)} title={data.id}>
                {data.id}
              </Link>
            );
          },
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.collectionDetails(data.id)} title={data.name}>
                {data.name}
              </Link>
            );
          },
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'singleSelect',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.common.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="collection" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    // @ts-ignore
    valueOptions: filterSelectOptions(
      COLLECTION_ENUM.status,
      'components.collection.status',
      formatMessage,
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'slug',
    type: 'string',
    width: 250,
    headerName: formatMessage({ id: 'components.tables.columns.common.slug' }),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'hide',
    type: 'singleSelect',
    width: 100,
    headerName: formatMessage({ id: 'components.tables.columns.collection.hide' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <DataTableSwitch
        updateRequest={updateRequest}
        refetchListQuery={refetchListQuery}
        valueKey="hide"
        value={data.hide}
        resourceId={data.id}
      />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    valueOptions: filterSelectBoolean(formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'locales',
    type: 'string',
    flex: 1,
    width: 10,
    sortable: false,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectLocale(formatMessage),
    headerName: formatMessage({ id: 'components.tables.columns.common.inLocales' }),
    renderCell: ({ row: data }: Record<string, any>) => renderLocalesList({ data }),
  },
];

// Collection Art table schema
export const COLLECTION_ART_TABLE_SCHEMA: SchemaType = [
  { key: 'artId' },
  {
    key: 'image',
    render: ({ data }: Record<string, any>): JSX.Element => {
      const { id, title, images } = (data && data.art) || {};
      const image =
        (images &&
          images.getChildByType(
            ART_ENUM.image.type.MAIN_SQUARE,
            ART_ENUM.image.type.MAIN_MASTER,
          )) ||
        COMPONENTS_CONFIG.art.fallbackImages.find(
          ({ type }) => type === ART_ENUM.image.type.MAIN_SQUARE,
        ) ||
        null;

      return (
        <Link to={UrlAssembler.artDetails(id)} title={title}>
          {image ? (
            <Picture type="table.list" artDirectionKey="art.main" image={image} alt={title} />
          ) : null}
        </Link>
      );
    },
  },
  {
    key: 'title',
    render: ({ data }) =>
      data && data.art ? (
        <Link
          to={UrlAssembler.artDetails(data.art.id, data.art.alias)}
          title={data.art.title}
          target="_blank"
        >
          {data.art.title}
        </Link>
      ) : null,
  },
  {
    key: 'artist',
    render: ({ data }) =>
      data && data.art && data.art.artist && data.art.artist.name ? (
        <Link
          to={UrlAssembler.artistDetails(data.art.artist.id, data.art.artist.alias)}
          title={data.art.artist.name}
          target="_blank"
        >
          {data.art.artist.name}
        </Link>
      ) : null,
  },
  {
    key: 'state',
    render: ({ data }: Record<string, any>) => (
      <FormattedMessage id={`components.art.state.${data.art.state}`} />
    ),
  },
  { key: 'description' },
  { key: 'locales', render: renderLocalesList },
  {
    key: 'actions',
    render: ({
      data: { artId },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.collection.artwork"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: artId })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

export const COLLECTIONLIST_PICKER_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (options: Record<string, any>) => [
  ...COLLECTIONLIST_BASE_DATAGRID_TABLE_SCHEMA(options),
  dataPickerActionColumn(options.formatMessage),
];
