export const FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'name', type: 'string' },
  { columnField: 'type', type: 'discrete' },
  { columnField: 'shipsCountry', apiField: 'ships', type: 'discrete' },
  { columnField: 'status', type: 'discrete', filterType: 'basic' },
  { columnField: 'integrationId', apiField: 'integration', type: 'integer' },
];

export const SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'name' },
  { columnField: 'type' },
  { columnField: 'shipsCountry', apiField: 'ships' },
  { columnField: 'status', apiField: 'status' },
  { columnField: 'integrationId', apiField: 'integration' },
];
