// @ts-ignore
import { v4 } from 'uuid';
import find from 'lodash/find';
import every from 'lodash/every';
import get from 'lodash/get';
import { errors as ERRORS_ENUM } from '../../../config/enumeration.js';
import { errors as ERROR_CONFIG } from '../../../config/config.js';
import { RiseartLogger } from '../Logger';
import { addNotification as actionAddNotification } from '../../redux/actions/notifications/notifications';

export type ErrorMessageType = {
  status?: number;
  code?: string;
  type?: string;
  title?: string;
  detail?: string;
  trace?: string[];
  additional?: Record<string, any>;
  handler?: string;
  placeholder?: string;
  level?: number;
  expire?: number | null;
  image?: string;
  log?: boolean;
};

export type ErrorPayloadType = ErrorMessageType & { uid: string };

const { errorsTypes: ERROR_TYPES, overwriteErrors: OVERWRITE_ERRORS } = ERROR_CONFIG;
const {
  handlers: ERROR_HANDLERS,
  levels: ERROR_LEVELS,
  placeholders: ERROR_PLACEHOLDERS,
} = ERRORS_ENUM;
const INTERNAL_ERROR_TYPES = [
  'EvalError',
  'InternalError',
  'RangeError',
  'ReferenceError',
  'SyntaxError',
  'TypeError',
  'URIError',
];

const JS_ERRORS = [EvalError, RangeError, ReferenceError, SyntaxError, TypeError, URIError];

/**
 * isInstanceOfJSError
 *
 * @param {Object} error
 * @returns {boolean}
 */
export const isInstanceOfJSError = (error: Record<string, any>): boolean => {
  return JS_ERRORS.some((err) => error instanceof err);
};

/**
 * isErrorOverwritten
 *
 * OVERWRITE_ERRORS is a list of error fields that can to be overwritten based on given match conditions
 * This array needs to have matchConditions key consisting key-value pairs and each key corresponds to the field
 * that it should match and each value should match the value of this field in the initial error For error fields
 * that have multidimensional structure, the key should separate the fields by using '.'
 *
 * This functionality can be used for any errors (API, Graphql, etc..) although for handling GraphQL errors we have
 * an ErrorLink in Apollo that has the filter functionality which can filter every GraphQL error with a more agile approach.
 *
 * For Example if we want to overwrite an error which is logged with type: 'InvalidField' and has a data: { action: { endpoint: '/art'} }
 * then the rule for this should be { matchConditions: { type: 'InvalidField', 'data.action.endpoint': '/arts' } }.
 *
 * @param {Object} errorData
 * @returns {?Object} returns found surpressed error or undefined
 */
const isErrorOverwritten = (errorData: ErrorMessageType): ErrorMessageType => {
  const matched = find(OVERWRITE_ERRORS, ({ matchConditions }) =>
    every(matchConditions, (value, itemKey) => get(errorData, itemKey) === value),
  );

  return matched ? matched.overwriteFields : {};
};

/**
 * ErrorService
 *
 * A set of error related utilities
 */
export const ErrorService = {
  /**
   * buildPayload
   *
   * @param {ErrorMessageType} error
   * @returns {ErrorPayloadType} error action payload
   */
  buildPayload(error: ErrorMessageType): ErrorPayloadType {
    return {
      uid: v4(),
      ...error,
    };
  },

  /**
   * augmentErrorMessage
   *
   * @param {ErrorMessageType} error
   * @param {Object} config
   * @returns {ErrorMessageType}
   */
  augmentErrorMessage(
    error: ErrorMessageType,
    config: Record<string, any> = ERROR_TYPES,
  ): ErrorMessageType {
    const defaultConfig = config.__defaultError || {};
    const typeConfig =
      (error && error.type && INTERNAL_ERROR_TYPES.indexOf(error.type) !== -1
        ? config.__internalError
        : error && error.type && config[error.type]) || {};

    const overwriteError = isErrorOverwritten(error);

    return {
      ...defaultConfig,
      ...typeConfig,
      ...error,
      ...overwriteError,
    };
  },

  /**
   * mapAxiosError
   *
   * @param {Object} axiosError
   * @returns {ErrorMessageType} mapped data for error middleware
   */
  mapAxiosError({
    data,
    errorType,
    message,
    status,
    statusText,
    config,
    request, // eslint-disable-line
    headers, // eslint-disable-line
    ...additional
  }: Record<string, any>): ErrorMessageType {
    const errorInfo = data && data.error;

    return ErrorService.augmentErrorMessage({
      code: errorInfo.code,
      detail: errorInfo.detail || message || statusText,
      status: errorInfo.status || status,
      title: errorInfo.title,
      trace: errorInfo.trace,
      type: errorInfo.type || errorType,
      additional: {
        path: config.url,
        validation: errorInfo.validation,
        ...additional,
      },
    });
  },

  /**
   * mapJSError
   *
   * @param {Error} jsError
   * @returns {ErrorMessageType} mapped data for error middleware
   */

  mapJSError(
    // @ts-ignore
    { name, message, stack, ...restJSProps }: Error = {},
    additionalProps?: ErrorMessageType,
  ): ErrorMessageType {
    const { additional: customAdditional, ...customProps } = additionalProps || {};
    const additional = {
      ...restJSProps,
      ...customAdditional,
    };

    return ErrorService.augmentErrorMessage({
      // @ts-ignore
      type: get(customProps, 'type') || restJSProps.type || ERROR_TYPES.__internalError.type,
      // @ts-ignore
      trace: restJSProps.trace || stack ? stack.split('\n').map((s: string) => s.trim()) : null,
      // @ts-ignore
      title: restJSProps.title || name,
      // @ts-ignore
      detail: restJSProps.detail || message || ERROR_TYPES.__internalError.detail,
      // @ts-ignore
      ...(restJSProps.code ? { code: restJSProps.code } : {}),
      ...customProps,
      additional,
    });
  },

  /**
   * mapNotification
   *
   * @param {ErrorMessageType} properties
   * @returns {ErrorMessageType} Enhanced ErrorMessageType with new properties
   */
  mapNotification(properties: ErrorMessageType = {}): ErrorMessageType {
    return ErrorService.augmentErrorMessage({
      handler: ERROR_HANDLERS.NOTIFICATION,
      level: ERROR_LEVELS.WARNING,
      placeholder: ERROR_PLACEHOLDERS.APP,
      ...properties,
    });
  },

  /**
   * dispatchHandler
   *
   * Handler to pass error payload to dispatches. Dispatcher can be any
   * function but in most cases it will be dispatch from Redux store
   *
   * @param {(action: Record<string, any>) => any} dispatch
   * @returns {Function}
   */
  dispatchHandler:
    (dispatch: (action: Record<string, any>) => any) =>
    (error: Record<string, any>): void => {
      switch (error.handler) {
        case ERROR_HANDLERS.NOTIFICATION:
          dispatch(ErrorService.handleNotification(error));
          break;
        default:
          break;
      }

      ErrorService.logError(error);
    },

  /**
   * handleNotification
   *
   * Handles notification type errors (shown as notification messages)
   *
   * @param {Record<string, any>} error
   * @returns {Record<string, any>} payload to be dispatched to store
   */
  handleNotification({ level, detail, expire, placeholder }: Record<string, any> = {}): Record<
    string,
    any
  > {
    return actionAddNotification({ level, detail, expire, placeholder });
  },

  /**
   * logError
   *
   * Log error to RiseartLogger if error's log and level are set for specific values
   *
   * @param {Object} error
   * @returns {void}
   */
  logError(error: ErrorMessageType): void {
    const { type, code, detail, level = ERROR_LEVELS.WARNING, log = true } = error;

    if (!log || (log && level >= ERROR_LEVELS.WARNING)) {
      return;
    }

    RiseartLogger.message({
      message: `[${type || 'UnknownType'}][${code || 'UnknownCode'}] ${
        detail || 'No error details set'
      }`,
      level,
      data: { ...error },
    });
  },
};
