export const application = {
  acl: { rolesWildcard: '*', rolesHierarchy: { admin: { super_admin: {} } } },
  store: { default: 'uk' },
  i18n: {
    locales: [
      { name: 'en-GB', language: 'en', basePath: 'en', isDefault: true },
      { name: 'de-DE', language: 'de', basePath: 'de' },
      { name: 'fr-FR', language: 'fr', basePath: 'fr' },
    ],
    currency: { signs: { uk: '£', eu: '€', us: '$' }, codes: { uk: 'GBP', eu: 'EUR', us: 'USD' } },
  },
  countries: { excluded: ['A1', 'A2', 'AP', 'O1', 'EU'] },
  domain: 'admin2.dev.riseart.com',
  host: 'https://admin2.dev.riseart.com',
  environmentLoggerScript: '/assets/scripts/environment.js',
  callbackDelay: 2000,
};
export const libs = {
  events: { tagId: 'ra-events-lib', file: 'track.min.js', namespace: 'raev', source: 'frontend' },
  facebook: {
    statusConnected: 'connected',
    scope: 'email, public_profile',
    sdkVersion: 'v2.8',
    tagId: 'facebook-module',
    source: 'https://connect.facebook.net/en_US/sdk.js',
    scriptTag: 'script',
  },
  google: {
    scope: 'profile',
    auth2: 'auth2',
    tagId: 'google-module',
    source: 'https://apis.google.com/js/client:platform.js?onload=googleLoad',
    scriptTag: 'script',
    jsType: 'text/javascript',
  },
  sentry: {
    accountEndpoint: 'https://3ebf28f691d24bbb8cd81fcab2a1dbd9@o100575.ingest.sentry.io/6368854',
  },
};
export const cookies = { token: { name: '__raatkn' }, gui: { name: '__raagui', expires: 356 } };
export const gui = { drawerWidth: 240 };
export const uri = {
  query: { params: { marketing: 'mktg', visitor: '_vid', message: '_msg' } },
  hash: {},
};
export const store = {
  keys: {
    application: 'application',
    notifications: 'notifications',
    pagePreloader: 'pagePreloader',
    auth: 'auth',
    me: 'me',
    gui: 'gui',
    errors: 'errors',
  },
};
export const externalUrls = {};
export const art = { maxFilesize: 5242880, acceptedFiles: 'image/jpeg, image/png' };
export const article = { maxFilesize: 5242880, acceptedFiles: 'image/jpeg, image/png' };
export const artist = { maxFilesize: 5242880, acceptedFiles: 'image/jpeg, image/png' };
export const collection = { maxFilesize: 5242880, acceptedFiles: 'image/jpeg, image/png' };
export const feed = { maxFilesize: 2097152, acceptedFiles: 'image/jpeg, image/png' };
export const user = { maxFilesize: 1048576, acceptedFiles: 'image/jpeg, image/png' };
export const event = { maxFilesize: 2097152, acceptedFiles: 'image/jpeg, image/png' };
export const file = { maxFilesize: 104857600 };
export const artDirection = {
  common: {
    avatar: [
      { width: 24, height: 'AUTO' },
      { width: 48, height: 'AUTO' },
      { width: 96, height: 'AUTO' },
      { width: 192, height: 'AUTO' },
    ],
  },
  art: {
    main: [
      { width: 140, height: 'AUTO' },
      { width: 280, height: 'AUTO' },
      { width: 350, height: 'AUTO' },
      { width: 560, height: 'AUTO' },
      { width: 700, height: 'AUTO' },
      { width: 1120, height: 'AUTO' },
    ],
  },
  event: {
    main: [
      { width: 375, height: 'AUTO' },
      { width: 550, height: 'AUTO' },
      { width: 750, height: 'AUTO' },
      { width: 1400, height: 'AUTO' },
    ],
  },
  artist: {
    banner: [
      { width: 450, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1100, height: 'AUTO' },
      { width: 2200, height: 'AUTO' },
    ],
    additional: [
      { width: 450, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1600, height: 'AUTO' },
      { width: 2880, height: 'AUTO' },
    ],
    detail: [
      { width: 450, height: 'AUTO' },
      { width: 600, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1200, height: 'AUTO' },
    ],
    feed: [
      { width: 375, height: 'AUTO' },
      { width: 750, height: 'AUTO' },
      { width: 1500, height: 'AUTO' },
    ],
  },
  collection: {
    main: [
      { width: 350, height: 'AUTO' },
      { width: 450, height: 'AUTO' },
      { width: 600, height: 'AUTO' },
      { width: 700, height: 'AUTO' },
      { width: 900, height: 'AUTO' },
      { width: 1200, height: 'AUTO' },
    ],
    square: [
      { width: 300, height: 'AUTO' },
      { width: 360, height: 'AUTO' },
      { width: 600, height: 'AUTO' },
      { width: 720, height: 'AUTO' },
    ],
  },
  article: {
    main: [
      { width: 250, height: 'AUTO' },
      { width: 500, height: 'AUTO' },
      { width: 1200, height: 'AUTO' },
    ],
    banner: [
      { width: 250, height: 'AUTO' },
      { width: 500, height: 'AUTO' },
      { width: 1200, height: 'AUTO' },
    ],
    inline: [
      { width: 360, height: 'AUTO' },
      { width: 720, height: 'AUTO' },
      { width: 1440, height: 'AUTO' },
    ],
  },
};
export const components = {
  terms: { ignoreRouteKeys: ['terms', 'signout', 'login', 'notfound'] },
  status: {
    admin: { 0: 'warning', 1: 'success', 3: 'error' },
    affiliate: { 0: 'warning', 1: 'success' },
    article: { 0: 'warning', 1: 'success' },
    artist: { 0: 'warning', 1: 'success', 2: 'warning', 3: 'error' },
    collection: { 0: 'error', 1: 'success' },
    partner: { 0: 'error', 1: 'success' },
    partnerArtist: { 1: 'success', 2: 'error' },
    partnerUser: { 1: 'success', 2: 'error' },
    seller: { 0: 'error', 1: 'success', 2: 'warning' },
    user: { 0: 'warning', 1: 'success', 2: 'warning', 3: 'error' },
    userAccount: { 0: 'warning', 1: 'success', 2: 'warning', 3: 'error' },
    event: { 1: 'success', 2: 'warning', 3: 'error' },
    file: { 0: 'error', 1: 'success' },
    cmsPage: { 0: 'error', 1: 'success', 2: 'warning' },
    cmsModule: { 0: 'error', 1: 'success' },
    cmsModuleInstance: { 0: 'error', 1: 'success' },
    offer: { pending: 'warning', active: 'success', inactive: 'error' },
  },
  offer: { maxAllowedDiscountPercent: 20 },
  art: {
    fallbackImages: [
      {
        type: 'main-square',
        key: 'static_fallback_art_original-square',
        extension: 'jpg',
        height: 1120,
        width: 1120,
      },
      {
        type: 'main-original',
        key: 'static_fallback_art_original-master',
        extension: 'jpg',
        height: 1120,
        width: 1120,
      },
    ],
  },
};
export const api = {
  endpoints: {
    me: 'me',
    admins: 'admins',
    affiliates: 'affiliates',
    arts: 'arts',
    artists: 'artists',
    articles: 'articles',
    articleCategories: 'articles/categories',
    authors: 'authors',
    events: 'events',
    files: 'files',
    partners: 'partners',
    products: 'products',
    sellers: 'sellers',
    collections: 'collections',
    validations: 'validations',
    users: 'users',
    uploads: 'uploads',
    caches: 'caches',
    urlsMetas: 'urls/metas',
    settings: 'settings',
    cms: 'cms',
    cmsAttributes: 'cms/attributes',
    cmsAttributeInstance: 'cms/attributes/instances',
    cmsModules: 'cms/modules',
    cmsModuleInstance: 'cms/modules/instances',
    cmsViews: 'cms/views',
    cmsViewsPlaceholders: 'cms/views/placeholders',
    cmsPages: 'cms/pages',
    cmsPagesPlaceholders: 'cms/pages/placeholders',
    cmsLayouts: 'cms/layouts',
    cmsLayoutsPlaceholders: 'cms/layouts/placeholders',
    offers: 'offers',
  },
  methods: { post: 'post', put: 'put', patch: 'patch', delete: 'delete', get: 'get' },
  endpoint: 'https://api.dev.riseart.com',
  version: 'v1',
  authKey: '036d84e6-8a34-4cf6-83c0-95f68e63e8ac',
};
export const errors = {
  errorsTypes: {
    __defaultError: {
      status: 500,
      code: 'ADMIN_1001',
      type: '__defaultError',
      title: 'errors.default.title',
      detail: 'errors.default.details',
      handler: 'notification',
      level: 10,
      expire: 15,
      image: 'https://d1ee3oaj5b5ueh.cloudfront.net/2017/10/59dcad93c93c0.jpeg',
      log: true,
    },
    __internalError: {
      status: 500,
      code: 'SELR_1002',
      type: 'JSError',
      handler: 'silent',
      level: 10,
    },
    CoreException: { status: 500, code: 'SELR_1010', type: 'CoreException' },
    ForbiddenException: { status: 403, type: 'ForbiddenException' },
    InvalidParamException: { status: 400, type: 'InvalidParamException' },
    MissingParamException: { status: 400, type: 'MissingParamException' },
    PageNotFound: {
      status: 404,
      type: 'PageNotFound',
      title: 'errors.pageNotFound.title',
      detail: 'errors.pageNotFound.details',
      handler: 'errorPage',
    },
    ResourceNotFoundException: {
      status: 404,
      type: 'ResourceNotFoundException',
      title: 'errors.pageNotFound.title',
      detail: 'errors.pageNotFound.details',
      handler: 'errorPage',
    },
    NetworkError: {
      status: 503,
      type: 'NetworkError',
      title: 'errors.networkError.title',
      detail: 'errors.networkError.details',
    },
    TimeoutError: {
      status: 503,
      type: 'NetworkError',
      title: 'errors.timeoutError.title',
      detail: 'errors.timeoutError.details',
    },
    ApiServerError: {
      type: 'ApiServerError',
      title: 'errors.apiServerError.title',
      detail: 'errors.apiServerError.details',
      handler: 'errorPage',
    },
    InvalidAuthorizationToken: {
      type: 'InvalidAuthorizationToken',
      title: 'errors.invalidToken.title',
      detail: 'errors.invalidToken.details',
    },
    InvalidAuthorizeParameters: { type: 'InvalidAuthorizeParameters' },
    AlreadyExistsException: {
      status: 409,
      type: 'AlreadyExistsException',
      handler: 'notification',
      level: 20,
      log: false,
    },
    AuthInvalidPassword: { type: 'AuthInvalidPassword', level: 20, log: false },
    AuthNoExistingUser: { type: 'AuthNoExistingUser', level: 20, log: false },
    ExistsInOtherDomain: { type: 'ExistsInOtherDomain', level: 20, log: false },
  },
  overwriteErrors: [
    {
      matchConditions: { type: 'AuthError' },
      overwriteFields: {
        handler: 'notification',
        log: false,
        title: 'errors.invalidToken.title',
        detail: 'errors.invalidToken.details',
      },
    },
    {
      matchConditions: { detail: 'Artist resource not found', type: 'ResourceNotFoundException' },
      overwriteFields: {
        handler: 'notification',
        log: false,
        title: 'errors.artistNotFound.title',
        detail: 'errors.artistNotFound.details',
      },
    },
    {
      matchConditions: {
        detail: 'Cannot find a valid seller for the given partner',
        status: '404',
        type: 'ResourceNotFoundException',
      },
      overwriteFields: { handler: 'notification', log: false },
    },
  ],
};
export const environment = 'development';
export const auth = { jwt: { leeway: 3600 } };
export const debug = {
  console: true,
  sentry: false,
  redux: true,
  logStoreState: true,
  traceback: true,
};
export const cdn = {
  fileSystem: { host: 'https://d1g280u56q0141.cloudfront.net', thumbs: 'thumbs' },
};
export const files = { s3Bucket: 'https://s3.eu-west-1.amazonaws.com/dev-files.riseart' };
export const frontend = { host: 'https://www.dev.riseart.com' };
export const pages = {
  error: {
    error: {
      defaultBackgroundImage: 'https://d1ee3oaj5b5ueh.cloudfront.net/2017/10/59dcad93c93c0.jpeg',
    },
  },
};
export const version = { number: '20.0.1', hash: '6ea43a7' };
