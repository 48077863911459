import { FormattedMessage } from 'react-intl';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { Link, SplitButton } from '@riseart/dashboard';
import { art as ART_ENUM, product as PRODUCT_ENUM } from '../../../../config/enumeration.js';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import {
  filterSelectOptions,
  DISCRETE_TYPE_OPERATORS,
  DISCRETESINGLE_TYPE_OPERATORS,
} from '../filters/utils';
import { getDimension, getDimensionValues } from '../../../../data/filters/utils';
import { DEFAULT_COLUMN_PROPS, renderLocalesList, dataPickerActionColumn } from '../utils';
import { SchemaType } from './types';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { Picture } from '../../../common/artdirection/Picture';
import { extractImageFileFromData } from '../../../../services/riseart/utils/Image';
import { delay } from '../../../../services/riseart/utils/Utils';

import styles from './schemas.module.css';

export const ARTLIST_DATAGRID_SCHEMA_BASIC: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.art.id' }),
    sortingOrder: ['desc', 'asc', null],
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    headerClassName: styles.columnLeftPadded,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'title',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.art.title' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
      return (
        <Link to={UrlAssembler.artDetails(data.id)} title={data.title}>
          {data.title}
        </Link>
      );
    },
    filterable: true,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.art.artist' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element | null => {
      return data && data.artist ? (
        <Link to={UrlAssembler.artistDetails(data.artist.id)} title={data.artist.name}>
          {data.artist.name}
        </Link>
      ) : null;
    },
    filterable: true,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'state',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.art.state' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <FormattedMessage id={`components.art.state.${data.state}`} />
    ),
    filterOperators: DISCRETESINGLE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      Object.keys(ART_ENUM.state).reduce(
        (accumulator, key) =>
          // @ts-ignore
          key !== 'ART' ? { ...accumulator, [key]: ART_ENUM.state[key] } : accumulator,
        {},
      ),
      'components.art.state',
      formatMessage,
    ),
    sortable: false,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'type',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.art.type' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      data.type && (
        <FormattedMessage id={`components.art.product.type.${data.type.toUpperCase()}`} />
      ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      PRODUCT_ENUM.type,
      'components.art.product.type',
      formatMessage,
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'skuNumber',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.art.sku' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      data.productPrimarySku && data.productPrimarySku.sku,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'seller',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.art.seller' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element | null => {
      return data && data.productPrimarySku && data.productPrimarySku.sellerId ? (
        <Link
          to={UrlAssembler.sellerDetails(data.productPrimarySku.sellerId)}
          title={data.productPrimarySku.sellerName}
        >
          {data.productPrimarySku.sellerName}
        </Link>
      ) : null;
    },
    filterable: false,
    sortable: false,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'medium',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.art.medium' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      data.medium ? <FormattedMessage id={`filters.art.medium.${data.medium}`} /> : null,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: getDimensionValues(getDimension('art', 'medium').values)?.map(
      (value: string) => ({
        value,
        label: formatMessage({ id: `filters.art.medium.${value}` }),
      }),
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'subject',
    type: 'string',
    flex: 1,
    hide: true,
    headerName: formatMessage({ id: 'components.tables.columns.art.subject' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      data.subject ? <FormattedMessage id={`filters.art.subject.${data.subject}`} /> : null,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'price',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.art.price' }),
    renderCell: ({ row: data }: Record<string, any>) => data.formattedPrice,
    headerClassName: styles.columnRightPadded,
    cellClassName: styles.columnRightPadded,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'stock',
    type: 'number',
    align: 'right',
    headerAlign: 'right',
    width: 70,
    hide: true,
    headerName: formatMessage({ id: 'components.tables.columns.art.stock' }),
    renderCell: ({ row: data }: Record<string, any>) => data.stock,
    headerClassName: styles.columnRightPadded,
    cellClassName: styles.columnRightPadded,
  },
];

// Editorial Curator Thought
export const ART_EDITORIAL_THOUGHT_SCHEMA: SchemaType = [
  {
    key: 'authorName',
    render: ({ data }) => (data && data.author && data.author.fullName) || null,
  },
  {
    key: 'text',
  },
  { key: 'locales', render: renderLocalesList },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.editorial.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

// Editorial text
export const ART_EDITORIAL_TEXT_SCHEMA: SchemaType = [
  { key: 'text' },
  { key: 'locales', render: renderLocalesList },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.text.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

// Editorial note
export const ART_EDITORIAL_NOTE_SCHEMA: SchemaType = [
  { key: 'text' },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.note.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

// Editorial link
export const ART_EDITORIAL_LINK_SCHEMA: SchemaType = [
  { key: 'text' },
  { key: 'url' },
  { key: 'locales', render: renderLocalesList },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.link.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

export const ART_DATAGRID_TABLE_SCHEMA: (properties: Record<string, any>) => GridColumns<any> = ({
  formatMessage,
  filterSelectedItems = false,
  pickerProps,
}) => [
  ...ARTLIST_DATAGRID_SCHEMA_BASIC({ formatMessage }),
  dataPickerActionColumn(formatMessage, {
    filterable: filterSelectedItems,
    pickerProps: pickerProps,
  }),
];

export const ART_SORTING_TABLE_SCHEMA: SchemaType = [
  {
    key: 'image',
    render: ({ data }: Record<string, any>): JSX.Element => {
      const mainImage = extractImageFileFromData.artMaster(data.images, 'MAIN_SQUARE');

      return (
        <Link to={UrlAssembler.artDetails(data.id)} title={data.title}>
          {mainImage ? (
            <Picture
              className={styles.artImage}
              type="table.list"
              artDirectionKey="art.main"
              image={mainImage}
              alt={data.title}
            />
          ) : null}
        </Link>
      );
    },
  },
  {
    key: 'title',
    render: ({ data }: Record<string, any>) => (
      <Link to={UrlAssembler.artDetails(data.id)} title={data.title}>
        {data.title}
      </Link>
    ),
  },
  {
    key: 'artist',
    render: ({ data }: Record<string, any>) => data.artist.name,
  },
  {
    key: 'medium',
    render: ({ data: { medium, subMedium } }: Record<string, any>) => (
      <>
        {medium ? <FormattedMessage id={`filters.art.medium.${medium}`} /> : null}
        {subMedium ? (
          <>
            {medium ? <> &gt; </> : null}
            <FormattedMessage id={`filters.art.subMedium.${subMedium}`} />
          </>
        ) : null}
      </>
    ),
  },
  {
    key: 'subject',
    render: ({ data: { subject, subSubject } }: Record<string, any>) => (
      <>
        {subject ? <FormattedMessage id={`filters.art.subject.${subject}`} /> : null}
        {subSubject ? (
          <>
            {subject ? <> &gt; </> : null}
            <FormattedMessage id={`filters.art.subSubject.${subSubject}`} />
          </>
        ) : null}
      </>
    ),
  },
  {
    key: 'style',
    // @ts-ignore
    render: ({ data: { style } }: Record<string, any>) =>
      style ? <FormattedMessage id={`filters.art.style.${style}`} /> : null,
  },
  {
    key: 'status',
    render: ({ data }: Record<string, any>) => (
      <FormattedMessage id={`components.art.status.${data.status}`} />
    ),
  },
];

export const RELATED_ART_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage }) => [
  ...ARTLIST_DATAGRID_SCHEMA_BASIC({ formatMessage }),
  {
    field: 'actions',
    type: 'string',
    align: 'right',
    minWidth: 150,
    headerName: '',
    filterable: false,
    sortable: false,
    hideable: false,
    renderCell: ({ row: { customData, rowData: data } }: Record<string, any>): JSX.Element => {
      const { deleteLoading, deleteMutation, refetchListQuery } = customData;

      return (
        <SplitButton
          disabled={deleteLoading}
          mainButtonProps={{
            actionKey: 'delete',
            requireConfirmation: true,
            children: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: data.id })
                .then(() => typeof refetchListQuery === 'function' && delay(refetchListQuery))
                .catch(() => null);
            },
          }}
          translationPrefix="components.dialog.art.related"
          confirmationDialogProps={splitButtonConfirmationProps}
          options={[]}
        />
      );
    },
    headerClassName: styles.columnRightPadded,
    cellClassName: styles.columnRightPadded,
  },
];
