import {
  CmsLayout as CmsLayoutBaseModel,
  CmsLayoutPaginationResult as CmsLayoutPaginationResultBaseModel,
  CmsLayoutPlaceholder as CmsLayoutPlaceholderBaseModel,
  CmsLayoutPlaceholderPaginationResult as CmsLayoutPlaceholderPaginationResultBaseModel,
  CmsPage as CmsPageBaseModel,
  CmsPageLocale as CmsPageLocaleBaseModel,
  CmsPagePaginationResult as CmsPagePaginationResultBaseModel,
  CmsModule as CmsModuleBaseModel,
  CmsAttribute as CmsAttributeBaseModel,
  CmsModuleInstance as CmsModuleInstanceBaseModel,
  CmsModuleInstancePaginationResult as CmsModuleInstancePaginationResultBaseModel,
  CmsAttributeInstance as CmsAttributeInstanceBaseModel,
  CmsAttributeInstancePaginationResult as CmsAttributeInstancePaginationResultBaseModel,
  CmsView as CmsViewBaseModel,
  CmsViewPaginationResult as CmsViewPaginationResultBaseModel,
  CmsViewPlaceholder as CmsViewPlaceholderBaseModel,
  CmsViewPlaceholderPaginationResult as CmsViewPlaceholderPaginationResultBaseModel,
  CmsPagePlaceholder as CmsPagePlaceholderBaseModel,
  CmsPagePlaceholderPaginationResult as CmsPagePlaceholderPaginationResultBaseModel,
} from '@riseart/models';
import { Utils } from '@riseart/utils';
import { PaginationInfo } from './List';
import { ApiInvalidData } from '../errors/ApiInvalidData';
import { FormDataMixin, LocaleMixin } from './Core';

/**
 * CmsPage
 */
class CmsPage extends FormDataMixin(LocaleMixin(CmsPageBaseModel, CmsPageLocaleBaseModel)) {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @param {string} locale
   * @returns {CmsPage}
   */
  public hydrateFromApiData(data?: Record<string, any>, locale?: string): CmsPage {
    try {
      // Deconstruct parameters
      const { layout, ...restData } = data || {};

      // Page layout information
      const layoutData = Object.keys(layout || {}).reduce(
        (accumulator: Record<string, any>, key: string): Record<string, any> => {
          accumulator[`layout${Utils.capitalize(key)}`] = layout[key];
          return accumulator;
        },
        {},
      );

      // Hydrate model
      const hydratedData = super.hydrateFromApiData({ ...restData, ...layoutData });
      let localeData = {};
      if (locale) {
        // eslint-disable-next-line
        const { id, localeCode, ...restLocaleData } = hydratedData.getLocale(locale) || {};

        if (restLocaleData) {
          localeData = restLocaleData;
        }
      }

      return this.hydrate({ ...hydratedData, ...localeData });
    } catch (error) {
      throw new ApiInvalidData('Unable to load CmsPage from provided data');
    }
  }

  /**
   * hydrateFromLocaleFormData
   *
   * @param {Record<string, any>} formData
   * @param {string} localeCode
   * @returns {ArtistLocale}
   */
  public hydrateFromLocaleFormData(
    formData: Record<string, any>,
    localeCode: string,
  ): CmsPageLocale {
    const { uri, ...restFormData } = formData;
    const existingLocale = this.locales.find(
      (localeItem: Record<string, any>) => localeItem.localeCode === localeCode,
    );

    const SHOULD_GENERATE_ID = !restFormData.id;
    const NewLocale = new CmsPageLocale().hydrate(
      { localeCode, ...(uri ? { uri } : null), ...restFormData },
      SHOULD_GENERATE_ID,
    );

    this.locales = existingLocale
      ? this.locales.map((localeItem: CmsPageLocale) => {
          if (localeItem.localeCode === localeCode) {
            return NewLocale;
          }

          return localeItem;
        })
      : [...this.locales, NewLocale];

    return NewLocale;
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {ArtCollectionist}
   */
  public hydrateFromFormData(formData: Record<string, any>): File {
    return this.hydrate({ id: this.id, ...formData });
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const mappedData = super.mapFormToApiData(data, fieldsSchema, includeNullValues);
    // eslint-disable-next-line
    const { locales, ...restData } = mappedData;
    return restData;
  }
}

/**
 * CmsPageLocale
 */
class CmsPageLocale extends CmsPageLocaleBaseModel {}

/**
 * CmsPagePaginationResult
 */
class CmsPagePaginationResult extends CmsPagePaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @param {string} locale
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>, locale?: string): CmsPagePaginationResult {
    try {
      const { items, ...paginationData } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const CmsPageModel = new CmsPage();
            CmsPageModel.hydrateFromApiData(item, locale);

            return CmsPageModel;
          })
        : {};

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      return this.hydrate({ items: listData, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load CmsPagePaginationResult from provided data.');
    }
  }
}

/**
 * CmsModule
 */
class CmsModule extends CmsModuleBaseModel {}

/**
 * CmsAttribute
 */
class CmsAttribute extends CmsAttributeBaseModel {}

/**
 * CmsModuleInstance
 */
class CmsModuleInstance extends FormDataMixin(CmsModuleInstanceBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {CmsModuleInstance}
   */
  public hydrateFromApiData(apiData?: Record<string, any>): CmsModuleInstance {
    try {
      // Hydrate model
      return this.hydrate(apiData);
    } catch (error) {
      throw new ApiInvalidData('Unable to load Cms module instance from provided data');
    }
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {ArtCollectionist}
   */
  public hydrateFromFormData(formData: Record<string, any>): File {
    return this.hydrate({ id: this.id, ...formData });
  }
}

/**
 * CmsModulePaginationResult
 */
class CmsModuleInstancePaginationResult extends CmsModuleInstancePaginationResultBaseModel {}

/**
 * CmsAttributeInstance
 */
class CmsAttributeInstance extends FormDataMixin(CmsAttributeInstanceBaseModel) {
  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @param {boolean} isEditForm
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
    isEditForm = false,
  ): Record<string, any> {
    const { moduleInstanceId } = data;
    const {
      type, // eslint-disable-line
      name,
      attributeId: rootAttributeId,
      ...mappedData
    } = super.mapFormToApiData(data, fieldsSchema, includeNullValues);
    const attributeId =
      (rootAttributeId && typeof rootAttributeId === 'object' && rootAttributeId.id) ||
      rootAttributeId ||
      null;

    return {
      ...(isEditForm ? null : { name }),
      ...mappedData,
      ...(isEditForm ? null : { moduleInstanceId }),
      ...(isEditForm ? null : { attributeId }),
    };
  }
}

/**
 * CmsAttributeInstancePaginationResult
 */
class CmsAttributeInstancePaginationResult extends CmsAttributeInstancePaginationResultBaseModel {}

/**
 * CmsView
 */
class CmsView extends FormDataMixin(CmsViewBaseModel) {
  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {ArtCollectionist}
   */
  public hydrateFromFormData(formData: Record<string, any>): CmsView {
    return this.hydrate({ id: this.id, ...formData });
  }
}

/**
 * CmsModulePaginationResult
 */
class CmsViewPaginationResult extends CmsViewPaginationResultBaseModel {}

/**
 * CmsViewPlaceholder
 */
class CmsViewPlaceholder extends FormDataMixin(CmsViewPlaceholderBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {CmsViewPlaceholder}
   */
  public hydrateFromApiData(apiData: Record<string, any>): CmsViewPlaceholder {
    const { moduleInstance, ...restData } = apiData;

    this.moduleInstance = new CmsModuleInstance().hydrate(moduleInstance);

    try {
      // Hydrate model
      return this.hydrate(restData);
    } catch (error) {
      throw new ApiInvalidData('Unable to load Cms view placeholder from provided data');
    }
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {CmsViewPlaceholder}
   */
  public hydrateFromFormData(formData: Record<string, any>): CmsViewPlaceholder {
    return this.hydrate({ id: this.id, ...formData });
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   *
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const { viewId } = data;
    const { moduleInstanceId, ...mappedData } = super.mapFormToApiData(
      data,
      fieldsSchema,
      includeNullValues,
    );

    return {
      ...mappedData,
      ...(data.id ? null : { viewId }),
      moduleInstanceId:
        (moduleInstanceId && typeof moduleInstanceId === 'object' && moduleInstanceId.id) ||
        moduleInstanceId ||
        null,
    };
  }
}

/**
 * CmsViewPlaceholderPaginationResult
 */
class CmsViewPlaceholderPaginationResult extends CmsViewPlaceholderPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {CmsViewPlaceholderPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): CmsViewPlaceholderPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<CmsViewPlaceholder> = itemsData
        ? itemsData.map((item: Record<string, any>) =>
            new CmsViewPlaceholder().hydrateFromApiData(item),
          )
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({ items, pagination });
    } catch (error) {
      throw new ApiInvalidData(
        'Unable to load CmsViewPlaceholderPaginationResult from provided data',
      );
    }
  }
}

/**
 * CmsPagePlaceholder
 */
class CmsPagePlaceholder extends FormDataMixin(CmsPagePlaceholderBaseModel) {
  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {CmsPagePlaceholder}
   */
  public hydrateFromApiData(apiData: Record<string, any>): CmsPagePlaceholder {
    const { moduleInstance, placeholder, ...restData } = apiData;

    this.placeholder = new CmsLayoutPlaceholder().hydrate(placeholder);
    this.moduleInstance = new CmsModuleInstance().hydrate(moduleInstance);

    try {
      // Hydrate model
      return this.hydrate(restData);
    } catch (error) {
      throw new ApiInvalidData('Unable to load CmsPagePlaceholder from provided data');
    }
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {CmsPagePlaceholder}
   */
  public hydrateFromFormData(formData: Record<string, any>): CmsPagePlaceholder {
    return this.hydrate({ id: this.id, ...formData });
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   *
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const { viewId } = data;
    const { moduleInstanceId, placeholderId, ...mappedData } = super.mapFormToApiData(
      data,
      fieldsSchema,
      includeNullValues,
    );

    return {
      ...mappedData,
      ...(data.id ? null : { viewId }),
      placeholderId:
        (placeholderId && typeof placeholderId === 'object' && placeholderId.id) ||
        placeholderId ||
        null,
      moduleInstanceId:
        (moduleInstanceId && typeof moduleInstanceId === 'object' && moduleInstanceId.id) ||
        moduleInstanceId ||
        null,
    };
  }
}

/**
 * CmsPagePlaceholderPaginationResult
 */
class CmsPagePlaceholderPaginationResult extends CmsPagePlaceholderPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {CmsPagePlaceholderPaginationResult}
   */
  public hydrateFromApiData(data?: Record<string, any>): CmsPagePlaceholderPaginationResult {
    try {
      // Deconstruct
      const { items: itemsData, ...paginationData } = data || {};

      // Items
      const items: Array<CmsPagePlaceholder> = itemsData
        ? itemsData.map((item: Record<string, any>) =>
            new CmsPagePlaceholder().hydrateFromApiData(item),
          )
        : [];

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      // Hydrate model
      return this.hydrate({ items, pagination });
    } catch (error) {
      throw new ApiInvalidData(
        'Unable to load CmsPagePlaceholderPaginationResult from provided data',
      );
    }
  }
}

/**
 * CmsLayout
 */
class CmsLayout extends CmsLayoutBaseModel {}

/**
 * CmsLayoutPaginationResult
 */
class CmsLayoutPaginationResult extends CmsLayoutPaginationResultBaseModel {}

/**
 * CmsLayoutPlaceholder
 */
class CmsLayoutPlaceholder extends CmsLayoutPlaceholderBaseModel {}

/**
 * CmsLayoutPaginationResult
 */
class CmsLayoutPlaceholderPaginationResult extends CmsLayoutPlaceholderPaginationResultBaseModel {}

export {
  CmsPage,
  CmsPageLocale,
  CmsPagePaginationResult,
  CmsPagePlaceholder,
  CmsPagePlaceholderPaginationResult,
  CmsModule,
  CmsAttribute,
  CmsModuleInstance,
  CmsModuleInstancePaginationResult,
  CmsAttributeInstance,
  CmsAttributeInstancePaginationResult,
  CmsView,
  CmsViewPaginationResult,
  CmsViewPlaceholder,
  CmsViewPlaceholderPaginationResult,
  CmsLayout,
  CmsLayoutPaginationResult,
  CmsLayoutPlaceholder,
  CmsLayoutPlaceholderPaginationResult,
};
