import queryString from 'query-string';
import Url from 'url-parse';
import { stripTrailingSlash, find } from '@riseart/fe-utils';
import { application as APP_CONFIG } from '../../../config/config.js';
import { ROUTER_CONFIG } from '../../../config/router.js';
import { httpStatus as HTTP_STATUS_ENUM } from '../../../config/enumeration.js';
import { LocationManager } from '../Location';
import { RiseartLogger } from '../Logger';

/**
 * getLocaleConfig
 *
 * @param {string| boolean} value
 * @param {string} compareField
 * @returns {Object}
 */
export function getLocaleConfig(
  value: string | boolean,
  compareField = 'name',
): Record<string, any> {
  return find(
    APP_CONFIG.i18n.locales,
    (locale: Record<string, any>): boolean => locale[compareField] === value,
  );
}

/**
 * getPageRouteConfig
 *
 * @param {string} key
 */
export function getPageRouteConfig(
  key: string,
  predicate: ((item: Record<string, any>) => boolean) | null = null,
): Record<string, any> | undefined {
  return find(
    ROUTER_CONFIG,
    predicate || (({ key: routeKey }: { key: string }): boolean => routeKey === key),
  );
}

/**
 * assembleUrl
 *
 * @param {string} routeName
 * @param {Record<string, any>} options
 * @returns {string | null}
 */
export function assembleUrl(routeName: string, options: Record<string, any> = {}): string | null {
  const { path: routePaths } = getPageRouteConfig(routeName) || {};
  const locale = (options && options.locale) || getLocaleConfig(true, 'isDefault');

  if (!routePaths) {
    return null;
  }

  const { params } = options;
  const langRegEx = new RegExp(`^\\/:lang\\(${locale.basePath}\\)\\??`);
  let routePath = find(routePaths, (path: string) => !!path.match(langRegEx));
  routePath = routePath.replace(langRegEx, locale.isDefault ? '' : `/${locale.basePath}`);
  let pathname = routePath;

  if (params && Object.keys(params).length > 0) {
    const paramsToRegExp = Object.keys(params)
      .filter((paramKey: string) => params[paramKey] !== undefined)
      // eslint-disable-next-line
      .map((i) => `:${i}[?]?`)
      .join('|');
    const matchRegex = new RegExp(`${paramsToRegExp}`, 'gi');

    pathname = routePath.replace(/\(([^)]+)\)/g, '').replace(matchRegex, (matched: string) => {
      const replacedValue = params[matched.replace(/[:?]/gi, '')];

      return replacedValue === null ? '' : replacedValue;
    });
  }

  return `${options.origin || ''}${normalizeUri(pathname) || (!options.origin ? '/' : '')}${
    options.search || ''
  }${options.hash || ''}`;
}

/**
 * findRedirect
 *
 * @param {string} requestUri
 * @param {Object} config
 * @param {number} hops
 * @param {string} initialRequestUri (optional)
 * @returns {Record<string, any> | void} found redirect configuration for the url or undefined
 */
export const findRedirect = (
  requestUri: string,
  config: Record<string, any>,
  hops = 0,
  initialRequestUri?: string,
): Record<string, any> | void => {
  const pathname = stripTrailingSlash(Url(requestUri).pathname);
  const foundRedirect = config[pathname];

  // No redirect found
  if (!foundRedirect || !foundRedirect.targetUri) {
    return;
  }

  // Log error when maximum redirect hops is exceeded
  // @ts-ignore
  if (hops === APP_CONFIG.maxRedirectHops) {
    RiseartLogger.exception(
      new Error(
        `Reached max redirect limit from redirector configuration for requestUri: ${
          initialRequestUri || requestUri || ''
        }.`,
      ),
    );

    return foundRedirect;
  }

  // Check for nested redirects if found redirect
  const foundNestedRedirect = findRedirect(
    foundRedirect.targetUri,
    config,
    hops + 1,
    initialRequestUri || requestUri,
  );

  if (foundNestedRedirect) {
    return foundNestedRedirect;
  }

  // Return found redirect
  return foundRedirect;
};

/**
 * combineQueryStringParams
 *
 * @param {string} targetUri
 * @param {string} requestUri
 * @returns {string}
 */
export function combineQueryStringParams(targetUri: string, requestUri: string): string {
  if (!requestUri || !targetUri) {
    return targetUri;
  }

  const parsedTargetUri = Url(targetUri);
  const parsedRequestUri = Url(requestUri);
  const combinedQueryStringParams = queryString.stringify({
    ...queryString.parse(parsedRequestUri.query),
    ...queryString.parse(parsedTargetUri.query),
  });

  return `${stripTrailingSlash(parsedTargetUri.pathname)}${
    combinedQueryStringParams ? `?${combinedQueryStringParams}` : ''
  }${parsedTargetUri.hash || ''}`;
}

/**
 * getQueryStringParam
 *
 * @param {string} name
 * @returns {strinng | null}
 */
export function getQueryStringParam(name: string): string | null {
  const { search } = LocationManager.get();
  const parsedQsParams = search && queryString.parse(search);

  return (parsedQsParams && parsedQsParams[name]) || null;
}

/**
 * gql404RedirectHandler
 *
 * @param {string} redirectUrl
 * @param {Record<string, any>} history
 * @param {Record<string, any>} staticContext
 * @returns {(errors: Record<string, any>) => Record<string, any>}
 */
export function gql404RedirectHandler(
  redirectUrl: string,
  history: Record<string, any>,
  staticContext?: Record<string, any>,
): (errors: Record<string, any>) => Record<string, any> {
  return ({ graphQLErrors = [] }) => {
    const shouldRedirect = graphQLErrors.some(
      (err: Record<string, any>) =>
        err && err.errorInfo && err.errorInfo.status === HTTP_STATUS_ENUM.NOT_FOUND,
    );

    if (shouldRedirect) {
      if (staticContext) staticContext.status = HTTP_STATUS_ENUM.MOVED_PERMANENTLY;
      history.replace(redirectUrl);

      return { shouldOverwriteDefaultHandler: true };
    }

    return { shouldOverwriteDefaultHandler: false };
  };
}

/**
 * normalizeUri
 *
 * @param {string} uri
 * @returns {string}
 */
export function normalizeUri(uri = ''): string {
  if (!uri) {
    return '';
  }

  const parsedUrl = Url(uri);
  parsedUrl.set('pathname', `/${parsedUrl.pathname.replace(/^\/+|\/+$/g, '')}`);

  return `${parsedUrl.pathname}${parsedUrl.query}${parsedUrl.hash}`;
}

/**
 * getLocale
 *
 * @param {string} urlLanguage?
 * @param {string} visitorLocale?
 * @param {Record<string, any>[]} localeConfig?
 * @returns {Record<string, any>}
 */
export function getLocale(
  urlLanguage?: string | boolean,
  visitorLocale?: string,
  localeConfig?: Record<string, any>[],
): Record<string, any> {
  // Get locale by detecting url language
  const validUrlLocale = urlLanguage && getLocaleConfig(urlLanguage, 'basePath');

  if (validUrlLocale) {
    return validUrlLocale;
  }

  // Get locale from visitor
  const validUserLocale = visitorLocale && getLocaleConfig(visitorLocale, 'name');

  if (validUserLocale) {
    return validUserLocale;
  }

  // Return default locale
  return find(localeConfig, ({ isDefault }: { isDefault: boolean }): boolean => isDefault);
}

/**
 * findDynamicPathParams
 *
 * @param {string} path
 * @param {string[]} exclude?
 * @returns {string[]}
 */
export function findDynamicPathParams(path: string, exclude: string[] = []): string[] {
  if (!path) {
    return [];
  }

  const params: string[] | null = path.match(/:([a-zA-Z]*)/g);

  return params
    ? params.reduce((accumulator: string[], param: string) => {
        const dynamicParam = param.replace(':', '');
        if (exclude.indexOf(dynamicParam) > -1) {
          return accumulator;
        }

        accumulator.push(dynamicParam);

        return accumulator;
      }, [])
    : [];
}

/**
 * extractLocaleFromUrl
 *
 * @param {string} uri
 * @returns {Record<string, any> | undefined}
 */
export function extractLocaleFromUrl(uri: string): Record<string, any> | undefined {
  const path = normalizeUri(uri);

  if (!path) {
    return;
  }

  const language = path.substr(1).split('/').shift() || null;

  if (!language) {
    return;
  }

  return getLocaleConfig(language, 'basePath');
}

/*
 * normalizeLocalePath
 *
 * Checks if url includes the locale data in it,
 * and if not then try to assemble new url with found locale config
 *
 * @param {string} url
 * @param {string} locale
 * @returns {string | undefined}
 */
export function normalizeLocalePath(url: string, locale: string): string | undefined {
  const normalizedUrl = normalizeUri(url);
  const urlLang = normalizedUrl.substr(1).split('/')[0];
  const validLocale = getLocaleConfig(urlLang, 'language');
  const localeBasedOnKey = getLocaleConfig(locale, 'name');

  // url has a valid and correct locale
  if (localeBasedOnKey && validLocale && validLocale.name === localeBasedOnKey.name) {
    return validLocale.isDefault ? normalizedUrl.substr(urlLang.length + 1) : normalizedUrl;
  }

  // url has a valid locale, but it is different than the one that it should be
  if (validLocale && validLocale.name !== locale && localeBasedOnKey) {
    const urlWithoutLang = normalizedUrl.substr(urlLang.length + 1);
    return localeBasedOnKey.isDefault
      ? urlWithoutLang
      : `/${localeBasedOnKey.language}${urlWithoutLang}`;
  }

  // no valid locale,, or url does not include the locale in it
  if (!validLocale && localeBasedOnKey) {
    return localeBasedOnKey.isDefault
      ? normalizedUrl
      : `/${localeBasedOnKey.language}${normalizedUrl}`;
  }
}
