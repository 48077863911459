import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { find } from '@riseart/fe-utils';
import { Link, SideMenu, menuItemsFinder } from '@riseart/dashboard';
import { artDirection as ART_DIRECTION_CONFIG } from '../../config/config.js';
import { ROUTER_CONFIG } from '../../config/router.js';
import { selectUser } from '../../services/redux/selectors/user';
import { selectAuth } from '../../services/redux/selectors/auth';
import { concatValues, getStringInitial } from '../../services/riseart/utils/Utils';
import { UrlAssembler } from '../../services/riseart/utils/UrlAssembler';
import { generatePicturePropsForCDN } from './artdirection/Picture';
import { ART_DIRECTION_SIZES } from '../../services/riseart/artdirection/sizes';
import { isRoleAllowedForACL } from '../../services/riseart/utils/Auth';

/**
 * UserAvatar
 * @returns
 */
export function UserAvatar(): JSX.Element {
  const { formatMessage } = useIntl();
  const user = useSelector(selectUser);
  const auth = useSelector(selectAuth);
  const userRole = get(auth, 'payload.acl_role');
  const MENU_ICONS: Record<string, any> = {
    changePassword: KeyIcon,
    signout: LogoutIcon,
  };
  const sideMenuItems = menuItemsFinder(
    ROUTER_CONFIG,
    'side',
    userRole,
    {
      isRoleAllowedForACL,
      UrlAssembler,
      formatMessage: ({ id }) => formatMessage({ id: `components.sideMenu.${id}` }),
    },
    MENU_ICONS,
  );

  if (user) {
    const avatar =
      user && user.avatars && find(user.avatars, ({ type }: { type: string }) => type === 'square');
    const avatarImgProps = avatar
      ? generatePicturePropsForCDN({
          image: avatar,
          sizesList: ART_DIRECTION_SIZES.avatar.medium,
          artDirectionConfig: ART_DIRECTION_CONFIG.common.avatar,
        })
      : null;
    const fullName = concatValues([user.firstName, user.lastName]);

    return (
      <SideMenu
        title={formatMessage({ id: 'components.secondaryMenu.tooltip' })}
        avatar={
          <Avatar
            sx={{ bgcolor: '#000000' }}
            {...(avatarImgProps
              ? {
                  ...avatarImgProps,
                  srcSet:
                    avatarImgProps.srcSet &&
                    (avatarImgProps.srcSet.webp || avatarImgProps.srcSet.img),
                  alt: fullName,
                }
              : null)}
          >
            {!avatarImgProps && user.firstName ? getStringInitial(user.firstName) : null}
            {!avatarImgProps && user.lastName ? getStringInitial(user.lastName) : null}
          </Avatar>
        }
      >
        {sideMenuItems.map(({ key, icon: IconComponent, text, ...restLinkProps }) => {
          return (
            <ListItem disablePadding key={key}>
              <ListItemButton component={Link} {...restLinkProps} title={text}>
                <ListItemIcon>
                  <IconComponent />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </SideMenu>
    );
  }

  return (
    <IconButton
      color="inherit"
      component={Link}
      to={UrlAssembler.byRouteKey('login')}
      title="Login"
    >
      <PersonIcon />
    </IconButton>
  );
}
