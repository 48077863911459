// @ts-ignore
import UAParser from 'ua-parser-js';

const { VENDOR, MODEL } = UAParser.DEVICE;
const TYPE_BOT = ['type', 'bot'];
export const BOTS_REG_EXTENSION = [
  // google, bing, msn
  [/((?:google|bing|msn)bot(?:-[imagevdo]{5})?)\/([\w.]+)/i],
  [VENDOR, MODEL, TYPE_BOT],
  // bing preview, crawler, spider, robot, crawling
  [/(bingpreview|crawler|spider|robot|crawling)\/([\w.]+)/i],
  [VENDOR, MODEL, TYPE_BOT],
];

/**
 * NavigatorService
 */
export const NavigatorService: Record<string, any> = {
  properties: {},

  /**
   * config
   * @param {Record<string, any>} initialData
   * @returns {void}
   */
  config(initialData: Record<string, any> = {}): void {
    NavigatorService.properties = {
      ...initialData,
      parsedUserAgent: UAParser(initialData.userAgent),
    };
  },

  /**
   * set
   * @param {*} property
   * @param {*} value
   * @returns {void}
   */
  set(property: string, value: any): void {
    NavigatorService.properties[property] = value;
  },

  /**
   * get
   * @param {string} property
   * @returns {any} The value of the property
   */
  get(property: string): any {
    return NavigatorService.properties[property];
  },

  /**
   * getParsedUserAgent
   * @param {string} uaString
   * @param {Object} extensions
   */
  getParsedUserAgent(
    uaString: string = NavigatorService.get('userAgent'),
    extensions: Record<string, any> = { device: BOTS_REG_EXTENSION },
  ) {
    return new UAParser(uaString, extensions);
  },

  /**
   * isBot
   * @param {string} uaString
   * @param {Record<string, any>[]} deviceRulesExtensions
   *
   * @returns {boolean} true if is bot
   */
  isBot(
    uaString: string = NavigatorService.get('userAgent'),
    deviceRulesExtensions: Record<string, any>[] = BOTS_REG_EXTENSION,
  ): boolean {
    const uaParser = NavigatorService.getParsedUserAgent(uaString, {
      device: deviceRulesExtensions,
    });

    return uaParser.getDevice().type === 'bot';
  },
};
