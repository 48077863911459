import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGet } from '@riseart/api-client-react';
import { CircularLoader } from '@riseart/dashboard';
import { api as API_CONFIG, cookies as COOKIES_CONFIG } from '../../config/config.js';
import { getInitialAuthActions, getCookieValue } from '../../services/riseart/utils/Auth';
import { selectAuth, AuthSelectorType } from '../../services/redux/selectors/auth';
import { selectUser } from '../../services/redux/selectors/user';
import { meFetched } from '../../services/redux/actions/me/me';
import { ErrorPage } from '../../components/pages/error/Error';
import { ErrorService } from '../../services/riseart/errors/ErrorService';

/**
 * TokenProvider
 *
 * @param {props} props
 * @returns
 */
export const TokenProvider = ({
  children,
}: {
  children: (data: AuthSelectorType) => any;
}): JSX.Element => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const user = useSelector(selectUser);
  const [loadedInitialActions, setLoadedInitialActions] = useState<
    Record<string, any> | { type: string; payload: any } | null | undefined
  >(undefined);
  const {
    loading,
    error,
    data: meData,
  } = useGet({
    skip:
      loadedInitialActions === undefined ||
      !auth.token ||
      !!(
        user &&
        auth &&
        auth.payload &&
        parseInt(auth.payload.user_id, 10) === parseInt(user.id, 10)
      ),
    endpoint: API_CONFIG.endpoints.me,
  });
  const tokenFromCookie = getCookieValue(COOKIES_CONFIG.token.name);

  useEffect(() => {
    async function determineActions() {
      const initialAuthActions = await getInitialAuthActions(
        tokenFromCookie,
        auth && auth.token,
        dispatch,
      );

      setLoadedInitialActions(initialAuthActions);
    }

    determineActions();
  }, [tokenFromCookie, auth.token]);

  if (loadedInitialActions !== undefined) {
    if (loadedInitialActions === null || user) {
      return children(auth);
    }

    if (loading) {
      return <CircularLoader active fullHeight />;
    } else if (meData && !user) {
      dispatch(meFetched(meData));
      return children(auth);
    } else if (error) {
      return <ErrorPage error={ErrorService.mapJSError(error)} translate={false} showLogo />;
    }
  }

  return <CircularLoader active fullHeight />;
};
