import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { FormBuilder } from '@riseart/dashboard';
import { guiUpdateProperties } from '../../../services/redux/actions/application/gui';
import { selectStore, selectLocale } from '../../../services/redux/selectors/gui';
import { getLocaleConfig } from '../../../services/riseart/utils/Route';
import { SettingsFormModel } from '../../forms/models/console';

/**
 * HeaderSettings
 *
 * @returns {JSX.Element}
 */
export function HeaderSettings(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const store = useSelector(selectStore);
  const locale = useSelector(selectLocale);

  /**
   * handleChange
   *
   * @param {Record<string, any>} formState
   */
  function handleChange({ data }: Record<string, any>) {
    dispatch(guiUpdateProperties(data));
    handleClose();

    if (locale !== data.locale) {
      // Hard reload the page when locale changes
      window.location.reload();
    }
  }

  /**
   * handleClick
   *
   * @param {React.MouseEvent<HTMLButtonElement>} event
   */
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  /**
   * handleClose
   */
  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{ color: '#fff', whiteSpace: 'nowrap', fontSize: 'medium' }}
      >
        {store} / {locale && getLocaleConfig(locale).language.toUpperCase()}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ p: 4 }}>
          <FormBuilder
            {...SettingsFormModel}
            initialValues={{ store, locale }}
            submitText={<FormattedMessage id="common.save" />}
            onSubmit={handleChange}
          />
        </Box>
      </Popover>
    </>
  );
}
