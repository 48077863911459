import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { GridColumns } from '@mui/x-data-grid';
import { DataPicker, SplitSelectButton, Search } from '@riseart/dashboard';
import { DataList } from '../../data/tables/DataList';

type Props = {
  initialValue?: Record<string, any>[] | Record<string, any> | null;
  selectLabel: string;
  multiple?: boolean;
  headerActions?: ((properties: Record<string, any>) => any) | null;
  clearOnSave?: boolean;
  onChange?: (data: Record<string, any> | null) => any;
  onSave?: (data: Record<string, any>) => any;
  children?: (data: Record<string, any>) => any;
  chipColor?:
    | 'primary'
    | 'default'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  chipValueField?: string;
  chipValueRender?: (
    selectedItems: Record<string, any>[] | null,
    selectLabel: string,
    chipValueField?: string,
  ) => string | JSX.Element | null;
  gridProps:
    | {
        endpoint: string;
        endpointParams?: Record<string, any> | null;
        itemMapper?: (item: Record<string, any>) => Record<string, any>;
        filterConfig: Record<string, any>[];
        sortConfig: Record<string, any>[];
        schema: GridColumns<any>;
        customData?: Record<string, any> | null;
        initialState?: Record<string, any>;
        inputParams?: Record<string, any>;
        onChangeParams?: ((params: any) => any) | undefined;
      }
    | ((pickerProps: Record<string, any>) => any);
  showSearch?: boolean;
  select?: Record<string, any>;
  initialState?: Record<string, any>;
};

/**
 * defaultChipLabelRenderer
 *
 * @param {Record<string, any>[] | null} selectedItems
 * @param {string} selectLabel
 * @returns {string | JSX.Element | null}
 */
function defaultChipLabelRenderer(
  selectedItems: Record<string, any>[] | null,
  selectLabel: string,
  chipValueField = 'title',
): string | JSX.Element | null {
  return selectedItems && selectedItems.length
    ? selectedItems.map((item: Record<string, any>) => item[chipValueField]).join(', ')
    : selectLabel;
}

/**
 * DataPickerGrid
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function DataPickerGrid({
  showSearch,
  initialValue = null,
  selectLabel,
  onChange,
  multiple = false,
  onSave,
  children,
  chipColor,
  chipValueField,
  chipValueRender = defaultChipLabelRenderer,
  gridProps: inputGridProps,
  clearOnSave = false,
  select,
  initialState = {},
}: Props): JSX.Element {
  const [gridParams, setGridParams] = useState<Record<string, any>>(initialState || {});

  return (
    <DataPicker
      initialValue={initialValue}
      label={selectLabel}
      title={selectLabel}
      multiple={multiple}
      onSave={onSave}
      onChange={onChange}
      clearOnSave={clearOnSave}
      action={({
        handleOpen,
        handleDelete,
        selectedItems,
        setSelectedItems,
      }: Record<string, any>) =>
        typeof children === 'function' ? (
          children({ handleOpen, onChange, selectedItems, setSelectedItems })
        ) : (
          <Chip
            color={chipColor || 'primary'}
            label={chipValueRender(selectedItems, selectLabel, chipValueField)}
            onClick={handleOpen}
            onDelete={selectedItems && selectedItems.length ? handleDelete : null}
          />
        )
      }
      {...(showSearch || select
        ? {
            headerActions: () => {
              return (
                <Grid container spacing={1} justifyContent="flex-end">
                  {showSearch ? (
                    <Grid item flexGrow={1} textAlign="right" marginLeft={2}>
                      <Search
                        onSubmit={(newTextQuery: string | null) => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          const { textQuery, ...restGridParams } = gridParams;

                          setGridParams({
                            ...restGridParams,
                            ...(newTextQuery ? { textQuery: newTextQuery } : null),
                          });
                        }}
                      />
                    </Grid>
                  ) : null}
                  {select ? (
                    <Grid item>
                      <SplitSelectButton
                        controlled
                        value={
                          (gridParams &&
                            gridParams.filters &&
                            gridParams.filters.columnField === select.name &&
                            gridParams.filters.value) ||
                          select.defaultValue
                        }
                        size="large"
                        variant="outlined"
                        initialValue={select.initialValue}
                        optionsList={select.items}
                        optionsTranslationPrefix="components.art.state"
                        onChange={(state: string | number) => {
                          setGridParams({
                            ...gridParams,
                            filters: {
                              columnField: 'state',
                              operatorValue: 'is',
                              value: `${state}`,
                            },
                          });
                        }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              );
            },
          }
        : null)}
    >
      {(pickerProps: Record<string, any>) => {
        const { customData = null, ...gridProps } =
          typeof inputGridProps === 'function' ? inputGridProps(pickerProps) : inputGridProps || {};

        return (
          <DataList
            {...gridProps}
            customData={{ ...customData, picker: pickerProps }}
            onSelect={multiple ? null : pickerProps.handleSelect}
            {...(gridProps.controlled
              ? {
                  initialState: gridParams,
                  inputParams: gridParams,
                  onChangeParams: (params) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { page, itemsPerPage, ...restGridParams } = gridParams;

                    setGridParams({ ...restGridParams, ...params });
                  },
                }
              : {})}
          />
        );
      }}
    </DataPicker>
  );
}
