export const ROUTER_CONFIG = [
  {
    key: 'home',
    path: ['/:lang(en)?', '/:lang(de)', '/:lang(fr)'],
    component: 'Home',
    menu: { type: 'main', order: 1, label: 'home' },
    meta: {
      metaTitle: 'meta.home.title',
      metaKeywords: 'meta.home.keywords',
      metaDescription: 'meta.home.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'login',
    path: ['/:lang(en)?/login', '/:lang(de)/login', '/:lang(fr)/login'],
    component: 'Login',
    meta: {
      metaTitle: 'meta.login.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'signout',
    path: ['/:lang(en)?/signout', '/:lang(de)/signout', '/:lang(fr)/signout'],
    component: 'Signout',
    menu: [
      { type: 'secondary', order: 1, label: 'signout' },
      { type: 'side', order: 2, label: 'signout' },
    ],
    meta: {
      metaTitle: 'meta.signout.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['*'] },
  },
  {
    key: 'changePassword',
    path: ['/:lang(en)?/me/password', '/:lang(de)/me/password', '/:lang(fr)/me/password'],
    component: 'ChangePassword',
    menu: { type: 'side', order: 1, label: 'changePassword' },
    meta: {
      metaTitle: 'meta.changePassword.title',
      metaKeywords: 'meta.changePassword.keywords',
      metaDescription: 'meta.changePassword.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'artAdd',
    path: ['/:lang(en)?/art/add', '/:lang(de)/art/add', '/:lang(fr)/art/add'],
    component: 'ArtAdd',
    meta: {
      metaTitle: 'meta.art.title',
      metaKeywords: 'meta.art.keywords',
      metaDescription: 'meta.art.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'artEdit',
    path: [
      '/:lang(en)?/art/:id(\\d+)/edit',
      '/:lang(de)/art/:id(\\d+)/edit',
      '/:lang(fr)/art/:id(\\d+)/edit',
    ],
    component: 'ArtEdit',
    meta: {
      metaTitle: 'meta.artDetails.title',
      metaKeywords: 'meta.artDetails.keywords',
      metaDescription: 'meta.artDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'artDetailsTabs',
    path: [
      '/:lang(en)?/art/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/art/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/art/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'ArtDetails',
    meta: {
      metaTitle: 'meta.artDetails.title',
      metaKeywords: 'meta.artDetails.keywords',
      metaDescription: 'meta.artDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'artDetails',
    path: ['/:lang(en)?/art/:id(\\d+)', '/:lang(de)/art/:id(\\d+)', '/:lang(fr)/art/:id(\\d+)'],
    component: 'ArtDetails',
    meta: {
      metaTitle: 'meta.artDetails.title',
      metaKeywords: 'meta.artDetails.keywords',
      metaDescription: 'meta.artDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'artList',
    path: ['/:lang(en)?/art', '/:lang(de)/art', '/:lang(fr)/art'],
    component: 'ArtList',
    menu: { type: 'main', order: 2, label: 'art' },
    meta: {
      metaTitle: 'meta.art.title',
      metaKeywords: 'meta.art.keywords',
      metaDescription: 'meta.art.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'artistAdd',
    path: ['/:lang(en)?/artist/add', '/:lang(de)/artist/add', '/:lang(fr)/artist/add'],
    component: 'ArtistAdd',
    meta: {
      metaTitle: 'meta.artist.title',
      metaKeywords: 'meta.artist.keywords',
      metaDescription: 'meta.artist.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'artistEdit',
    path: [
      '/:lang(en)?/artist/:id(\\d+)/edit',
      '/:lang(de)/artist/:id(\\d+)/edit',
      '/:lang(fr)/artist/:id(\\d+)/edit',
    ],
    component: 'ArtistEdit',
    meta: {
      metaTitle: 'meta.artistDetails.title',
      metaKeywords: 'meta.artistDetails.keywords',
      metaDescription: 'meta.artistDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'artistDetailsTabs',
    path: [
      '/:lang(en)?/artist/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/artist/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/artist/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'ArtistDetails',
    meta: {
      metaTitle: 'meta.artistDetails.title',
      metaKeywords: 'meta.artistDetails.keywords',
      metaDescription: 'meta.artistDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'artistDetails',
    path: [
      '/:lang(en)?/artist/:id(\\d+)',
      '/:lang(de)/artist/:id(\\d+)',
      '/:lang(fr)/artist/:id(\\d+)',
    ],
    component: 'ArtistDetails',
    meta: {
      metaTitle: 'meta.artistDetails.title',
      metaKeywords: 'meta.artistDetails.keywords',
      metaDescription: 'meta.artistDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'artistList',
    path: ['/:lang(en)?/artists', '/:lang(de)/artists', '/:lang(fr)/artists'],
    component: 'ArtistList',
    menu: { type: 'main', order: 5, label: 'artists' },
    meta: {
      metaTitle: 'meta.artist.title',
      metaKeywords: 'meta.artist.keywords',
      metaDescription: 'meta.artist.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'sellerAdd',
    path: ['/:lang(en)?/seller/add', '/:lang(de)/seller/add', '/:lang(fr)/seller/add'],
    component: 'SellerAdd',
    meta: {
      metaTitle: 'meta.seller.title',
      metaKeywords: 'meta.seller.keywords',
      metaDescription: 'meta.seller.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'sellerEdit',
    path: [
      '/:lang(en)?/seller/:id(\\d+)/edit',
      '/:lang(de)/seller/:id(\\d+)/edit',
      '/:lang(fr)/seller/:id(\\d+)/edit',
    ],
    component: 'SellerEdit',
    meta: {
      metaTitle: 'meta.sellerDetails.title',
      metaKeywords: 'meta.sellerDetails.keywords',
      metaDescription: 'meta.sellerDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'sellerDetails',
    path: [
      '/:lang(en)?/seller/:id(\\d+)',
      '/:lang(de)/seller/:id(\\d+)',
      '/:lang(fr)/seller/:id(\\d+)',
    ],
    component: 'SellerDetails',
    meta: {
      metaTitle: 'meta.sellerDetails.title',
      metaKeywords: 'meta.sellerDetails.keywords',
      metaDescription: 'meta.sellerDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'sellerList',
    path: ['/:lang(en)?/sellers', '/:lang(de)/sellers', '/:lang(fr)/sellers'],
    component: 'SellerList',
    menu: { type: 'main', order: 7, label: 'sellers' },
    meta: {
      metaTitle: 'meta.seller.title',
      metaKeywords: 'meta.seller.keywords',
      metaDescription: 'meta.seller.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'sellerDetailsTabs',
    path: [
      '/:lang(en)?/seller/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/seller/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/seller/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'SellerDetails',
    meta: {
      metaTitle: 'meta.sellerDetails.title',
      metaKeywords: 'meta.sellerDetails.keywords',
      metaDescription: 'meta.sellerDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'sellerShippingRates',
    path: [
      '/:lang(en)?/seller/:id(\\d+)/shipping/:tableId(\\d+)/rates',
      '/:lang(de)/seller/:id(\\d+)/shipping/:tableId(\\d+)/rates',
      '/:lang(fr)/seller/:id(\\d+)/shipping/:tableId(\\d+)/rates',
    ],
    component: 'SellerShippingRates',
    meta: {
      metaTitle: 'meta.sellerShipping.title',
      metaKeywords: 'meta.sellerShipping.keywords',
      metaDescription: 'meta.sellerShipping.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'affiliateDetails',
    path: [
      '/:lang(en)?/affiliate/:id(\\d+)',
      '/:lang(de)/affiliate/:id(\\d+)',
      '/:lang(fr)/affiliate/:id(\\d+)',
    ],
    component: 'AffiliateDetails',
    meta: {
      metaTitle: 'meta.affiliateDetails.title',
      metaKeywords: 'meta.affiliateDetails.keywords',
      metaDescription: 'meta.affiliateDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'affiliateList',
    path: ['/:lang(en)?/affiliates', '/:lang(de)/affiliates', '/:lang(fr)/affiliates'],
    component: 'AffiliateList',
    menu: { type: 'main', order: 8, label: 'affiliate' },
    meta: {
      metaTitle: 'meta.affiliate.title',
      metaKeywords: 'meta.affiliate.keywords',
      metaDescription: 'meta.affiliate.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'affiliateDetailsTabs',
    path: [
      '/:lang(en)?/affiliate/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/affiliate/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/affiliate/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'AffiliateDetails',
    meta: {
      metaTitle: 'meta.AffiliateDetails.title',
      metaKeywords: 'meta.AffiliateDetails.keywords',
      metaDescription: 'meta.AffiliateDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'collectionAdd',
    path: ['/:lang(en)?/collection/add', '/:lang(de)/collection/add', '/:lang(fr)/collection/add'],
    component: 'CollectionAdd',
    meta: {
      metaTitle: 'meta.collection.title',
      metaKeywords: 'meta.collection.keywords',
      metaDescription: 'meta.collection.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'collectionEdit',
    path: [
      '/:lang(en)?/collection/:id(\\d+)/edit',
      '/:lang(de)/collection/:id(\\d+)/edit',
      '/:lang(fr)/collection/:id(\\d+)/edit',
    ],
    component: 'CollectionEdit',
    meta: {
      metaTitle: 'meta.collectionDetails.title',
      metaKeywords: 'meta.collectionDetails.keywords',
      metaDescription: 'meta.collectionDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'collectionDetails',
    path: [
      '/:lang(en)?/collection/:id(\\d+)',
      '/:lang(de)/collection/:id(\\d+)',
      '/:lang(fr)/collection/:id(\\d+)',
    ],
    component: 'CollectionDetails',
    meta: {
      metaTitle: 'meta.collectionDetails.title',
      metaKeywords: 'meta.collectionDetails.keywords',
      metaDescription: 'meta.collectionDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'collectionList',
    path: ['/:lang(en)?/collections', '/:lang(de)/collections', '/:lang(fr)/collections'],
    component: 'CollectionList',
    menu: { type: 'main', order: 3, label: 'collections' },
    meta: {
      metaTitle: 'meta.collection.title',
      metaKeywords: 'meta.collection.keywords',
      metaDescription: 'meta.collection.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'collectionDetailsTabs',
    path: [
      '/:lang(en)?/collection/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/collection/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/collection/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'CollectionDetails',
    meta: {
      metaTitle: 'meta.collectionDetails.title',
      metaKeywords: 'meta.collectionDetails.keywords',
      metaDescription: 'meta.collectionDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleAdd',
    path: ['/:lang(en)?/article/add', '/:lang(de)/article/add', '/:lang(fr)/article/add'],
    component: 'ArticleAdd',
    meta: {
      metaTitle: 'meta.article.title',
      metaKeywords: 'meta.article.keywords',
      metaDescription: 'meta.article.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleEditBody',
    path: [
      '/:lang(en)?/article/:id(\\d+)/body/:locale/edit',
      '/:lang(de)/article/:id(\\d+)/body/:locale/edit',
      '/:lang(fr)/article/:id(\\d+)/body/:locale/edit',
    ],
    component: 'ArticleEditBody',
    meta: {
      metaTitle: 'meta.articleDetails.title',
      metaKeywords: 'meta.articleDetails.keywords',
      metaDescription: 'meta.articleDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleEdit',
    path: [
      '/:lang(en)?/article/:id(\\d+)/edit',
      '/:lang(de)/article/:id(\\d+)/edit',
      '/:lang(fr)/article/:id(\\d+)/edit',
    ],
    component: 'ArticleEdit',
    meta: {
      metaTitle: 'meta.articleDetails.title',
      metaKeywords: 'meta.articleDetails.keywords',
      metaDescription: 'meta.articleDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleDetails',
    path: [
      '/:lang(en)?/article/:id(\\d+)',
      '/:lang(de)/article/:id(\\d+)',
      '/:lang(fr)/article/:id(\\d+)',
    ],
    component: 'ArticleDetails',
    meta: {
      metaTitle: 'meta.articleDetails.title',
      metaKeywords: 'meta.articleDetails.keywords',
      metaDescription: 'meta.articleDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleList',
    path: ['/:lang(en)?/articles', '/:lang(de)/articles', '/:lang(fr)/articles'],
    component: 'ArticleList',
    menu: { type: 'main', order: 11, label: 'articles' },
    meta: {
      metaTitle: 'meta.articles.title',
      metaKeywords: 'meta.articles.keywords',
      metaDescription: 'meta.articles.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleDetailsTabs',
    path: [
      '/:lang(en)?/article/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/article/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/article/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'ArticleDetails',
    meta: {
      metaTitle: 'meta.articleDetails.title',
      metaKeywords: 'meta.articleDetails.keywords',
      metaDescription: 'meta.articleDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'userEdit',
    path: [
      '/:lang(en)?/user/:id(\\d+)/edit',
      '/:lang(de)/user/:id(\\d+)/edit',
      '/:lang(fr)/user/:id(\\d+)/edit',
    ],
    component: 'UserEdit',
    meta: {
      metaTitle: 'meta.userDetails.title',
      metaKeywords: 'meta.userDetails.keywords',
      metaDescription: 'meta.userDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'userList',
    path: ['/:lang(en)?/users', '/:lang(de)/users', '/:lang(fr)/users'],
    component: 'UserList',
    menu: { type: 'main', order: 4, label: 'users' },
    meta: {
      metaTitle: 'meta.users.title',
      metaKeywords: 'meta.users.keywords',
      metaDescription: 'meta.users.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'userDetailsTabs',
    path: [
      '/:lang(en)?/user/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/user/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/user/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'UserDetails',
    meta: {
      metaTitle: 'meta.userDetails.title',
      metaKeywords: 'meta.userDetails.keywords',
      metaDescription: 'meta.userDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'eventAdd',
    path: ['/:lang(en)?/event/add', '/:lang(de)/event/add', '/:lang(fr)/event/add'],
    component: 'EventAdd',
    meta: {
      metaTitle: 'meta.event.title',
      metaKeywords: 'meta.event.keywords',
      metaDescription: 'meta.event.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'eventEdit',
    path: [
      '/:lang(en)?/event/:id(\\d+)/edit',
      '/:lang(de)/event/:id(\\d+)/edit',
      '/:lang(fr)/event/:id(\\d+)/edit',
    ],
    component: 'EventEdit',
    meta: {
      metaTitle: 'meta.eventDetails.title',
      metaKeywords: 'meta.eventDetails.keywords',
      metaDescription: 'meta.eventDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'eventDetails',
    path: [
      '/:lang(en)?/event/:id(\\d+)',
      '/:lang(de)/event/:id(\\d+)',
      '/:lang(fr)/event/:id(\\d+)',
    ],
    component: 'EventDetails',
    meta: {
      metaTitle: 'meta.eventDetails.title',
      metaKeywords: 'meta.eventDetails.keywords',
      metaDescription: 'meta.eventDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'eventList',
    path: ['/:lang(en)?/events', '/:lang(de)/events', '/:lang(fr)/events'],
    component: 'EventList',
    menu: { type: 'main', order: 12, label: 'events' },
    meta: {
      metaTitle: 'meta.events.title',
      metaKeywords: 'meta.events.keywords',
      metaDescription: 'meta.events.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'eventDetailsTabs',
    path: [
      '/:lang(en)?/event/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/event/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/event/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'EventDetails',
    meta: {
      metaTitle: 'meta.eventDetails.title',
      metaKeywords: 'meta.eventDetails.keywords',
      metaDescription: 'meta.eventDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'fileAdd',
    path: ['/:lang(en)?/file/add', '/:lang(de)/file/add', '/:lang(fr)/file/add'],
    component: 'FileAdd',
    meta: {
      metaTitle: 'meta.file.title',
      metaKeywords: 'meta.file.keywords',
      metaDescription: 'meta.file.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'fileEdit',
    path: [
      '/:lang(en)?/file/:id(\\d+)/edit',
      '/:lang(de)/file/:id(\\d+)/edit',
      '/:lang(fr)/file/:id(\\d+)/edit',
    ],
    component: 'FileEdit',
    meta: {
      metaTitle: 'meta.file.title',
      metaKeywords: 'meta.file.keywords',
      metaDescription: 'meta.file.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'fileDetails',
    path: ['/:lang(en)?/file/:id(\\d+)', '/:lang(de)/file/:id(\\d+)', '/:lang(fr)/file/:id(\\d+)'],
    component: 'FileDetails',
    meta: {
      metaTitle: 'meta.fileDetails.title',
      metaKeywords: 'meta.fileDetails.keywords',
      metaDescription: 'meta.fileDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'fileList',
    path: ['/:lang(en)?/files', '/:lang(de)/files', '/:lang(fr)/files'],
    component: 'FileList',
    menu: { type: 'main', order: 14, label: 'files' },
    meta: {
      metaTitle: 'meta.files.title',
      metaKeywords: 'meta.files.keywords',
      metaDescription: 'meta.files.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsModuleInstanceAdd',
    path: ['/:lang(en)?/cms/module/add', '/:lang(de)/cms/module/add', '/:lang(fr)/cms/module/add'],
    component: 'CmsModuleInstanceAdd',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsModuleInstanceEdit',
    path: [
      '/:lang(en)?/cms/module/:id(\\d+)/edit',
      '/:lang(de)/cms/module/:id(\\d+)/edit',
      '/:lang(fr)/cms/module/:id(\\d+)/edit',
    ],
    component: 'CmsModuleInstanceEdit',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsModuleInstanceDetails',
    path: [
      '/:lang(en)?/cms/module/:id(\\d+)',
      '/:lang(de)/cms/module/:id(\\d+)',
      '/:lang(fr)/cms/module/:id(\\d+)',
    ],
    component: 'CmsModuleInstanceDetails',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsModuleInstanceDetailsTabs',
    path: [
      '/:lang(en)?/cms/module/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/cms/module/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/cms/module/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'CmsModuleInstanceDetails',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsModuleInstanceList',
    path: ['/:lang(en)?/cms/modules', '/:lang(de)/cms/modules', '/:lang(fr)/cms/modules'],
    component: 'CmsModuleInstanceList',
    menu: { type: 'cms', order: 3, label: 'modules' },
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsViewAdd',
    path: ['/:lang(en)?/cms/view/add', '/:lang(de)/cms/view/add', '/:lang(fr)/cms/view/add'],
    component: 'CmsViewAdd',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsViewEdit',
    path: [
      '/:lang(en)?/cms/view/:id(\\d+)/edit',
      '/:lang(de)/cms/view/:id(\\d+)/edit',
      '/:lang(fr)/cms/view/:id(\\d+)/edit',
    ],
    component: 'CmsViewEdit',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsViewDetails',
    path: [
      '/:lang(en)?/cms/view/:id(\\d+)',
      '/:lang(de)/cms/view/:id(\\d+)',
      '/:lang(fr)/cms/view/:id(\\d+)',
    ],
    component: 'CmsViewDetails',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsViewDetailsTabs',
    path: [
      '/:lang(en)?/cms/view/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/cms/view/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/cms/view/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'CmsViewDetails',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsViewList',
    path: ['/:lang(en)?/cms/views', '/:lang(de)/cms/views', '/:lang(fr)/cms/views'],
    component: 'CmsViewList',
    menu: [{ type: 'cms', order: 2, label: 'views' }],
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsPageAdd',
    path: ['/:lang(en)?/cms/page/add', '/:lang(de)/cms/page/add', '/:lang(fr)/cms/page/add'],
    component: 'CmsPageAdd',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsPageEdit',
    path: [
      '/:lang(en)?/cms/page/:id(\\d+)/edit',
      '/:lang(de)/cms/page/:id(\\d+)/edit',
      '/:lang(fr)/cms/page/:id(\\d+)/edit',
    ],
    component: 'CmsPageEdit',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsPageDetails',
    path: [
      '/:lang(en)?/cms/page/:id(\\d+)',
      '/:lang(de)/cms/page/:id(\\d+)',
      '/:lang(fr)/cms/page/:id(\\d+)',
    ],
    component: 'CmsPageDetails',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsPageDetailsTabs',
    path: [
      '/:lang(en)?/cms/page/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/cms/page/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/cms/page/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'CmsPageDetails',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cmsPageList',
    path: ['/:lang(en)?/cms/pages', '/:lang(de)/cms/pages', '/:lang(fr)/cms/pages'],
    component: 'CmsPageList',
    meta: {
      metaTitle: 'meta.cms.title',
      metaKeywords: 'meta.cms.keywords',
      metaDescription: 'meta.cms.description',
    },
    menu: [
      { type: 'main', order: 13, label: 'cms' },
      { type: 'cms', order: 1, label: 'pages' },
    ],
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'authorAdd',
    path: ['/:lang(en)?/author/add', '/:lang(de)/author/add', '/:lang(fr)/author/add'],
    component: 'AuthorAdd',
    meta: {
      metaTitle: 'meta.author.title',
      metaKeywords: 'meta.author.keywords',
      metaDescription: 'meta.author.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'authorEdit',
    path: [
      '/:lang(en)?/author/:id(\\d+)/edit',
      '/:lang(de)/author/:id(\\d+)/edit',
      '/:lang(fr)/author/:id(\\d+)/edit',
    ],
    component: 'AuthorEdit',
    meta: {
      metaTitle: 'meta.author.title',
      metaKeywords: 'meta.author.keywords',
      metaDescription: 'meta.author.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'authorDetails',
    path: [
      '/:lang(en)?/author/:id(\\d+)',
      '/:lang(de)/author/:id(\\d+)',
      '/:lang(fr)/author/:id(\\d+)',
    ],
    component: 'AuthorDetails',
    meta: {
      metaTitle: 'meta.authorDetails.title',
      metaKeywords: 'meta.authorDetails.keywords',
      metaDescription: 'meta.authorDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'authorDetailsTabs',
    path: [
      '/:lang(en)?/author/:id(\\d+)/:tab',
      '/:lang(de)/author/:id(\\d+)/:tab',
      '/:lang(fr)/author/:id(\\d+)/:tab',
    ],
    component: 'AuthorDetails',
    meta: {
      metaTitle: 'meta.authorDetails.title',
      metaKeywords: 'meta.authorDetails.keywords',
      metaDescription: 'meta.authorDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'authorList',
    path: ['/:lang(en)?/authors', '/:lang(de)/authors', '/:lang(fr)/authors'],
    component: 'AuthorList',
    menu: { type: 'main', order: 10, label: 'authors' },
    meta: {
      metaTitle: 'meta.authors.title',
      metaKeywords: 'meta.authors.keywords',
      metaDescription: 'meta.authors.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'cache',
    path: ['/:lang(en)?/system/cache', '/:lang(de)/system/cache', '/:lang(fr)/system/cache'],
    component: 'CacheList',
    menu: [
      { type: 'main', order: 15, label: 'system' },
      { type: 'system', order: 1, label: 'cache' },
    ],
    meta: {
      metaTitle: 'meta.cache.title',
      metaKeywords: 'meta.cache.keywords',
      metaDescription: 'meta.cache.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'admin',
    path: ['/:lang(en)?/system/admins', '/:lang(de)/system/admins', '/:lang(fr)/system/admins'],
    component: 'AdminList',
    menu: { type: 'system', order: 4, label: 'administrators' },
    meta: {
      metaTitle: 'meta.admin.title',
      metaKeywords: 'meta.admin.keywords',
      metaDescription: 'meta.admin.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['super_admin'] },
  },
  {
    key: 'adminAdd',
    path: [
      '/:lang(en)?/system/admin/add',
      '/:lang(de)/system/admin/add',
      '/:lang(fr)/system/admin/add',
    ],
    component: 'AdminAdd',
    meta: {
      metaTitle: 'meta.admin.title',
      metaKeywords: 'meta.admin.keywords',
      metaDescription: 'meta.admin.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['super_admin'] },
  },
  {
    key: 'adminDetails',
    path: [
      '/:lang(en)?/system/admin/:id(\\d+)',
      '/:lang(de)/system/admin/:id(\\d+)',
      '/:lang(fr)/system/admin/:id(\\d+)',
    ],
    component: 'AdminDetails',
    meta: {
      metaTitle: 'meta.admin.title',
      metaKeywords: 'meta.admin.keywords',
      metaDescription: 'meta.admin.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['super_admin'] },
  },
  {
    key: 'adminEdit',
    path: [
      '/:lang(en)?/system/admin/:id(\\d+)/edit',
      '/:lang(de)/system/admin/:id(\\d+)/edit',
      '/:lang(fr)/system/admin/:id(\\d+)/edit',
    ],
    component: 'AdminEdit',
    meta: {
      metaTitle: 'meta.admin.title',
      metaKeywords: 'meta.admin.keywords',
      metaDescription: 'meta.admin.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['super_admin'] },
  },
  {
    key: 'partnerAdd',
    path: ['/:lang(en)?/partner/add', '/:lang(de)/partner/add', '/:lang(fr)/partner/add'],
    component: 'PartnerAdd',
    meta: {
      metaTitle: 'meta.partner.title',
      metaKeywords: 'meta.partner.keywords',
      metaDescription: 'meta.partner.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'partnerEdit',
    path: [
      '/:lang(en)?/partner/:id(\\d+)/edit',
      '/:lang(de)/partner/:id(\\d+)/edit',
      '/:lang(fr)/partner/:id(\\d+)/edit',
    ],
    component: 'PartnerEdit',
    meta: {
      metaTitle: 'meta.partnerDetails.title',
      metaKeywords: 'meta.partnerDetails.keywords',
      metaDescription: 'meta.partnerDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'partnerDetailsTabs',
    path: [
      '/:lang(en)?/partner/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/partner/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/partner/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'PartnerDetails',
    meta: {
      metaTitle: 'meta.partnerDetails.title',
      metaKeywords: 'meta.partnerDetails.keywords',
      metaDescription: 'meta.partnerDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'partnerDetails',
    path: [
      '/:lang(en)?/partner/:id(\\d+)',
      '/:lang(de)/partner/:id(\\d+)',
      '/:lang(fr)/partner/:id(\\d+)',
    ],
    component: 'PartnerDetails',
    meta: {
      metaTitle: 'meta.partnerDetails.title',
      metaKeywords: 'meta.partnerDetails.keywords',
      metaDescription: 'meta.partnerDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'partnerList',
    path: ['/:lang(en)?/partners', '/:lang(de)/partners', '/:lang(fr)/partners'],
    component: 'PartnerList',
    menu: { type: 'main', order: 6, label: 'partners' },
    meta: {
      metaTitle: 'meta.partners.title',
      metaKeywords: 'meta.partners.keywords',
      metaDescription: 'meta.partners.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleCategories',
    path: [
      '/:lang(en)?/system/categories',
      '/:lang(de)/system/categories',
      '/:lang(fr)/system/categories',
    ],
    component: 'ArticleCategoryList',
    menu: [{ type: 'system', order: 2, label: 'categories' }],
    meta: {
      metaTitle: 'meta.category.title',
      metaKeywords: 'meta.category.keywords',
      metaDescription: 'meta.category.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleCategoryAdd',
    path: [
      '/:lang(en)?/system/category/add',
      '/:lang(de)/system/category/add',
      '/:lang(fr)/system/category/add',
    ],
    component: 'ArticleCategoryAdd',
    meta: {
      metaTitle: 'meta.category.title',
      metaKeywords: 'meta.category.keywords',
      metaDescription: 'meta.category.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleCategoryDetails',
    path: [
      '/:lang(en)?/system/category/:id(\\d+)',
      '/:lang(de)/system/category/:id(\\d+)',
      '/:lang(fr)/system/category/:id(\\d+)',
    ],
    component: 'ArticleCategoryDetails',
    meta: {
      metaTitle: 'meta.category.title',
      metaKeywords: 'meta.category.keywords',
      metaDescription: 'meta.category.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleCategoryEdit',
    path: [
      '/:lang(en)?/system/category/:id(\\d+)/edit',
      '/:lang(de)/system/category/:id(\\d+)/edit',
      '/:lang(fr)/system/category/:id(\\d+)/edit',
    ],
    component: 'ArticleCategoryEdit',
    meta: {
      metaTitle: 'meta.category.title',
      metaKeywords: 'meta.category.keywords',
      metaDescription: 'meta.category.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'articleCategoryDetailsTabs',
    path: [
      '/:lang(en)?/system/category/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/system/category/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/system/category/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'ArticleCategoryDetails',
    meta: {
      metaTitle: 'meta.category.title',
      metaKeywords: 'meta.category.keywords',
      metaDescription: 'meta.category.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'settings',
    path: [
      '/:lang(en)?/system/settings',
      '/:lang(de)/system/settings',
      '/:lang(fr)/system/settings',
    ],
    component: 'SettingsList',
    menu: { type: 'system', order: 3, label: 'settings' },
    meta: {
      metaTitle: 'meta.settings.title',
      metaKeywords: 'meta.settings.keywords',
      metaDescription: 'meta.settings.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['super_admin'] },
  },
  {
    key: 'settingsAdd',
    path: [
      '/:lang(en)?/system/settings/add',
      '/:lang(de)/system/settings/add',
      '/:lang(fr)/system/settings/add',
    ],
    component: 'SettingsAdd',
    meta: {
      metaTitle: 'meta.settings.title',
      metaKeywords: 'meta.settings.keywords',
      metaDescription: 'meta.settings.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['super_admin'] },
  },
  {
    key: 'settingsDetails',
    path: [
      '/:lang(en)?/system/settings/:id(\\d+)',
      '/:lang(de)/system/settings/:id(\\d+)',
      '/:lang(fr)/system/settings/:id(\\d+)',
    ],
    component: 'SettingsDetails',
    meta: {
      metaTitle: 'meta.settings.title',
      metaKeywords: 'meta.settings.keywords',
      metaDescription: 'meta.settings.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['super_admin'] },
  },
  {
    key: 'settingsEdit',
    path: [
      '/:lang(en)?/system/settings/:id(\\d+)/edit',
      '/:lang(de)/system/settings/:id(\\d+)/edit',
      '/:lang(fr)/system/settings/:id(\\d+)/edit',
    ],
    component: 'SettingsEdit',
    meta: {
      metaTitle: 'meta.settings.title',
      metaKeywords: 'meta.settings.keywords',
      metaDescription: 'meta.settings.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['super_admin'] },
  },
  {
    key: 'offerAdd',
    path: ['/:lang(en)?/offer/add', '/:lang(de)/offer/add', '/:lang(fr)/offer/add'],
    component: 'OfferAdd',
    meta: {
      metaTitle: 'meta.offer.title',
      metaKeywords: 'meta.offer.keywords',
      metaDescription: 'meta.offer.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'offerEdit',
    path: [
      '/:lang(en)?/offer/:id(\\d+)/edit',
      '/:lang(de)/offer/:id(\\d+)/edit',
      '/:lang(fr)/offer/:id(\\d+)/edit',
    ],
    component: 'OfferEdit',
    meta: {
      metaTitle: 'meta.offer.title',
      metaKeywords: 'meta.offer.keywords',
      metaDescription: 'meta.offer.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'offerDetailsTabs',
    path: [
      '/:lang(en)?/offer/:id(\\d+)/:tab?/:subtab?',
      '/:lang(de)/offer/:id(\\d+)/:tab?/:subtab?',
      '/:lang(fr)/offer/:id(\\d+)/:tab?/:subtab?',
    ],
    component: 'OfferDetails',
    meta: {
      metaTitle: 'meta.offerDetails.title',
      metaKeywords: 'meta.offerDetails.keywords',
      metaDescription: 'meta.offerDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'offerDetails',
    path: [
      '/:lang(en)?/offer/:id(\\d+)',
      '/:lang(de)/offer/:id(\\d+)',
      '/:lang(fr)/offer/:id(\\d+)',
    ],
    component: 'OfferDetails',
    meta: {
      metaTitle: 'meta.offerDetails.title',
      metaKeywords: 'meta.offerDetails.keywords',
      metaDescription: 'meta.offerDetails.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'offerList',
    path: ['/:lang(en)?/offers', '/:lang(de)/offers', '/:lang(fr)/offers'],
    component: 'OfferList',
    menu: { type: 'main', order: 9, label: 'offers' },
    meta: {
      metaTitle: 'meta.offers.title',
      metaKeywords: 'meta.offers.keywords',
      metaDescription: 'meta.offers.description',
    },
    exact: true,
    strict: false,
    acl: { roles: ['admin'] },
  },
  {
    key: 'notfound',
    component: 'NotFound',
    meta: {
      metaTitle: 'meta.common.title',
      metaKeywords: 'meta.common.keywords',
      metaDescription: 'meta.common.description',
    },
    exact: false,
    strict: false,
    acl: { roles: ['*'] },
  },
];
