export const MODULE_INSTANCE_FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'status', type: 'discrete', filterType: 'basic' },
  { columnField: 'name', type: 'string' },
  { columnField: 'cache', type: 'discrete', filterType: 'basic' },
];

export const MODULE_INSTANCE_SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'status' },
  { columnField: 'name' },
  { columnField: 'cache' },
];

export const MODULE_FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'status', type: 'discrete', filterType: 'basic' },
  { columnField: 'name', type: 'string' },
  { columnField: 'cache', type: 'discrete', filterType: 'basic' },
  { columnField: 'zendModuleName', type: 'string' },
  { columnField: 'zendControllerName', type: 'string' },
  { columnField: 'zendActionName', type: 'string' },
];

export const MODULE_SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'status' },
  { columnField: 'name' },
  { columnField: 'cache' },
  { columnField: 'zendModuleName' },
  { columnField: 'zendControllerName' },
  { columnField: 'zendActionName' },
];

export const ATTRIBUTE_FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'moduleId', type: 'integer' },
  { columnField: 'name', type: 'string' },
  { columnField: 'type', type: 'discrete', filterType: 'basic' },
  { columnField: 'required', type: 'discrete', filterType: 'basic' },
  { columnField: 'localizable', type: 'discrete', filterType: 'basic' },
];

export const ATTRIBUTE_SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'moduleId' },
  { columnField: 'name' },
  { columnField: 'type' },
  { columnField: 'required' },
  { columnField: 'localizable' },
];

export const VIEW_FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'name', type: 'string' },
  { columnField: 'disable', type: 'discrete', filterType: 'basic' },
];

export const VIEW_SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'name' },
  { columnField: 'disable' },
  { columnField: 'zendModuleName' },
  { columnField: 'zendControllerName' },
  { columnField: 'zendActionName' },
];

export const LAYOUT_FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'status', type: 'discrete' },
  { columnField: 'name', type: 'string' },
];

export const LAYOUT_SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'status' },
  { columnField: 'name' },
];

export const PAGE_FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'name', type: 'string' },
  {
    columnField: 'status',
    apiField: 'status',
    type: 'discrete',
  },
  { columnField: 'uri', apiField: 'uri', type: 'string' },
  { columnField: 'locales', apiField: 'locale', type: 'string' },
];

export const PAGE_SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'name' },
  { columnField: 'status' },
  { columnField: 'uri' },
];

export const LAYOUT_PLACEHOLDER_FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'layoutId', type: 'integer' },
  { columnField: 'name', type: 'string' },
  { columnField: 'key', type: 'string' },
];

export const LAYOUT_PLACEHOLDER_SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'name' },
  { columnField: 'key' },
];
