import get from 'lodash/get';
import pick from 'lodash/pick';
import { GOOGLE_TAGMANAGER_ACTIONS, gtmAuthLogin } from '../actions/google/tagManager';
import { store as CONFIG_STORE } from '../../../config/config.js';
import { GTMService } from '../../riseart/GTM';

export const GTM_EVENT_TRIGGER_REASONS = {
  registration: 'registration',
  login: 'login',
  subscription: 'subscription',
  updateRegional: 'updateRegional',
  updateUser: 'updateUser',
  updateSubscriptions: 'updateSubscriptions',
  passwordReset: 'passwordReset',
};

/**
 * MiddlewareGoogleTagManager
 */
export default class MiddlewareGoogleTagManager {
  static EVENT_GA = 'ga.event';

  static EVENT_AUTH_LOGIN = 'ra.login';

  static EVENT_PAGEVIEW = 'ra.pageview';

  static EVENT_ME_UPDATED = 'ra.meUpdated';

  static EVENT_APPLICATION_CONFIG = 'ra.applicationConfig';

  static triggerReasonToEvent = {
    [GTM_EVENT_TRIGGER_REASONS.login]: gtmAuthLogin,
  };

  /**
   * Redux Middleware
   *
   * @param store
   */
  static middleware =
    (store: Record<string, any>) => (next: any) => (action: Record<string, any>) => {
      switch (action.type) {
        // Pageview
        case GOOGLE_TAGMANAGER_ACTIONS.PAGEVIEW:
          MiddlewareGoogleTagManager.pageviewEvent(action.payload);
          break;
        // Auth Login
        case GOOGLE_TAGMANAGER_ACTIONS.AUTH_LOGIN:
          MiddlewareGoogleTagManager.pushAuthEvent(
            MiddlewareGoogleTagManager.EVENT_AUTH_LOGIN,
            action.payload,
          );
          break;
        case GOOGLE_TAGMANAGER_ACTIONS.ME_UPDATED:
          MiddlewareGoogleTagManager.meUpdated(action.payload, store);
          break;
        // Application Config
        case GOOGLE_TAGMANAGER_ACTIONS.APPLICATION_PROPERTY_UPDATE:
          if (!action) {
            return;
          }

          switch (get(action, 'payload.payload.property')) {
            case 'locale':
              MiddlewareGoogleTagManager.applicationPropertyUpdate(
                MiddlewareGoogleTagManager.EVENT_APPLICATION_CONFIG,
                { [action.payload.payload.property]: get(action, 'payload.payload.value.name') },
              );
              break;
            default:
              break;
          }
          break;

        default:
          break;
      }
      next(action);
    };

  /**
   * getMe
   *
   * @param {Record<string, any>} store
   * @returns {Record<string, any> | null}
   */
  static getMe(store: Record<string, any>): Record<string, any> | null {
    return store.getState()[CONFIG_STORE.keys.me].data;
  }

  /**
   * buildAuthData
   *
   * @param {Record<string, any>} payload
   * @returns {Record<string, any>}
   */
  static buildAuthData(me: Record<string, any>): Record<string, any> {
    const authData: Record<string, any> = {
      identity: null,
      visitor: {},
      seller: {},
    };
    const user = pick(me.user, [
      'id',
      'role',
      'email',
      'firstName',
      'lastName',
      'lastLogin',
      'city',
      'phone',
      'birthDate',
      'ageRange',
      'gender',
      'timeZone',
      'quizDate',
    ]);

    if (me.user !== null) {
      authData.identity = {
        ...user,
        registered: get(me.user, 'registrationDate'),
        country: get(me.user, 'countryCode'),
        quizProfile: get(me.user, 'quizPersonality'),
        subscriptions: {
          newsletter: false,
          general: false,
          activityUpdates: false,
          activityWeekly: false,
        },
      };
    }
    if (me.seller !== null) {
      authData.seller = pick(me.seller, [
        'id',
        'type',
        'name',
        'entityRole',
        'entityId',
        'integrationId',
        'onboardingStage',
        'shipsCountryCode',
      ]);
    }
    return authData;
  }

  /**
   * pageviewEvent
   *
   * @param {Record<string, any>} payload
   * @returns {void}
   */
  static pageviewEvent(payload: Record<string, any>): void {
    if (!payload) {
      return;
    }

    GTMService.push({
      event: MiddlewareGoogleTagManager.EVENT_PAGEVIEW,
      page: {
        ...payload,
      },
    });
  }

  /**
   * meUpdated
   *
   * @param {Record<string, any>} data
   * @returns {void}
   */
  static meUpdated(data: Record<string, any> = {}, store: Record<string, any> | null = null): void {
    const { identity, visitor, seller } = MiddlewareGoogleTagManager.buildAuthData(data.payload);
    const { trigger } = data.payload;
    const postEvent = MiddlewareGoogleTagManager.triggerReasonToEvent[trigger && trigger.reason];

    GTMService.push({
      event: MiddlewareGoogleTagManager.EVENT_ME_UPDATED,
      eventTriggerReason: (trigger && trigger.reason) || null,
      identity,
      visitor,
      seller,
    });

    if (typeof postEvent === 'function' && store) {
      store.dispatch(postEvent(trigger.data));
    }
  }

  /**
   * pushAuthEvent
   * @param {string} event
   * @param {Record<string, any>} payload?
   * @returns {void}
   */
  static pushAuthEvent(event: string, payload: Record<string, any> | null = null): void {
    if (!event) {
      return;
    }

    GTMService.push({ event, ...payload });
  }

  /**
   * applicationPropertyUpdate
   *
   * @param {string} event
   * @param {Record<string, any>} data
   * @returns {void}
   */
  static applicationPropertyUpdate(event: string, data: Record<string, any>): void {
    GTMService.push({
      event,
      applicationConfig: data,
    });
  }
}
