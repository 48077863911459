import 'source-map-support/register';
import {
  Image as ImageBaseModel,
  ImageHierarchical as ImageHierarchicalBaseModel,
} from '@riseart/models';
import { ApiInvalidData } from '../errors/ApiInvalidData';

// TODO: @nikola Localize ApiInvalidData messages
// maybe use a common message for all injecting the data model name as a replacement
// to avoid having tons of very similar messages in the localization files

/**
 * Types
 */
type Constructor<T> = new (...args: any[]) => T;

/**
 * Image
 */
class Image extends ImageBaseModel {
  public fileId: number;

  /**
   * hydrateFromApiData
   *
   * @param {Record<string, any>} data
   * @returns {Image}
   */
  public hydrateFromApiData(data?: Record<string, any>): Image {
    try {
      const { file, ...image } = data || {};
      return this.hydrate({
        ...file,
        ...image,
        fileId: (file && file.id) || null,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Image from provided data');
    }
  }
}

/**
 * ImageArray
 */
class ImageArray extends Array {
  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ImageArray}
   */
  public hydrateFromApiData(data?: Array<Record<string, any>>): ImageArray {
    try {
      (data || []).forEach((item: Record<string, any>) =>
        this.push(new Image().hydrateFromApiData(item)),
      );

      return this;
    } catch (error) {
      throw new ApiInvalidData('Unable to load ImageArray from provided data');
    }
  }

  /**
   * getById
   *
   * @param {number} id
   * @param {boolean} active
   * @returns {Image}
   */
  public getById(id: number, active?: boolean): Image | undefined {
    return this.find(
      (image: Image) => image.id === id && (!active || (active && image.active === true)),
    );
  }

  /**
   * getByType
   *
   * @param {string} type
   * @param {boolean} active
   * @returns {Image}
   */
  public getByType(type: string, active?: boolean): Image | undefined {
    return this.find(
      (image: Image) => image.type === type && (!active || (active && image.active === true)),
    );
  }

  /**
   * getActive
   *
   * @returns {ImageArray}
   */
  public getActive(): ImageArray {
    return this.filter((image: Image) => image.active === true) as ImageArray;
  }
}

/**
 * ImageHierarchicalMixin
 *
 * @param {ImageHierarchicalBaseModel} SuperClass
 */
function ImageHierarchicalMixin<
  T extends Constructor<ImageHierarchicalBaseModel>,
  A extends Constructor<ImageHierarchicalArray>,
>(SuperClass: T, ArrayClass: A): any {
  return class extends SuperClass {
    children: ImageHierarchicalArray;

    /**
     * getArrayClass
     *
     * @returns {ImageHierarchicalArray}
     */
    protected getArrayClass(): ImageHierarchicalArray {
      return new ArrayClass();
    }

    /**
     * hydrateFromApiData
     *
     * @param {Record<string, any>} data
     * @returns {ImageHierarchical}
     */
    public hydrateFromApiData(data?: Record<string, any>): ImageHierarchical {
      try {
        const { file, children: childrenData, ...image } = data || {};

        // Children images
        const children: ImageHierarchicalArray =
          this.getArrayClass().hydrateFromApiData(childrenData);

        // Hydrate model
        return this.hydrate({
          children,
          ...file,
          ...image,
          fileId: file.id,
        });
      } catch (error) {
        throw new ApiInvalidData('Unable to load ImageHierarchical from provided data');
      }
    }

    /**
     * flatten: returns the entire image hierarchy as a shallow array
     *
     * @returns {ImageHierarchicalArray}
     */
    public flatten(): ImageHierarchicalArray {
      const { children, ...image } = this;
      const accumulator = this.getArrayClass();
      accumulator.push(Object.create(this).hydrate(image));

      return (children || []).reduce(
        (accumulator: ImageHierarchicalArray, child: ImageHierarchical): ImageHierarchicalArray => {
          accumulator.push(...child.flatten());
          return accumulator;
        },
        accumulator,
      );
    }
  };
}

/**
 * ImageHierarchicalArray
 */
class ImageHierarchicalArray extends Array {
  /**
   * getImageObject
   *
   * @returns {ImageHierarchical}
   */
  protected getImageObject(): ImageHierarchical {
    return new ImageHierarchical();
  }

  /**
   * hydrateFromApiData
   *
   * @param {Array<Record<string, any>>} data
   * @returns {ImageArray}
   */
  public hydrateFromApiData(data?: Array<Record<string, any>>): ImageHierarchicalArray {
    try {
      (data || []).forEach((item: Record<string, any>) =>
        this.push(this.getImageObject().hydrateFromApiData(item)),
      );

      return this;
    } catch (error) {
      throw new ApiInvalidData('Unable to load ImageHierarchicalArray from provided data');
    }
  }

  /**
   * flatten: returns the entire image hierarchy as a shallow array
   *
   * @returns {ImageHierarchicalArray}
   */
  public flatten(): ImageHierarchicalArray {
    return this.reduce(
      (accumulator: ImageHierarchicalArray, image: ImageHierarchical): ImageHierarchicalArray => {
        accumulator.push(...image.flatten());
        return accumulator;
      },
      Object.create(this),
    );
  }

  /**
   * group: returns the entire image hierarchy as an array of shallow arrays (for each parent)
   *
   * @returns {Array<ImageHierarchicalArray>}
   */
  public group(): Array<ImageHierarchicalArray> {
    return this.reduce((accumulator: Array<ImageHierarchicalArray>, image: ImageHierarchical) => {
      accumulator.push(image.flatten());
      return accumulator;
    }, []);
  }

  /**
   * getById
   *
   * @param {number} id
   * @param {boolean} active
   * @returns {ImageHierarchical}
   */
  public getById(id: number, active?: boolean): ImageHierarchical | undefined {
    return this.flatten().find(
      (image: ImageHierarchical) =>
        image.id === id && (!active || (active && image.active === true)),
    );
  }

  /**
   * getParentById
   *
   * @param {number} id
   * @param {boolean} active
   * @returns {ImageHierarchical}
   */
  public getParentById(id: number, active?: boolean): ImageHierarchical | undefined {
    return this.find(
      (image: ImageHierarchical) =>
        image.id === id && (!active || (active && image.active === true)),
    );
  }

  /**
   * getParentByType
   *
   * @param {string} type
   * @param {boolean} active
   * @returns {ImageHierarchical}
   */
  public getParentByType(type: string, active?: boolean): ImageHierarchical | undefined {
    return this.find(
      (image: ImageHierarchical) =>
        image.type === type && (!active || (active && image.active === true)),
    );
  }

  /**
   * getParentsByType
   *
   * @param {string} type
   * @returns {ImageHierarchicalArray}
   */
  public getParentsByType(type: string, active?: boolean): ImageHierarchicalArray | undefined {
    return this.reduce(
      (accumulator: ImageHierarchicalArray, image: ImageHierarchical): ImageHierarchicalArray => {
        if (image.type === type && (!active || (active && image.active === true))) {
          accumulator.push(image);
        }
        return accumulator;
      },
      [],
    );
  }

  /**
   * getChildByType
   *
   * @param {string} type
   * @param {string} parentType
   * @returns {ImageHierarchical}
   */
  public getChildByType(
    type: string,
    parentType: string,
    active?: boolean,
  ): ImageHierarchical | undefined {
    const parent = this.getParentByType(parentType, active);
    if (parent && parent.children) {
      return parent.children.getParentByType(type, active);
    }
  }

  /**
   * getActive
   *
   * @returns {ImageHierarchicalArray}
   */
  public getActive(): ImageHierarchicalArray {
    return this.filter(
      (image: ImageHierarchical) => image.active === true,
    ) as ImageHierarchicalArray;
  }
}

/**
 * ImageHierarchical
 */
class ImageHierarchical extends ImageHierarchicalMixin(
  ImageHierarchicalBaseModel,
  ImageHierarchicalArray,
) {}

export { Image, ImageArray, ImageHierarchical, ImageHierarchicalArray, ImageHierarchicalMixin };
