import { ElementType, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GridColDef, GridFilterItem } from '@mui/x-data-grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import { Country } from '@riseart/dashboard';
import { delay } from '../../../services/riseart/utils/Utils';

import styles from './schemas/schemas.module.css';

export const DEFAULT_COLUMN_PROPS = {
  minWidth: 100,
  headerClassName: styles.gridHeader,
};

export const PICK_COLUMN_PROPS: Record<string, any> = {
  ...DEFAULT_COLUMN_PROPS,
  headerClassName: styles.gridHeader,
  headerAlign: 'center',
  align: 'center',
  sortable: false,
  filterable: false,
};

/**
 * getColumnHeaderRenderer
 *
 * @param {string} translationId
 * @param {ElementType<any> | undefined} tagName ?
 * @returns {() => JSX.Element}
 */
export function getColumnHeaderRenderer(
  translationId: string,
  tagName: ElementType<any> | undefined = 'strong',
): () => JSX.Element {
  return function renderer() {
    return <FormattedMessage id={translationId} tagName={tagName} />;
  };
}

/**
 * renderCountryCell
 *
 * @param {Record<string, any>} data
 * @returns {JSX.Element | null}
 */
export function renderCountryCell({ data }: Record<string, any>): JSX.Element | null {
  return data.countryCode ? <Country code={data.countryCode} /> : null;
}

/**
 * renderLocalesList
 *
 * @param data {Record<string, any>}
 * @returns {JSX.Element}
 */
export function renderLocalesList({ data }: Record<string, any>): JSX.Element {
  return data && data.locales
    ? data.locales.map(({ localeCode }: Record<string, any>) =>
        localeCode ? (
          <Chip
            key={localeCode}
            sx={{ m: '3px' }}
            label={<FormattedMessage id={`locales.${localeCode.toLowerCase()}`} />}
          />
        ) : (
          <></>
        ),
      )
    : null;
}

function PickedItemsInputValue(props: Record<string, any>) {
  const { item, applyValue, label, selectItems } = props;
  const handleFilterChange: any = (event: SelectChangeEvent) => {
    applyValue({ ...item, value: event.target.value as string });
  };

  return (
    <>
      <InputLabel shrink id="picker-selected-label" htmlFor="picker-selected-input">
        {label}
      </InputLabel>
      <Select
        labelId="picker-selected-label"
        id="picker-selected-input"
        displayEmpty
        defaultValue=""
        onChange={handleFilterChange}
      >
        {selectItems.map((option: Record<string, any>) => (
          <MenuItem
            disabled={[null, undefined].indexOf(option.value) > -1}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

/**
 * dataPickerActionColumn
 *
 * @param {(options: Record<string, any>) => string} formatMessage
 * @returns {GridColDef}
 */
export function dataPickerActionColumn(
  formatMessage: (options: Record<string, any>) => string,
  options: Record<string, any> | null = null,
): GridColDef {
  const { pickerProps, ...columnOptions } = options || {};

  return {
    ...PICK_COLUMN_PROPS,
    field: 'customData',
    headerName: formatMessage({ id: 'components.tables.columns.common.pick' }),
    renderCell: ({ row: { customData, rowData: data } }: Record<string, any>): JSX.Element => {
      const {
        picker: { selectedItems, handleSelect },
      } = customData;

      return (
        <IconButton
          {...(selectedItems &&
          selectedItems.some((item: Record<string, any>) => item.id === data.id)
            ? {
                sx: {
                  color: '#fff',
                  bgcolor: 'primary.main',
                  '&:hover': { bgcolor: 'primary.main' },
                },
              }
            : null)}
          onClick={() => handleSelect(data)}
        >
          <CheckIcon />
        </IconButton>
      );
    },
    ...columnOptions,
    ...(columnOptions && columnOptions.filterable
      ? {
          type: 'singleSelect',
          field: '_selectedItems',
          filterOperators: [
            {
              label: formatMessage({ id: 'components.grid.filter.operator.is' }),
              value: 'isAnyOf',
              getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                  return null;
                }

                return (params: Record<string, any>): boolean => {
                  return Number(params.value) >= Number(filterItem.value);
                };
              },
              InputComponent: PickedItemsInputValue,
              InputComponentProps: {
                label: formatMessage({ id: 'components.grid.filter.label.value' }),
                selectItems: [
                  { value: '', label: formatMessage({ id: 'components.grid.filter.values.all' }) },
                  {
                    value:
                      (pickerProps &&
                        pickerProps.selectedItems &&
                        pickerProps.selectedItems.reduce(
                          (acumulator: string, item: Record<string, any>) =>
                            `${acumulator !== null ? `${acumulator},` : ''}${item.id}`,
                          null,
                        )) ||
                      null,
                    label: formatMessage({ id: 'components.grid.filter.values.selected' }),
                  },
                ],
              },
            },
          ],
        }
      : null),
  };
}

type Props = {
  updateRequest?: (options: Record<string, any>) => Promise<any> | null;
  refetchListQuery: () => any;
  valueKey: string;
  value: boolean;
  resourceId: number;
  disabled?: boolean;
};

/**
 * DataTableSwitch
 *
 * @param {props} Props
 * @returns {: JSX.Element}
 */
export function DataTableSwitch({
  disabled,
  updateRequest,
  refetchListQuery,
  valueKey,
  value,
  resourceId,
}: Props): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [switchValue, setSwitchValue] = useState(!!value);

  if (updateRequest) {
    return (
      <Switch
        disabled={disabled || !updateRequest || loading}
        checked={switchValue}
        onChange={(e: Record<string, any>) => {
          const checked = e.target.checked;
          setLoading(true);
          setSwitchValue(checked);
          // @ts-ignore
          updateRequest({
            resourceId,
            parameters: { [valueKey]: checked },
          })
            .then(() => {
              if (refetchListQuery) {
                delay(() =>
                  refetchListQuery()
                    .then(() => setLoading(false))
                    .catch(() => setLoading(false)),
                );
              } else {
                setLoading(false);
              }
            })
            .catch(() => {
              setLoading(false);
              setSwitchValue(!checked);
            });
        }}
      />
    );
  }

  return <FormattedMessage id={value ? 'common.yes' : 'common.no'} />;
}
