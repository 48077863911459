import { FormattedMessage, FormattedDate } from 'react-intl';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import Switch from '@mui/material/Switch';
import { Country, Link, SplitButton } from '@riseart/dashboard';
import { partner as PARTNER_ENUM } from '../../../../config/enumeration.js';
import { countries as COUNTRIES_LIST } from '../../../../config/directory.js';
import { delay } from '../../../../services/riseart/utils/Utils';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { filterSelectOptions, DISCRETE_TYPE_OPERATORS } from '../../../data/tables/filters/utils';
import { DEFAULT_COLUMN_PROPS, dataPickerActionColumn } from '../utils';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { Status } from '../../../common/Status';
import { SchemaType } from './types';

export const PARTNERLIST_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink = false }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.partner.name' }),
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.partnerDetails(data.id)} title={data.name}>
                {data.name}
              </Link>
            );
          },
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'type',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.partner.type' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <FormattedMessage id={`components.partner.type.${data.type}`} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(PARTNER_ENUM.type, 'components.partner.type', formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.partner.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="partner" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    sortable: false,
    valueOptions: filterSelectOptions(
      PARTNER_ENUM.status,
      'components.partner.status',
      formatMessage,
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'country',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.country' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
      <Country code={data.countryCode} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(COUNTRIES_LIST.all, 'countries', formatMessage),
  },
];

export const PARTNERLIST_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage }) => [
  ...PARTNERLIST_BASE_DATAGRID_TABLE_SCHEMA({ formatMessage }),
  dataPickerActionColumn(formatMessage),
];

// Artist partners
export const ARTIST_PARTNERS_SCHEMA: SchemaType = [
  { key: 'id' },
  {
    key: 'type',
    render: ({ data }: Record<string, any>) =>
      data && data.type ? <FormattedMessage id={`components.partner.type.${data.type}`} /> : null,
  },
  {
    key: 'name',
    render: ({ data }: Record<string, any>) =>
      data && data.name ? (
        <Link to={UrlAssembler.partnerDetails(data.id)} title={data.name}>
          {data.name}
        </Link>
      ) : null,
  },
  {
    key: 'status',
    render: ({ data }: Record<string, any>) =>
      data && [null, undefined].indexOf(data.status) === -1 ? (
        <Status type="partner" status={data.status} />
      ) : null,
  },
  {
    key: 'country',
    render: ({ data }: Record<string, any>) =>
      (data && <Country code={data.countryCode} />) || null,
  },
  {
    key: 'canAdminArtist',
    render: ({ data }: Record<string, any>) =>
      data ? <FormattedMessage id={`common.${data.canAdminArtist ? 'yes' : 'no'}`} /> : null,
  },
  {
    key: 'actions',
    render: ({
      data: { id },
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          actionKey: 'delete',
          onClick: () => {
            deleteMutation({ resourceId: id })
              .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
              .catch(() => null);
          },
          requireConfirmation: true,
          children: <FormattedMessage id="common.delete" />,
        }}
        translationPrefix="components.dialog.article.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[]}
      />
    ),
  },
];

export const PARTNER_ARTIST_TABLE_SCHEMA = [
  {
    key: 'artistId',
  },
  {
    key: 'name',
    render: ({ data: { artistId, artist } }: Record<string, any>): JSX.Element => (
      <Link to={UrlAssembler.artistDetails(artistId)} title={artist.name}>
        {artist.name}
      </Link>
    ),
  },
  {
    key: 'gender',
    render: ({
      data: {
        artist: { gender },
      },
    }: Record<string, any>): JSX.Element | null =>
      gender ? <FormattedMessage id={`common.gender.${gender}`} /> : null,
  },
  {
    key: 'country',
    render: ({
      data: {
        artist: { livesCountry },
      },
    }: Record<string, any>): JSX.Element | null =>
      livesCountry && livesCountry.code ? <Country code={livesCountry.code} /> : null,
  },
  {
    key: 'status',
    render: ({ data }: Record<string, any>): JSX.Element => (
      <Status type="partnerArtist" status={data.status} />
    ),
  },
  {
    key: 'admin',
    render: ({
      data,
      customData: { updateRequest, updateLoading, refetchListQuery },
    }: Record<string, any>): JSX.Element => (
      <Switch
        disabled={updateLoading}
        checked={data.admin}
        onChange={(e: Record<string, any>) => {
          updateRequest({
            resourceId: data.artistId,
            parameters: { admin: e.target.checked },
          })
            .then(() => delay(refetchListQuery))
            .catch(() => null);
        }}
      />
    ),
  },
  {
    key: 'actions',
    render: ({
      data: { artistId },
      handleToggleMode,
      customData: { deleteMutation, deleteLoading, refetchListQuery },
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.partner.artist"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: artistId })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];

export const PARTNER_USER_TABLE_SCHEMA = [
  {
    key: 'userId',
  },
  {
    key: 'name',
    render: ({ data }: Record<string, any>): JSX.Element => (
      <Link to={UrlAssembler.userDetails(data.userId)} title={data.fullName}>
        {data.fullName}
      </Link>
    ),
  },
  {
    key: 'status',
    render: ({ data }: Record<string, any>): JSX.Element => (
      <Status type="partnerUser" status={data.status} />
    ),
  },
  {
    key: 'aclRole',
    render: ({ data }: Record<string, any>): JSX.Element => (
      <FormattedMessage id={`common.acl.roles.${data.aclRole}`} />
    ),
  },
  {
    key: 'actions',
    render: ({
      data: { userId },
      handleToggleMode,
      customData: { deleteMutation, deleteLoading, refetchListQuery, hasSeller },
    }: Record<string, any>): JSX.Element => {
      const [mainAction, ...secondaryActions] = [
        ...(hasSeller
          ? [
              {
                onClick: handleToggleMode(true),
                children: <FormattedMessage id="common.edit" />,
              },
            ]
          : []),
        {
          actionKey: 'delete',
          requireConfirmation: true,
          [hasSeller ? 'text' : 'children']: <FormattedMessage id="common.delete" />,
          onClick: () => {
            deleteMutation({ resourceId: userId })
              .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
              .catch(() => null);
          },
        },
      ];

      return (
        <SplitButton
          disabled={deleteLoading}
          mainButtonProps={mainAction}
          translationPrefix="components.dialog.partner.user"
          confirmationDialogProps={splitButtonConfirmationProps}
          options={secondaryActions}
        />
      );
    },
  },
];

export const PARTNER_INVITATION_TABLE_SCHEMA = [
  { key: 'id' },
  { key: 'email' },
  {
    key: 'aclRole',
    render: ({ data }: Record<string, any>): JSX.Element => (
      <FormattedMessage id={`common.acl.roles.${data.aclRole}`} />
    ),
  },
  {
    key: 'created',
    render: ({ data }: Record<string, any>): JSX.Element | null =>
      data.created ? (
        <FormattedDate
          value={data.created}
          year="numeric"
          month="short"
          day="numeric"
          weekday="short"
        />
      ) : null,
  },
  {
    key: 'expirationDate',
    render: ({ data }: Record<string, any>): JSX.Element | null =>
      data.expirationDate ? (
        <FormattedDate
          value={data.expirationDate}
          year="numeric"
          month="short"
          day="numeric"
          weekday="short"
        />
      ) : null,
  },
  {
    key: 'actions',
    render: ({
      data,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          actionKey: 'cancel',
          requireConfirmation: true,
          onClick: () => {
            deleteMutation({ resourceId: data.id })
              .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
              .catch(() => null);
          },
          children: <FormattedMessage id="common.cancel" />,
        }}
        translationPrefix="components.dialog.partner.invitation"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[]}
      />
    ),
  },
];
