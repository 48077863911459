import { FormattedMessage } from 'react-intl';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import { ConfirmationDialog, Country, Link, SplitButton } from '@riseart/dashboard';
import { user as USER_ENUM } from '../../../../config/enumeration.js';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { Status } from '../../../common/Status';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { filterSelectOptions, DISCRETE_TYPE_OPERATORS } from '../../../data/tables/filters/utils';
import { DEFAULT_COLUMN_PROPS, dataPickerActionColumn } from '../utils';
import { delay } from '../../../../services/riseart/utils/Utils';
import { SchemaType } from './types';
import styles from './schemas.module.css';

export const USERLIST_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.artist.id' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.userDetails(data.id)} title={data.id}>
                {data.id}
              </Link>
            );
          },
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'firstName',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.firstName' }),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'lastName',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.lastName' }),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'email',
    type: 'string',
    flex: 2,
    headerName: formatMessage({ id: 'components.tables.columns.common.email' }),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'role',
    type: 'singleSelect',
    width: 120,
    headerName: formatMessage({ id: 'components.tables.columns.common.role' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <FormattedMessage id={`components.user.role.${data.role}`} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(USER_ENUM.role, 'components.user.role', formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'singleSelect',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.artist.status' }),
    renderCell: ({ row: data }: Record<string, any>) => <Status type="user" status={data.status} />,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(USER_ENUM.status, 'components.user.status', formatMessage),
  },
];

export const USERLIST_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage }) => [
  ...USERLIST_BASE_DATAGRID_TABLE_SCHEMA({ formatMessage }),
  dataPickerActionColumn(formatMessage),
];

export const USER_ACCOUNTS_LIST: SchemaType = [
  { key: 'id' },
  {
    key: 'domain',
    render: ({ data }: Record<string, any>): JSX.Element | null => {
      return [null, undefined].indexOf(data.domain) < 0 ? (
        <FormattedMessage id={`components.user.account.domain.${data.domain}`} />
      ) : null;
    },
  },
  {
    key: 'status',
    render: ({ data }: Record<string, any>): JSX.Element | null => {
      return [null, undefined].indexOf(data.status) < 0 ? (
        <Status type="userAccount" status={data.status} />
      ) : null;
    },
  },
  { key: 'entityId' },
  {
    key: 'actions',
    render: ({ data, customData }: Record<string, any>): JSX.Element | null => {
      return customData.totalItems > 1 ? (
        <ConfirmationDialog
          title={<FormattedMessage id="components.dialog.user.account.delete.title" />}
          description={<FormattedMessage id="components.dialog.user.account.delete.description" />}
          disagreeLabel={<FormattedMessage id="common.no" />}
          agreeLabel={<FormattedMessage id="common.yes" />}
          onAgree={() => {
            customData
              .deleteRequest({ resourceId: data.id })
              .then(() => customData.refetchList())
              .catch(() => null);
          }}
        >
          {(handleOpen) => (
            <IconButton onClick={handleOpen}>
              <DeleteIcon />
            </IconButton>
          )}
        </ConfirmationDialog>
      ) : null;
    },
  },
];

export const USER_MAILING_LIST_SCHEMA: (properties: Record<string, any>) => GridColumns<any> = ({
  formatMessage,
}) => [
  {
    field: 'type',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.user.mailing.list' }),
    filterable: false,
    sortable: false,
    renderCell: ({ row: data }: Record<string, any>): JSX.Element | null => {
      return [null, undefined].indexOf(data.type) < 0 ? (
        <FormattedMessage id={`components.user.mailing.type.${data.type}`} />
      ) : null;
    },
  },
  {
    field: 'status',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.user.mailing.statusLabel' }),
    filterable: false,
    sortable: false,
    renderCell: ({ row: data }: Record<string, any>): JSX.Element | null => {
      return (
        <Switch
          checked={data.status === USER_ENUM.mailingList.status.STATUS_ACTIVE ? true : false}
          onChange={(e: Record<string, any>) => {
            data.customData
              .updateRequest({
                parameters: {
                  items: [
                    {
                      type: data.type,
                      status: e.target.checked
                        ? USER_ENUM.mailingList.status.STATUS_ACTIVE
                        : USER_ENUM.mailingList.status.STATUS_INACTIVE,
                    },
                  ],
                },
              })
              .then(() => delay(data.customData.refetchList))
              .catch(() => null);
          }}
        />
      );
    },
  },
  {
    field: 'email',
    type: 'string',
    flex: 2,
    headerName: formatMessage({ id: 'components.user.mailing.email' }),
    filterable: false,
    sortable: false,
    renderCell: ({ row: data }: Record<string, any>): JSX.Element | null => {
      return (data.emailAddress && data.emailAddress.email) || null;
    },
  },
];

export const USER_PRIVACY_SETTINGS_LIST: SchemaType = [
  { key: 'id' },
  {
    key: 'category',
    render: ({ data }: Record<string, any>): JSX.Element | null => {
      return data.category ? (
        <FormattedMessage id={`components.user.privacy.category.${data.category}`} />
      ) : null;
    },
  },
  {
    key: 'name',
    render: ({ data }: Record<string, any>): JSX.Element | null => {
      return data.name ? (
        <FormattedMessage id={`components.user.privacy.setting.${data.name}.name`} />
      ) : null;
    },
  },
  {
    key: 'description',
    render: ({ data }: Record<string, any>): JSX.Element | null => {
      return data.category ? (
        <FormattedMessage id={`components.user.privacy.setting.${data.name}.description`} />
      ) : null;
    },
  },
  {
    key: 'value',
    render: ({
      data,
      customData: { updateRequest, refetchList },
    }: Record<string, any>): JSX.Element | null => {
      return (
        <Switch
          checked={data.value}
          onChange={(e: Record<string, any>) => {
            updateRequest({
              parameters: {
                items: [
                  {
                    category: data.category,
                    name: data.name,
                    value: e.target.checked,
                  },
                ],
              },
            })
              .then(() => refetchList())
              .catch(() => null);
          }}
        />
      );
    },
  },
];

export const USER_PRIVACY_SETTINGS_LIST_DATAGRID_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage }) => [
  {
    field: 'category',
    type: 'singleSelect',
    maxWidth: 200,
    headerName: formatMessage({ id: 'components.user.privacy.categoryLabel' }),
    sortable: false,
    filterable: true,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      USER_ENUM.privacySetting.category,
      'components.user.privacy.category',
      formatMessage,
    ),
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    renderCell: ({ row: data }: Record<string, any>) =>
      data.category ? (
        <FormattedMessage id={`components.user.privacy.category.${data.category}`} />
      ) : null,
  },
  {
    field: 'name',
    type: 'string',
    minWidth: 170,
    headerName: formatMessage({ id: 'components.user.privacy.name' }),
    sortable: false,
    filterable: false,
    renderCell: ({ row: data }: Record<string, any>) =>
      data.name ? (
        <FormattedMessage
          id={`components.user.privacy.setting.${data.category}.${data.name}.name`}
        />
      ) : null,
  },
  {
    field: 'actions',
    type: 'string',
    align: 'left',
    headerAlign: 'left',
    width: 100,
    filterable: false,
    sortable: false,
    hideable: false,
    headerName: formatMessage({ id: 'components.user.privacy.value' }),
    renderCell: ({ row: { customData, ...data } }: Record<string, any>): JSX.Element => (
      <Switch
        checked={data.value}
        onChange={(e: Record<string, any>) => {
          customData
            .updateRequest({
              parameters: {
                items: [
                  {
                    category: data.category,
                    name: data.name,
                    value: e.target.checked,
                  },
                ],
              },
            })
            .then(() => customData.refetchList())
            .catch(() => null);
        }}
      />
    ),
    headerClassName: styles.columnRightPadded,
    cellClassName: styles.columnRightPadded,
  },
  {
    field: 'description',
    type: 'string',
    flex: 2,
    headerName: formatMessage({ id: 'components.user.privacy.description' }),
    sortable: false,
    filterable: false,
    renderCell: ({ row: data }: Record<string, any>) =>
      data.name ? (
        <FormattedMessage
          id={`components.user.privacy.setting.${data.category}.${data.name}.description`}
        />
      ) : null,
  },
];

export const USER_CARDS_LIST_SCHEMA: (properties: Record<string, any>) => GridColumns<any> = ({
  formatMessage,
}) => [
  {
    field: 'cardId',
    type: 'string',
    flex: 1,
    minWidth: 170,
    headerName: formatMessage({ id: 'components.tables.columns.user.cardId' }),
    filterable: false,
    sortable: false,
  },
  {
    field: 'type',
    type: 'string',
    headerName: formatMessage({ id: 'components.common.type' }),
    filterable: false,
    sortable: false,
    renderCell: ({ row: data }: Record<string, any>): JSX.Element | null =>
      [null, undefined].indexOf(data.cardType) < 0 ? (
        <FormattedMessage id={`components.user.card.type.${data.cardType}`} />
      ) : null,
  },
  {
    field: 'cardLast4',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.user.last4' }),
    filterable: false,
    sortable: false,
  },
  {
    field: 'cardEndMonth',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.user.expiryDate' }),
    filterable: false,
    sortable: false,
    renderCell: ({ row: data }: Record<string, any>): string =>
      `${data.cardEndMonth}/${data.cardEndYear}`,
  },
  {
    field: 'canUse',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.user.usable' }),
    filterable: false,
    sortable: false,
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
      <FormattedMessage id={data.canUse ? 'common.yes' : 'common.no'} />
    ),
  },
  {
    field: 'billingAddress',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.user.billingAddress' }),
    filterable: false,
    sortable: false,
    renderCell: ({ row: data }: Record<string, any>): JSX.Element | null => {
      if (!data.billingAddress) {
        return null;
      }

      return (
        <span
          dangerouslySetInnerHTML={{
            __html: [
              `${data.billingAddress.addressLine1},`,
              data.billingAddress.addressLine2,
              data.billingAddress.city,
              data.billingAddress.postcode,
              data.billingAddress.country && data.billingAddress.country.code
                ? formatMessage({ id: `countries.${data.billingAddress.country.code}` })
                : null,
            ]
              .filter((item) => !!item)
              .join('<br />'),
          }}
        />
      );
    },
  },
  {
    field: 'actions',
    type: 'string',
    filterable: false,
    sortable: false,
    hideable: false,
    headerName: '',
    align: 'right',
    headerAlign: 'right',
    renderCell: ({ row: { customData, ...data } }: Record<string, any>): JSX.Element => (
      <ConfirmationDialog
        title={formatMessage({ id: 'components.dialog.user.card.delete.title' })}
        description={formatMessage({ id: 'components.dialog.user.card.delete.description' })}
        disagreeLabel={formatMessage({ id: 'common.no' })}
        agreeLabel={formatMessage({ id: 'common.yes' })}
        onAgree={() => {
          customData
            .deleteRequest({ resourceId: data.id, parameters: { method: data.method } })
            .then(() => delay(customData.refetchList))
            .catch(() => null);
        }}
      >
        {(handleOpen) => (
          <IconButton onClick={handleOpen}>
            <DeleteIcon />
          </IconButton>
        )}
      </ConfirmationDialog>
    ),
    headerClassName: styles.columnRightPadded,
    cellClassName: styles.columnRightPadded,
  },
  /*
  {
    field: 'status',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.user.mailing.statusLabel' }),
    filterable: false,
    sortable: false,
    renderCell: ({ row: data }: Record<string, any>): JSX.Element | null => {
      return (
        <Switch
          checked={data.status === USER_ENUM.mailingList.status.STATUS_ACTIVE ? true : false}
          onChange={(e: Record<string, any>) => {
            data.customData
              .updateRequest({
                parameters: {
                  items: [
                    {
                      type: data.type,
                      status: e.target.checked
                        ? USER_ENUM.mailingList.status.STATUS_ACTIVE
                        : USER_ENUM.mailingList.status.STATUS_INACTIVE,
                    },
                  ],
                },
              })
              .then(() => delay(data.customData.refetchList))
              .catch(() => null);
          }}
        />
      );
    },
  },
  {
    field: 'email',
    type: 'string',
    flex: 2,
    headerName: formatMessage({ id: 'components.user.mailing.email' }),
    filterable: false,
    sortable: false,
    renderCell: ({ row: data }: Record<string, any>): JSX.Element | null => {
      return (data.emailAddress && data.emailAddress.email) || null;
    },
  },
  */
];

// User shipping
export const USER_SHIPPING_SCHEMA: SchemaType = [
  { key: 'name' },
  { key: 'addressLine1' },
  { key: 'addressLine2' },
  { key: 'city' },
  { key: 'county' },
  { key: 'postcode' },
  {
    key: 'countryCode',
    render: ({ data }): JSX.Element | null =>
      data.countryCode ? <Country code={data.countryCode} /> : null,
  },
  { key: 'notes' },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.user.shipping.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: <FormattedMessage id="common.delete" />,
            onClick: () => {
              deleteMutation({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];
