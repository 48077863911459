import { useCallback, useEffect, useState } from 'react';
import { useGet } from '@riseart/api-client-react';

function defaultBuildUrl(DEFAULT_ITEMS_PER_PAGE = 10) {
  return function buildUrl({
    page,
    itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
    ...qsParams
  }: Record<string, any>) {
    return {
      ...qsParams,
      ...(page && page > 1 ? { page } : null),
      ...(itemsPerPage && itemsPerPage !== DEFAULT_ITEMS_PER_PAGE ? { itemsPerPage } : null),
    };
  };
}

type Props = {
  controlled?: boolean;
  endpoint: string;
  itemsPerPage?: number;
  variablesMapper: (
    qsParams: Record<string, any>,
    options?: Record<string, any>,
  ) => Record<string, any>;
  buildUrl?: (data: Record<string, any>) => any;
  children: (data: Record<string, any>) => any;
  onChangeParams?: (params: Record<string, any>) => any;
  onChangePage?: (
    params: Record<string, any>,
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => any;
  onChangeRowsPerPage?: (
    params: Record<string, any>,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => any;
  initialState?: Record<string, any>;
  inputParams?: Record<string, any>;
};

/**
 * DataListFeed
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const DataListFeed = ({
  controlled = false,
  endpoint,
  itemsPerPage: initialItemsPerPage = 10,
  initialState = {},
  inputParams: passedParams,
  buildUrl,
  variablesMapper,
  children,
  onChangePage,
  onChangeRowsPerPage,
  onChangeParams,
}: Props): JSX.Element => {
  const DEFAULT_ITEMS_PER_PAGE = initialItemsPerPage;
  const buildUrlHandler = buildUrl || defaultBuildUrl(DEFAULT_ITEMS_PER_PAGE);
  const [qsParams, setQsParams] = useState<Record<string, any>>({
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    page: 1,
    ...initialState,
  });
  const { itemsPerPage, page = 1, ...restQsParams } = qsParams;
  const {
    data,
    previousData,
    loading,
    error,
    processRequest: refetchListQuery,
  } = useGet({
    endpoint,
    parameters: variablesMapper(controlled ? passedParams || {} : qsParams),
  });

  const setStateParams = useCallback(
    (params: Record<string, any>) => {
      // if component is controlled then update the upper/parent state
      if (controlled && typeof onChangeParams === 'function') {
        onChangeParams(params);
      }

      setQsParams(params);
    },
    [controlled],
  );

  // update the component state from parent state if the component is controlled
  useEffect(() => {
    if (controlled) {
      setQsParams(passedParams || {});
    }
  }, [controlled, passedParams]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const params = buildUrlHandler({
      ...restQsParams,
      page: page + 1,
      itemsPerPage: itemsPerPage || DEFAULT_ITEMS_PER_PAGE,
    });

    setStateParams(params);

    if (typeof onChangePage === 'function') {
      onChangePage(params, event, page);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const params = buildUrlHandler({
      ...restQsParams,
      page: 0,
      itemsPerPage: parseInt(event.target.value, 10) || DEFAULT_ITEMS_PER_PAGE,
    });

    setStateParams(params);

    if (typeof onChangeRowsPerPage === 'function') {
      onChangeRowsPerPage(params, event);
    }
  };

  const handleParamsChange = (inputParams: Record<string, any>) => {
    const params = buildUrlHandler({
      ...qsParams,
      ...inputParams,
    });

    setStateParams(params);

    if (typeof onChangeParams === 'function') {
      onChangeParams(params);
    }
  };

  const outputData = data || previousData;
  const queryData = outputData;

  return children({
    loading,
    error,
    refetchListQuery,
    items: queryData && queryData.items,
    rawData: outputData,
    initialValues: { page },
    qsParams,
    setQsParams: setStateParams,
    handleParamsChange,
    pagination: queryData &&
      queryData.pagination && {
        ...queryData.pagination,
        buildPaginationUrl: buildUrlHandler,
        handleChangeRowsPerPage,
        handleChangePage,
      },
  });
};
