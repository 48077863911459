export const FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'name', type: 'string' },
  { columnField: 'slug', type: 'string' },
  { columnField: 'type', type: 'discrete', filterType: 'basic' },
  { columnField: 'status', type: 'discrete', filterType: 'basic' },
  { columnField: 'showcase', type: 'discrete', filterType: 'basic' },
];

export const SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'name' },
  { columnField: 'slug' },
  { columnField: 'type' },
  { columnField: 'status' },
  { columnField: 'showcase' },
];
