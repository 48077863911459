/**
 * Action Types
 */
export const AUTH_ACTIONS = {
  USER_LOGIN_DONE: 'USER_LOGIN_DONE',
  USER_LOGIN_LOADING: 'USER_LOGIN_LOADING',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
  USER_REGISTER_DONE: 'USER_REGISTER_DONE',
  USER_REGISTER_LOADING: 'USER_REGISTER_LOADING',
  USER_REGISTER_ERROR: 'USER_REGISTER_ERROR',
  TOKEN_FETCH: 'TOKEN_FETCH',
  TOKEN_FETCH_LOADING: 'TOKEN_FETCH_LOADING',
  TOKEN_FETCH_DONE: 'TOKEN_FETCH_DONE',
  TOKEN_FETCH_ERROR: 'TOKEN_FETCH_ERROR',
  TOKEN_UPDATED: 'TOKEN_UPDATED',
  TOKEN_CLEAR: 'TOKEN_CLEAR',
  SIGNOUT: 'SIGNOUT',
  AUTH_COOKIE_UPDATE: 'AUTH_COOKIE_UPDATE',
  AUTH_TOKEN_COOKIE_UPDATE: 'AUTH_TOKEN_COOKIE_UPDATE',
  AUTH_TOKEN_COOKIE_CLEAR: 'AUTH_TOKEN_COOKIE_CLEAR',
  AUTH_VISITOR_COOKIE_UPDATE: 'AUTH_VISITOR_COOKIE_UPDATE',
};

type ActionType = {
  type: string;
  payload: Record<string, any> | null;
};

/**
 *  Action Creators
 */
export function authTokenFetch(inputData = {}): ActionType {
  return {
    type: AUTH_ACTIONS.TOKEN_FETCH,
    payload: inputData,
  };
}

export function authTokenFetchLoading(): { type: string } {
  return {
    type: AUTH_ACTIONS.TOKEN_FETCH_LOADING,
  };
}

export function authTokenFetchDone(payload: Record<string, any>): ActionType {
  return {
    type: AUTH_ACTIONS.TOKEN_FETCH_DONE,
    payload,
  };
}

export function authTokenFetchError(error: Record<string, any>): ActionType {
  return {
    type: AUTH_ACTIONS.TOKEN_FETCH_ERROR,
    payload: { ...error },
  };
}

export function authTokenUpdated(payload: Record<string, any>): ActionType {
  return {
    type: AUTH_ACTIONS.TOKEN_UPDATED,
    payload,
  };
}

export function authCookieUpdate(payload: Record<string, any>): ActionType {
  return {
    type: AUTH_ACTIONS.AUTH_COOKIE_UPDATE,
    payload: { payload },
  };
}

export function authTokenClear(payload: Record<string, any>): ActionType {
  return {
    type: AUTH_ACTIONS.TOKEN_CLEAR,
    payload,
  };
}

export function authSignout(payload = null): ActionType {
  return {
    type: AUTH_ACTIONS.SIGNOUT,
    payload,
  };
}
