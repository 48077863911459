import { FormattedMessage } from 'react-intl';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { Country, Link, SplitButton } from '@riseart/dashboard';
import { seller as SELLER_ENUM } from '../../../../config/enumeration.js';
import { countries as COUNTRIES_LIST } from '../../../../config/directory.js';
import { DEFAULT_COLUMN_PROPS, dataPickerActionColumn } from '../utils';
import { filterSelectOptions, DISCRETE_TYPE_OPERATORS } from '../../../data/tables/filters/utils';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';
import { formatCurrency, parseFloatToLocaleString } from '../../../../services/riseart/utils/Utils';
import { Status } from '../../../common/Status';
import { SchemaType } from './types';

export const SELLERLIST_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.seller.id' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.seller.name' }),
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => {
            return (
              <Link to={UrlAssembler.sellerDetails(data.id)} title={data.name}>
                {data.name}
              </Link>
            );
          },
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'integrationId',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.magentoId' }),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'type',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.seller.type' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <FormattedMessage id={`components.seller.type.${data.type}`} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(SELLER_ENUM.type, 'components.seller.type', formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'shipsCountry',
    type: 'singleSelect',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.seller.shipsCountry' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element | null =>
      data.shipsCountryCode ? <Country code={data.shipsCountryCode} /> : null,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(COUNTRIES_LIST.all, 'countries', formatMessage),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'singleSelect',
    headerName: formatMessage({ id: 'components.tables.columns.seller.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="seller" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    // @ts-ignore
    valueOptions: filterSelectOptions(
      SELLER_ENUM.status,
      'components.seller.status',
      formatMessage,
    ),
  },
];

export const SELLERLIST_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage }) => [
  ...SELLERLIST_BASE_DATAGRID_TABLE_SCHEMA({ formatMessage }),
  dataPickerActionColumn(formatMessage),
];

export const SELLER_SHIPPING_TABLE_SCHEMA: SchemaType = [
  {
    key: 'name',
    render: ({ data, customData }: Record<string, any>) => (
      <Link to={UrlAssembler.shippingRates(customData.sellerId, data.id)} title={data.name}>
        {data.name}
      </Link>
    ),
  },
  {
    key: 'default',
    render: ({
      data: { id, default: isDefault },
      customData,
      refetchListQuery,
    }: Record<string, any>): JSX.Element | null => (
      <Switch
        disabled={isDefault}
        checked={isDefault}
        onChange={(e: Record<string, any>) => {
          customData &&
            customData
              .updateItem({ resourceId: id, parameters: { default: e.target.checked } })
              .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
              .catch(() => null);
        }}
      />
    ),
  },
  {
    key: 'currency',
    render: ({ data }: Record<string, any>) => data.currency,
  },
  {
    key: 'inheritsFrom',
    render: ({ data }: Record<string, any>) => (data.default ? null : data.parentName),
  },
  {
    key: 'actions',
    render: ({
      data: { id, default: isDefault },
      handleToggleMode,
      customData: { deleteMutation, refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: <FormattedMessage id="common.edit" />,
        }}
        translationPrefix="components.dialog.seller.action"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={
          !isDefault
            ? [
                {
                  actionKey: 'delete',
                  requireConfirmation: true,
                  text: <FormattedMessage id="common.delete" />,
                  onClick: () => {
                    deleteMutation({ resourceId: id })
                      .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                      .catch(() => null);
                  },
                },
              ]
            : []
        }
      />
    ),
  },
];

export const SELLER_SHIPPING_TABLE_RATES_SCHEMA: SchemaType = [
  {
    key: 'destination',
    render: ({ data }) =>
      data.regionCode || data.countryCode ? (
        <>
          <FormattedMessage
            id={data.regionCode ? `regions.${data.regionCode}` : `countries.${data.countryCode}`}
          />
          <br />
          <Typography component="div" sx={{ fontSize: '13px', color: '#9e9e9e' }}>
            <FormattedMessage id={`common.${data.regionCode ? 'region' : 'country'}`} />
            {data.inherited ? (
              <Typography component="span" sx={{ fontStyle: 'italic', fontSize: 'inherit' }}>
                {' '}
                &#8212; <FormattedMessage id={`components.seller.inherited`} />
              </Typography>
            ) : null}
          </Typography>
        </>
      ) : null,
  },
  {
    key: 'baseCost',
    render: ({ data, customData }) => formatCurrency(data.baseCost, customData.storeCode),
  },
  {
    key: 'pctCost',
    render: ({ data }) => <>{parseFloatToLocaleString(data.pctCost)}%</>,
  },
  {
    key: 'actions',
    render: ({
      data,
      handleToggleMode,
      customData,
      deleteLoading,
    }: Record<string, any>): JSX.Element => {
      const handleClick = handleToggleMode(true);
      if (data.inherited) {
        return (
          <Button variant="contained" onClick={handleClick}>
            <FormattedMessage id="common.override" />
          </Button>
        );
      }
      return (
        <SplitButton
          disabled={deleteLoading}
          mainButtonProps={{
            onClick: handleClick,
            children: <FormattedMessage id="common.edit" />,
          }}
          translationPrefix="components.dialog.sellerRate.action"
          confirmationDialogProps={splitButtonConfirmationProps}
          options={[
            {
              actionKey: 'deleteRate',
              requireConfirmation: true,
              text: <FormattedMessage id="common.delete" />,
              onClick: () => {
                customData
                  .deleteRate({ resourceId: data.id })
                  .then(() => {
                    customData.refetchListQuery && customData.refetchListQuery();
                  })
                  .catch(() => null);
              },
            },
          ]}
        />
      );
    },
  },
];
