import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicationStoreUpdateLocales } from '../../services/redux/actions/application/store';
import { application as APP_CONFIG } from '../../config/config.js';
import { selectUser } from '../../services/redux/selectors/user';
import {
  getLocale,
  getLocaleConfig,
  extractLocaleFromUrl,
} from '../../services/riseart/utils/Route';

type Props = {
  isSSR: boolean;
  urlLanguage: string | boolean | undefined;
  location: Record<string, any>;
  children: (data: {
    userLocale: Record<string, any> | null;
    routeLocale: Record<string, any>;
  }) => JSX.Element;
};

/**
 * RouterLocale
 *
 * Determines the locale based on url and user locales (or falls back to default)
 * and dispatches an event to store the locale in redux store
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const RouterLocale = ({ isSSR, urlLanguage, location, children }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const routeLocale =
    // @ts-ignore
    getLocaleConfig(urlLanguage, 'basePath') ||
    extractLocaleFromUrl(location && location.pathname) ||
    getLocaleConfig(true, 'isDefault');
  const userLocale = getLocale(urlLanguage, user && user.locale, APP_CONFIG.i18n.locales);

  // useEffect is only executed on client
  useEffect(() => {
    dispatch(applicationStoreUpdateLocales({ userLocale, routeLocale }));
  }, [dispatch, userLocale, routeLocale]);

  // called on server to workaround the useEffect not called on server issue
  if (isSSR) {
    dispatch(applicationStoreUpdateLocales({ userLocale, routeLocale }));
  }

  return children({ userLocale, routeLocale });
};
