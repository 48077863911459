// @ts-ignore
import { v4 } from 'uuid';
import { errors as ERRORS_ENUM } from '../../../../config/enumeration.js';
import { NOTIFICATION_ACTIONS } from '../../actions/container';

/**
 * Application messages reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function notificationsReducer(
  state: Record<string, any>[] = [],
  action: Record<string, any> = {},
): Record<string, any>[] {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.NOTIFICATION_ADD: {
      let msgType = 'error';

      if (action.payload.level >= ERRORS_ENUM.levels.SUCCESS) {
        msgType = 'success';
      } else if (action.payload.level >= ERRORS_ENUM.levels.INFO) {
        msgType = 'info';
      } else if (action.payload.level >= ERRORS_ENUM.levels.WARNING) {
        msgType = 'warning';
      } else if (action.payload.level >= ERRORS_ENUM.levels.ERROR) {
        msgType = 'error';
      }

      return [
        ...state,
        {
          ...action.payload,
          id: v4(),
          type: msgType,
          placeholder: action.payload.placeholder || ERRORS_ENUM.placeholders.APP,
        },
      ];
    }
    case NOTIFICATION_ACTIONS.NOTIFICATION_REMOVE:
      return state.filter(({ id }) => id !== action.payload.id);
    case NOTIFICATION_ACTIONS.NOTIFICATION_TYPE_REMOVE:
      return state.filter(({ type }) => type !== action.payload.type);
    default:
      return state;
  }
}
