export const FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'name', type: 'string' },
  { columnField: 'slug', type: 'string' },
  { columnField: 'status', type: 'discrete', filterType: 'basic' },
  { columnField: 'hide', apiField: 'hide', type: 'discrete', filterType: 'basic' },
  { columnField: 'locales', apiField: 'locale', type: 'discrete' },
];

export const SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'name' },
  { columnField: 'slug' },
  { columnField: 'status' },
  { columnField: 'hide' },
];
