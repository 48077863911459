import { store as CONFIG_STORE } from '../../../config/config.js';

/**
 * selectMainMenuState
 *
 * @param {Record<string, any>} state
 * @returns boolean
 */
export function selectMainMenuState(state: Record<string, any>): boolean | null {
  const gui = state[CONFIG_STORE.keys.gui];

  return gui && [true, false].indexOf(gui.menuOpen) > -1 ? gui.menuOpen : null;
}

/**
 * selectStore
 *
 * @param {Record<string, any>} state
 * @returns string
 */
export function selectStore(state: Record<string, any>): string {
  const gui = state[CONFIG_STORE.keys.gui];
  return gui && gui.store;
}

/**
 * selectLocale
 *
 * @param {Record<string, any>} state
 * @returns {string}
 */
export function selectLocale(state: Record<string, any>): string {
  const gui = state[CONFIG_STORE.keys.gui];
  return gui && gui.locale;
}
