import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import Switch from '@mui/material/Switch';
import { Link } from '@riseart/dashboard';
import { cms as CMS_ENUM } from '../../../../config/enumeration.js';
import { SchemaType } from './types';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';
import { DEFAULT_COLUMN_PROPS, dataPickerActionColumn, renderLocalesList } from '../utils';
import {
  filterSelectOptions,
  filterSelectBoolean,
  filterSelectLocale,
  DISCRETE_TYPE_OPERATORS,
} from '../../../data/tables/filters/utils';
import { delay } from '../../../../services/riseart/utils/Utils';
import { Status } from '../../../common/Status';
import { SplitButton } from '@riseart/dashboard';
import { splitButtonConfirmationProps } from '../../../common/buttons/settings';

export const CMSMODULE_INSTANCE_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink, updateRequest, refetchListQuery }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    sortable: true,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
            <Link to={UrlAssembler.cmsModuleInstanceDetails(data.id)} title={data.id}>
              {data.name}
            </Link>
          ),
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'string',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.common.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="cmsModuleInstance" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      CMS_ENUM.module.instance.status,
      'components.cms.module.instance.status',
      formatMessage,
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'cache',
    type: 'singleSelect',
    filterable: true,
    sortable: true,
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    // @ts-ignore
    valueOptions: filterSelectBoolean(formatMessage),
    headerName: formatMessage({ id: 'components.tables.columns.cms.cache' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
      <Switch
        checked={data.cache}
        onChange={(e: Record<string, any>) => {
          updateRequest({
            resourceId: data.id,
            parameters: { cache: e.target.checked },
          })
            .then(() => delay(refetchListQuery))
            .catch(() => null);
        }}
      />
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'moduleId',
    type: 'string',
    minWidth: 200,
    flex: 1,
    filterable: false,
    sortable: false,
    headerName: formatMessage({ id: 'components.tables.columns.cms.module' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      showNameAsLink ? (
        <Link to={UrlAssembler.cmsModuleDetails(data.module.id)} title={data.module.name}>
          {data.module.name}
        </Link>
      ) : (
        data.module.name
      ),
  },
];

export const CMSMODULE_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({
  formatMessage,
  readOnly = true,
  showNameAsLink,
  updateRequest,
  refetchListQuery,
}) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    sortable: true,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
            <Link to={UrlAssembler.cmsModuleDetails(data.id)} title={data.id}>
              {data.name}
            </Link>
          ),
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'string',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.common.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="cmsModule" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      CMS_ENUM.module.status,
      'components.cms.module.status',
      formatMessage,
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'cache',
    type: 'singleSelect',
    filterable: true,
    sortable: true,
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    // @ts-ignore
    valueOptions: filterSelectBoolean(formatMessage),
    headerName: formatMessage({ id: 'components.tables.columns.cms.cache' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
      <Switch
        disabled={readOnly}
        checked={data.cache}
        onChange={(e: Record<string, any>) => {
          updateRequest({
            resourceId: data.id,
            parameters: { cache: e.target.checked },
          })
            .then(() => delay(refetchListQuery))
            .catch(() => null);
        }}
      />
    ),
  },
  {
    field: 'description',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.common.description' }),
    filterable: false,
    sortable: false,
  },
  {
    field: 'zendModuleName',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.cms.zendModuleName' }),
    filterable: true,
    sortable: true,
  },
  {
    field: 'zendControllerName',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.cms.zendControllerName' }),
    filterable: true,
    sortable: true,
  },
  {
    field: 'zendActionName',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.cms.zendActionName' }),
    filterable: true,
    sortable: true,
  },
];

export const CMSMODULE_PICKER_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (properties) => [
  ...CMSMODULE_BASE_DATAGRID_TABLE_SCHEMA(properties),
  dataPickerActionColumn(properties.formatMessage),
];

export const CMSMODULE_INSTANCE_PICKER_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (properties) => [
  ...CMSMODULE_INSTANCE_BASE_DATAGRID_TABLE_SCHEMA(properties),
  dataPickerActionColumn(properties.formatMessage),
];

export const CMSATTRIBUTE_INSTANCE_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => SchemaType = ({ formatMessage, deleteRequest }) => [
  { key: 'id' },
  { key: 'name' },
  {
    key: 'type',
    render: ({ data }: Record<string, any>): JSX.Element => {
      return formatMessage({ id: `components.cms.attribute.type.${data.type}` });
    },
  },
  {
    key: 'value',
    render: ({ data }: Record<string, any>): string => {
      return JSON.stringify(data.value);
    },
  },
  {
    key: 'required',
    render: ({ data }: Record<string, any>): JSX.Element => {
      return formatMessage({ id: data.required ? 'common.yes' : 'common.no' });
    },
  },
  {
    key: 'localizable',
    render: ({ data }: Record<string, any>): JSX.Element => {
      return formatMessage({ id: data.localizable ? 'common.yes' : 'common.no' });
    },
  },
  {
    key: 'actions',
    render: ({
      data: { id, required },
      handleToggleMode,
      customData: { refetchListQuery },
      deleteLoading,
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: formatMessage({ id: 'common.edit' }),
        }}
        translationPrefix="components.dialog.cms.attributeInstance.delete"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          ...(!required
            ? [
                {
                  actionKey: 'delete',
                  requireConfirmation: true,
                  text: formatMessage({ id: 'common.delete' }),
                  onClick: () => {
                    deleteRequest({ resourceId: id })
                      .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                      .catch(() => null);
                  },
                },
              ]
            : []),
        ]}
      />
    ),
  },
];

export const CMSATTRIBUTE_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({
  formatMessage,
  readOnly = true,
  showNameAsLink,
  updateRequest,
  refetchListQuery,
}) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    sortable: true,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
            <Link to={UrlAssembler.cmsAttributeDetails(data.id)} title={data.id}>
              {data.name}
            </Link>
          ),
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'type',
    type: 'string',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.common.type' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      formatMessage({ id: `components.cms.attribute.type.${data.type}` }),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      CMS_ENUM.attribute.type,
      'components.cms.attribute.type',
      formatMessage,
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'required',
    type: 'singleSelect',
    filterable: true,
    sortable: true,
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    // @ts-ignore
    valueOptions: filterSelectBoolean(formatMessage),
    headerName: formatMessage({ id: 'components.tables.columns.cms.required' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
      <Switch
        disabled={readOnly}
        checked={data.required}
        onChange={(e: Record<string, any>) => {
          updateRequest({
            resourceId: data.id,
            parameters: { required: e.target.checked },
          })
            .then(() => delay(refetchListQuery))
            .catch(() => null);
        }}
      />
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'localizable',
    type: 'singleSelect',
    filterable: true,
    sortable: true,
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    // @ts-ignore
    valueOptions: filterSelectBoolean(formatMessage),
    headerName: formatMessage({ id: 'components.tables.columns.cms.localizable' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
      <Switch
        disabled={readOnly}
        checked={data.localizable}
        onChange={(e: Record<string, any>) => {
          updateRequest({
            resourceId: data.id,
            parameters: { localizable: e.target.checked },
          })
            .then(() => delay(refetchListQuery))
            .catch(() => null);
        }}
      />
    ),
  },
];

export const CMSATTRIBUTE_PICKER_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (properties) => [
  ...CMSATTRIBUTE_BASE_DATAGRID_TABLE_SCHEMA(properties),
  dataPickerActionColumn(properties.formatMessage),
];

export const CMSVIEW_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({
  formatMessage,
  readOnly = true,
  showNameAsLink,
  updateRequest,
  refetchListQuery,
}) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    sortable: true,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
            <Link to={UrlAssembler.cmsViewDetails(data.id)} title={data.id}>
              {data.name}
            </Link>
          ),
        }
      : null),
  },
  {
    field: 'description',
    type: 'string',
    minWidth: 300,
    headerName: formatMessage({ id: 'components.tables.columns.common.description' }),
    filterable: false,
    sortable: false,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'disable',
    type: 'singleSelect',
    filterable: true,
    sortable: true,
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    // @ts-ignore
    valueOptions: filterSelectBoolean(formatMessage),
    headerName: formatMessage({ id: 'components.tables.columns.cms.disable' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
      <Switch
        disabled={readOnly}
        checked={data.cache}
        onChange={(e: Record<string, any>) => {
          updateRequest({
            resourceId: data.id,
            parameters: { disable: e.target.checked },
          })
            .then(() => delay(refetchListQuery))
            .catch(() => null);
        }}
      />
    ),
  },
  {
    field: 'zendModuleName',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.cms.zendModuleName' }),
    filterable: true,
    sortable: true,
  },
  {
    field: 'zendControllerName',
    type: 'string',
    minWidth: 120,
    headerName: formatMessage({ id: 'components.tables.columns.cms.zendControllerName' }),
    filterable: true,
    sortable: true,
  },
  {
    field: 'zendActionName',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.cms.zendActionName' }),
    filterable: true,
    sortable: true,
  },
];

export const CMSVIEW_PLACEHOLDER_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => SchemaType = ({
  formatMessage,
  readOnly = true,
  updateRequest,
  refetchListQuery,
  deleteRequest,
  deleteLoading,
}) => [
  { key: 'id' },
  { key: 'key' },
  { key: 'name' },
  {
    key: 'module',
    render: ({ data: { moduleInstance } }: Record<string, any>): JSX.Element => {
      return readOnly ? (
        moduleInstance.name
      ) : (
        <Link
          to={UrlAssembler.cmsModuleInstanceDetails(moduleInstance.id)}
          title={moduleInstance.name}
        >
          {moduleInstance.name}
        </Link>
      );
    },
  },
  { key: 'description' },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { refetchListQuery },
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: formatMessage({ id: 'common.edit' }),
        }}
        translationPrefix="components.dialog.cms.viewPlaceholder.delete"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: formatMessage({ id: 'common.delete' }),
            onClick: () => {
              deleteRequest({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
  {
    field: 'description',
    type: 'string',
    minWidth: 300,
    headerName: formatMessage({ id: 'components.tables.columns.common.description' }),
    filterable: false,
    sortable: false,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'disable',
    type: 'singleSelect',
    filterable: true,
    sortable: true,
    filterOperators: DISCRETE_TYPE_OPERATORS.filter(({ value }) => value === 'is'),
    // @ts-ignore
    valueOptions: filterSelectBoolean(formatMessage),
    headerName: formatMessage({ id: 'components.tables.columns.cms.disable' }),
    renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
      <Switch
        disabled={readOnly}
        checked={data.cache}
        onChange={(e: Record<string, any>) => {
          updateRequest({
            resourceId: data.id,
            parameters: { disable: e.target.checked },
          })
            .then(() => delay(refetchListQuery))
            .catch(() => null);
        }}
      />
    ),
  },
  {
    field: 'zendModuleName',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.cms.zendModuleName' }),
    filterable: true,
    sortable: true,
  },
  {
    field: 'zendControllerName',
    type: 'string',
    minWidth: 120,
    headerName: formatMessage({ id: 'components.tables.columns.cms.zendControllerName' }),
    filterable: true,
    sortable: true,
  },
  {
    field: 'zendActionName',
    type: 'string',
    headerName: formatMessage({ id: 'components.tables.columns.cms.zendActionName' }),
    filterable: true,
    sortable: true,
  },
];

export const CMSPAGE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    sortable: true,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
            <Link to={UrlAssembler.cmsPageDetails(data.id)} title={data.id}>
              {data.name}
            </Link>
          ),
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'string',
    width: 100,
    headerName: formatMessage({ id: 'components.tables.columns.common.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="cmsPage" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      CMS_ENUM.page.status,
      'components.cms.page.status',
      formatMessage,
    ),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'locales',
    type: 'string',
    flex: 1,
    width: 10,
    sortable: false,
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectLocale(formatMessage),
    headerName: formatMessage({ id: 'components.tables.columns.common.inLocales' }),
    renderCell: ({ row: data }: Record<string, any>) => renderLocalesList({ data }),
  },
  {
    field: 'uri',
    type: 'string',
    minWidth: 200,
    headerName: formatMessage({ id: 'components.tables.columns.common.uri' }),
    filterable: true,
    sortable: true,
  },
  {
    field: 'description',
    type: 'string',
    minWidth: 300,
    headerName: formatMessage({ id: 'components.tables.columns.common.description' }),
    filterable: false,
    sortable: false,
  },
];

export const CMSLAYOUT_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    sortable: true,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
    ...(showNameAsLink
      ? {
          renderCell: ({ row: data }: Record<string, any>): JSX.Element => (
            <Link to={UrlAssembler.cmsModuleDetails(data.id)} title={data.id}>
              {data.name}
            </Link>
          ),
        }
      : null),
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'status',
    type: 'string',
    width: 150,
    headerName: formatMessage({ id: 'components.tables.columns.common.status' }),
    renderCell: ({ row: data }: Record<string, any>) => (
      <Status type="cmsModule" status={data.status} />
    ),
    filterOperators: DISCRETE_TYPE_OPERATORS,
    valueOptions: filterSelectOptions(
      CMS_ENUM.module.status,
      'components.cms.module.status',
      formatMessage,
    ),
  },
  {
    field: 'code',
    type: 'string',
    minWidth: 200,
    headerName: formatMessage({ id: 'components.tables.columns.cms.code' }),
    filterable: false,
    sortable: false,
  },
  {
    field: 'description',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.description' }),
    filterable: false,
    sortable: false,
  },
];

export const CMSLAYOUT_PICKER_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (properties) => [
  ...CMSLAYOUT_BASE_DATAGRID_TABLE_SCHEMA(properties),
  dataPickerActionColumn(properties.formatMessage),
];

export const CMSLAYOUT_PLACEHOLDER_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    sortable: true,
  },
  {
    field: 'name',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    filterable: true,
    sortable: true,
  },
  {
    field: 'key',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.cms.key' }),
    filterable: true,
    sortable: true,
  },
  {
    field: 'description',
    type: 'string',
    flex: 2,
    headerName: formatMessage({ id: 'components.tables.columns.common.description' }),
    filterable: false,
    sortable: false,
  },
];

export const CMSLAYOUT_PLACEHOLDER_PICKER_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = (properties) => [
  ...CMSLAYOUT_PLACEHOLDER_BASE_DATAGRID_TABLE_SCHEMA(properties),
  dataPickerActionColumn(properties.formatMessage),
];

export const CMSPAGE_PLACEHOLDER_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => SchemaType = ({ formatMessage, readOnly = true, deleteRequest, deleteLoading }) => [
  { key: 'id' },
  {
    key: 'name',
    render: ({ data: { placeholder } }: Record<string, any>): string => placeholder.name,
  },
  {
    key: 'key',
    render: ({ data: { placeholder } }: Record<string, any>): string => placeholder.key,
  },
  {
    key: 'module',
    render: ({ data: { moduleInstance } }: Record<string, any>): JSX.Element => {
      return readOnly ? (
        moduleInstance.name
      ) : (
        <Link
          to={UrlAssembler.cmsModuleInstanceDetails(moduleInstance.id)}
          title={moduleInstance.name}
        >
          {moduleInstance.name}
        </Link>
      );
    },
  },
  {
    key: 'description',
    render: ({ data: { placeholder } }: Record<string, any>): string => placeholder.description,
  },
  {
    key: 'actions',
    render: ({
      data: { id },
      handleToggleMode,
      customData: { refetchListQuery },
    }: Record<string, any>): JSX.Element => (
      <SplitButton
        disabled={deleteLoading}
        mainButtonProps={{
          onClick: handleToggleMode(true),
          children: formatMessage({ id: 'common.edit' }),
        }}
        translationPrefix="components.dialog.cms.pagePlaceholder.delete"
        confirmationDialogProps={splitButtonConfirmationProps}
        options={[
          {
            actionKey: 'delete',
            requireConfirmation: true,
            text: formatMessage({ id: 'common.delete' }),
            onClick: () => {
              deleteRequest({ resourceId: id })
                .then(() => typeof refetchListQuery === 'function' && refetchListQuery())
                .catch(() => null);
            },
          },
        ]}
      />
    ),
  },
];
