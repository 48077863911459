import { api as API_CONFIG } from '../../config/config.js';
import { art as ART_ENUM } from '../../config/enumeration.js';
import { Art as ArtModel } from '../../data/models/Art';
import { Author as AuthorModel } from '../../data/models/Author';
import { Artist as ArtistModel } from '../../data/models/Artist';
import { Article as ArticleModel } from '../../data/models/Article';
import { ArticleCategory as ArticleCategoryModel } from '../../data/models/Article';
import { Collection as CollectionModel } from '../../data/models/Collection';
import { Event as EventModel } from '../../data/models/Event';
import { Partner as PartnerModel } from '../../data/models/Partner';
import { Seller as SellerModel } from '../../data/models/Seller';
import { User as UserModel } from '../../data/models/User';
import { Admin as AdminModel } from '../../data/models/Admin';
import {
  CmsModule as CmsModuleModel,
  CmsAttribute as CmsAttributeModel,
  CmsLayout as CmsLayoutModel,
  CmsLayoutPlaceholder as CmsLayoutPlaceholderModel,
} from '../../data/models/Cms';
import * as ADMIN_CONFIG from '../data/tables/filters/config/admin';
import * as ART_CONFIG from '../data/tables/filters/config/art';
import * as AUTHOR_CONFIG from '../data/tables/filters/config/author';
import * as ARTICLE_CONFIG from '../data/tables/filters/config/article';
import * as ARTIST_CONFIG from '../data/tables/filters/config/artist';
import * as COLLECTION_CONFIG from '../data/tables/filters/config/collection';
import * as EVENT_CONFIG from '../data/tables/filters/config/event';
import * as PARTNER_CONFIG from '../data/tables/filters/config/partner';
import * as SELLER_CONFIG from '../data/tables/filters/config/seller';
import * as USER_FILTER_CONFIG from '../data/tables/filters/config/user';
import * as CMS_FILTER_CONFIG from '../data/tables/filters/config/cms';
import { ART_DATAGRID_TABLE_SCHEMA } from '../data/tables/schemas/art';
import { ADMIN_PICKER_TABLE_SCHEMA } from '../data/tables/schemas/admin';
import { AUTHOR_DATAGRID_TABLE_SCHEMA } from '../data/tables/schemas/author';
import {
  ARTICLE_DATAPICKER_TABLE_SCHEMA,
  ARTICLE_CATEGORY_DATAPICKER_TABLE_SCHEMA,
} from '../data/tables/schemas/article';
import {
  CMSMODULE_PICKER_DATAGRID_TABLE_SCHEMA,
  CMSATTRIBUTE_PICKER_DATAGRID_TABLE_SCHEMA,
  CMSMODULE_INSTANCE_PICKER_DATAGRID_TABLE_SCHEMA,
  CMSLAYOUT_PICKER_DATAGRID_TABLE_SCHEMA,
  CMSLAYOUT_PLACEHOLDER_PICKER_DATAGRID_TABLE_SCHEMA,
} from '../data/tables/schemas/cms';
import { ARTISTLIST_DATAGRID_TABLE_SCHEMA } from '../data/tables/schemas/artist';
import { PARTNERLIST_DATAGRID_TABLE_SCHEMA } from '../data/tables/schemas/partner';
import { SELLERLIST_DATAGRID_TABLE_SCHEMA } from '../data/tables/schemas/seller';
import { USERLIST_DATAGRID_TABLE_SCHEMA } from '../data/tables/schemas/user';
import { COLLECTIONLIST_PICKER_DATAGRID_TABLE_SCHEMA } from '../data/tables/schemas/collection';
import { EVENTLIST_PICKER_DATAGRID_TABLE_SCHEMA } from '../data/tables/schemas/event';
import { DataPickerGrid } from '../common/data/PickerGrid';

/**
 * ArtPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function ArtPicker({
  art: initialValue,
  endpointParams = null,
  storeCode,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
  initialState,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      showSearch
      select={{
        name: 'state',
        defaultValue: ART_ENUM.state.ALL,
        initialValue: ART_ENUM.state.ALL,
        items: Object.keys(ART_ENUM.state).reduce(
          (accumulator, key) =>
            // @ts-ignore
            key !== 'ART' ? { ...accumulator, [key]: ART_ENUM.state[key] } : accumulator,
          {},
        ),
      }}
      initialValue={initialValue}
      initialState={initialState}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectArt' })}
      onChange={onChange}
      onSave={onSave}
      gridProps={(pickerProps) => ({
        controlled: true,
        itemMapper: (item: Record<string, any>) =>
          new ArtModel().hydrateFromApiData(item).getStoreData(storeCode),
        endpoint: API_CONFIG.endpoints.arts,
        endpointParams: { ...endpointParams, store: storeCode },
        filterConfig: ART_CONFIG.FILTER_CONFIG,
        sortConfig: ART_CONFIG.SORT_CONFIG,
        schema: ART_DATAGRID_TABLE_SCHEMA({
          formatMessage,
          filterSelectedItems: multiple,
          pickerProps,
        }),
      })}
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * ArticlePicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function ArticlePicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectArticle' })}
      onChange={onChange}
      onSave={onSave}
      clearOnSave={multiple && typeof children === 'function'}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new ArticleModel().hydrateFromApiData(item),
        endpoint: API_CONFIG.endpoints.articles,
        endpointParams,
        filterConfig: ARTICLE_CONFIG.FILTER_CONFIG,
        sortConfig: ARTICLE_CONFIG.SORT_CONFIG,
        schema: ARTICLE_DATAPICKER_TABLE_SCHEMA({ formatMessage, showTitleAsLink: false }),
      }}
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * ArtistPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function ArtistPicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
  chipColor,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectArtist' })}
      chipColor={chipColor}
      chipValueField="name"
      onChange={onChange}
      onSave={onSave}
      clearOnSave={multiple && typeof children === 'function'}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new ArtistModel().hydrateFromApiData(item),
        endpoint: API_CONFIG.endpoints.artists,
        endpointParams,
        filterConfig: ARTIST_CONFIG.FILTER_CONFIG,
        sortConfig: ARTIST_CONFIG.SORT_CONFIG,
        schema: ARTISTLIST_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * AuthorPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function AuthorPicker({
  author: initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectAuthor' })}
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new AuthorModel().hydrateFromApiData(item),
        endpoint: API_CONFIG.endpoints.authors,
        endpointParams,
        filterConfig: AUTHOR_CONFIG.FILTER_CONFIG,
        sortConfig: AUTHOR_CONFIG.SORT_CONFIG,
        schema: AUTHOR_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
      chipValueField="fullName"
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * PartnerPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function PartnerPicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
  chipColor,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectPartner' })}
      chipColor={chipColor}
      chipValueField="name"
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new PartnerModel().hydrateFromApiData(item),
        endpoint: API_CONFIG.endpoints.partners,
        endpointParams,
        filterConfig: PARTNER_CONFIG.FILTER_CONFIG,
        sortConfig: PARTNER_CONFIG.SORT_CONFIG,
        schema: PARTNERLIST_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * SellerPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function SellerPicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
  chipColor,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectSeller' })}
      chipColor={chipColor}
      chipValueField="name"
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new SellerModel().hydrateFromApiData(item),
        endpoint: API_CONFIG.endpoints.sellers,
        endpointParams,
        filterConfig: SELLER_CONFIG.FILTER_CONFIG,
        sortConfig: SELLER_CONFIG.SORT_CONFIG,
        schema: SELLERLIST_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * UserPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function UserPicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
  chipColor,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectUser' })}
      chipColor={chipColor}
      chipValueField="fullName"
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new UserModel().hydrateFromApiData(item),
        endpoint: API_CONFIG.endpoints.users,
        endpointParams,
        filterConfig: USER_FILTER_CONFIG.FILTER_CONFIG,
        sortConfig: USER_FILTER_CONFIG.SORT_CONFIG,
        schema: USERLIST_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * ArticleCategoryPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function ArticleCategoryPicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
  chipColor,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectCategory' })}
      chipColor={chipColor}
      chipValueField="name"
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) =>
          new ArticleCategoryModel().hydrateFromApiData(item),
        endpoint: `${API_CONFIG.endpoints.articles}/categories`,
        endpointParams,
        filterConfig: ARTICLE_CONFIG.FILTER_CATEGORY_CONFIG,
        sortConfig: ARTICLE_CONFIG.SORT_CATEGORY_CONFIG,
        schema: ARTICLE_CATEGORY_DATAPICKER_TABLE_SCHEMA({ formatMessage, showTitleAsLink: false }),
      }}
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * CollectionPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function CollectionPicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
  chipColor,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectCollection' })}
      chipColor={chipColor}
      chipValueField="name"
      onChange={onChange}
      onSave={onSave}
      clearOnSave={multiple && typeof children === 'function'}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new CollectionModel().hydrateFromApiData(item),
        endpoint: API_CONFIG.endpoints.collections,
        endpointParams,
        filterConfig: COLLECTION_CONFIG.FILTER_CONFIG,
        sortConfig: COLLECTION_CONFIG.SORT_CONFIG,
        schema: COLLECTIONLIST_PICKER_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * EventPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function EventPicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
  chipColor,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectEvent' })}
      chipColor={chipColor}
      chipValueField="name"
      onChange={onChange}
      onSave={onSave}
      clearOnSave={multiple && typeof children === 'function'}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new EventModel().hydrateFromApiData(item),
        endpoint: API_CONFIG.endpoints.events,
        endpointParams,
        filterConfig: EVENT_CONFIG.FILTER_CONFIG,
        sortConfig: EVENT_CONFIG.SORT_CONFIG,
        schema: EVENTLIST_PICKER_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * AdminPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function AdminPicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectAdmin' })}
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new AdminModel().hydrate(item),
        endpoint: API_CONFIG.endpoints.admins,
        endpointParams,
        filterConfig: ADMIN_CONFIG.FILTER_CONFIG,
        sortConfig: ADMIN_CONFIG.SORT_CONFIG,
        schema: ADMIN_PICKER_TABLE_SCHEMA({ formatMessage }),
      }}
      chipValueField="name"
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * CmsModulePicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function CmsModulePicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectCmsModule' })}
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new CmsModuleModel().hydrate(item),
        endpoint: API_CONFIG.endpoints.cmsModules,
        endpointParams,
        filterConfig: CMS_FILTER_CONFIG.MODULE_FILTER_CONFIG,
        sortConfig: CMS_FILTER_CONFIG.MODULE_SORT_CONFIG,
        schema: CMSMODULE_PICKER_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
      chipValueField="name"
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * CmsModuleInstancePicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function CmsModuleInstancePicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectCmsModule' })}
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new CmsModuleModel().hydrate(item),
        endpoint: API_CONFIG.endpoints.cmsModuleInstance,
        endpointParams,
        filterConfig: CMS_FILTER_CONFIG.MODULE_FILTER_CONFIG,
        sortConfig: CMS_FILTER_CONFIG.MODULE_SORT_CONFIG,
        schema: CMSMODULE_INSTANCE_PICKER_DATAGRID_TABLE_SCHEMA({
          formatMessage,
          showNameAsLink: false,
        }),
      }}
      chipValueField="name"
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * CmsAttributePicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function CmsAttributePicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectCmsAttribute' })}
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new CmsAttributeModel().hydrate(item),
        endpoint: API_CONFIG.endpoints.cmsAttributes,
        endpointParams,
        filterConfig: CMS_FILTER_CONFIG.ATTRIBUTE_FILTER_CONFIG,
        sortConfig: CMS_FILTER_CONFIG.ATTRIBUTE_SORT_CONFIG,
        schema: CMSATTRIBUTE_PICKER_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
      chipValueField="name"
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * CmsLayoutPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function CmsLayoutPicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectCmsLayout' })}
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new CmsLayoutModel().hydrate(item),
        endpoint: API_CONFIG.endpoints.cmsLayouts,
        endpointParams,
        filterConfig: CMS_FILTER_CONFIG.LAYOUT_FILTER_CONFIG,
        sortConfig: CMS_FILTER_CONFIG.LAYOUT_SORT_CONFIG,
        schema: CMSLAYOUT_PICKER_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
      chipValueField="name"
    >
      {children}
    </DataPickerGrid>
  );
}

/**
 * CmsLayoutPlaceholderPicker
 *
 * @param {Record<string, any>} Props
 * @returns {JSX.Element}
 */
export function CmsLayoutPlaceholderPicker({
  initialValue,
  endpointParams = null,
  onChange,
  multiple = false,
  onSave,
  children,
  formatMessage,
}: Record<string, any>): JSX.Element {
  return (
    <DataPickerGrid
      initialValue={initialValue}
      multiple={multiple}
      selectLabel={formatMessage({ id: 'components.common.selectCmsLayoutPlaceholder' })}
      onChange={onChange}
      onSave={onSave}
      gridProps={{
        itemMapper: (item: Record<string, any>) => new CmsLayoutPlaceholderModel().hydrate(item),
        endpoint: API_CONFIG.endpoints.cmsLayoutsPlaceholders,
        endpointParams,
        filterConfig: CMS_FILTER_CONFIG.LAYOUT_PLACEHOLDER_FILTER_CONFIG,
        sortConfig: CMS_FILTER_CONFIG.LAYOUT_PLACEHOLDER_SORT_CONFIG,
        schema: CMSLAYOUT_PLACEHOLDER_PICKER_DATAGRID_TABLE_SCHEMA({ formatMessage }),
      }}
      chipValueField="name"
    >
      {children}
    </DataPickerGrid>
  );
}
