import React, { Component } from 'react';
import last from 'lodash/last';
import { connect } from 'react-redux';
import { ErrorPage } from '../../components/pages/error/Error';
import { errors as ERRORS_ENUM } from '../../config/enumeration.js';
import { store as CONFIG_STORE } from '../../config/config.js';

type Props = {
  errors?: Array<any>;
  children: any;
};

type State = {
  showError: boolean;
  lastError: any;
};

/**
 * ErrorRenderer
 */
class ErrorRenderer extends Component<Props, State> {
  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      showError: false,
      lastError: null,
    };
  }

  /**
   * getDerivedStateFromProps
   *
   * @param {Props} props
   * @param {State} state
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    const { errors = [] } = props;
    const lastError = last(errors);

    if (
      !lastError ||
      (!state.showError && state.lastError === null && !lastError) ||
      (state.lastError && lastError && state.lastError.uid === lastError.uid)
    ) {
      return null;
    }

    return {
      showError: true,
      lastError,
    };
  }

  /**
   * shouldComponentUpdate
   */
  shouldComponentUpdate() {
    return !this.state.showError;
  }

  /**
   * render
   */
  render() {
    return !this.state.showError ? (
      this.props.children
    ) : (
      <ErrorPage error={this.state.lastError} showLogo />
    );
  }
}

const mapStateToProps = (state: Record<string, any>) => ({
  errors: state[CONFIG_STORE.keys.errors].data.filter(
    (i: Record<string, any>) => i.handler === ERRORS_ENUM.handlers.ERROR_PAGE,
  ),
});

export const ErrorPageRenderer = connect(mapStateToProps)(ErrorRenderer);
