// @ts-ignore
import { find } from '@riseart/fe-utils';
import { DIMENSIONS as ART_DIMENSIONS } from './art/dimensions';
import { DIMENSIONS as ARTIST_DIMENSIONS } from './artist/dimensions';
import { DIMENSIONS as COLLECTION_DIMENSIONS } from './collection/dimensions';

/**
 * getDimension
 *
 * @param {string} domain
 * @param {string} name
 * @returns {Record<string, any>}
 */
export function getDimension(domain: string, name: string): Record<string, any> {
  let dimensions: Record<string, any>[] = [];

  switch (domain) {
    case 'art':
      dimensions = ART_DIMENSIONS;
      break;
    case 'artist':
      dimensions = ARTIST_DIMENSIONS;
      break;
    case 'collection':
      dimensions = COLLECTION_DIMENSIONS;
      break;
    default:
      break;
  }
  return find(dimensions, (dimension: Record<string, any>): boolean => dimension.name === name);
}

/**
 * getDimensionValues
 *
 * @param {Record<string, any>[]} list
 * @param {boolean} includeChildren
 * @returns {string[] | null}
 */
export function getDimensionValues(
  list: Record<string, any>[],
  includeChildren = false,
): string[] | null {
  return (
    (list &&
      list.reduce((accumulator: string[], item: Record<string, any>) => {
        accumulator.push(item.name);

        if (includeChildren && item.children) {
          accumulator.push(item.children);
        }

        return accumulator;
      }, [])) ||
    null
  );
}
