import get from 'lodash/get';
import { store as STORE_CONFIG } from '../../../config/config.js';
import { RiseartLogger } from '../Logger';
import { ErrorService } from '../errors/ErrorService';
import { errorAdd } from '../../redux/actions/errors/errors';

/**
 * setHeaders
 *
 * @param reduxStore
 * @returns
 */
export function setHeaders(
  reduxStore: Record<string, any>,
  authorizationBearerPrefix = 'Bearer',
): (request: Record<string, any>) => Record<string, any> {
  return function setCommonHeaders(request: Record<string, any>) {
    const reduxState = reduxStore.getState();
    const token = get(reduxState, `${STORE_CONFIG.keys.auth}.token`);
    const locale = get(reduxState, `${STORE_CONFIG.keys.gui}.locale`);
    // map over current request headers and lowercase thir key names
    const currentHeaders = Object.keys(request.headers).reduce(
      (accumulator: Record<string, any>, headerKey: string) => ({
        ...accumulator,
        [headerKey.toLowerCase()]: request.headers[headerKey],
      }),
      {},
    );

    // Set headers if no headers set in request
    request.headers['Accept-Language'] = currentHeaders['accept-language'] || locale;
    request.headers['Content-Type'] = currentHeaders['content-type'] || 'application/json';
    request.headers['Authorization'] =
      currentHeaders['authorization'] || `${authorizationBearerPrefix} ${token}`;

    return request;
  };
}

/**
 * logRequest
 *
 * @param {Record<string, any>} request
 * @returns {Record<string, any>}
 */
export function logRequest(request: Record<string, any>): Record<string, any> {
  RiseartLogger.groupedLog(
    `[API][${request && request.method && request.method.toUpperCase()}] Request to ${request.url}`,
    request,
  );

  return request;
}

/**
 * logResponse
 *
 * @param {Record<string, any>} response
 * @returns {Record<string, any>}
 */
export function logResponse(response: Record<string, any>): Record<string, any> {
  const { config, ...rest } = response;
  RiseartLogger.groupedLog(
    `[API][${config && config.method && config.method.toUpperCase()}] Result from ${config.url}`,
    {
      config,
      ...rest,
    },
  );

  return response;
}

/**
 * handleError
 *
 * @param {Record<string, any>} reduxStore
 * @returns {(error: Record<string, any>) => void}
 */
export function handleError(reduxStore: Record<string, any>) {
  return (error: Record<string, any>): Promise<any> | void => {
    // Log the error
    logResponse(error);

    // Handle error
    const { errorHandler, errorFilter = (i: Record<string, any>[]): Record<string, any>[] => i } =
      (error && error.response && error.response.__context) || {};
    const filteredError = errorFilter(error.response);

    // ErrorHandler provided in operation context for custom error handling logic
    if (errorHandler) {
      const { shouldOverwriteDefaultHandler } = errorHandler(error);

      if (shouldOverwriteDefaultHandler === true) {
        return;
      }
    }

    // Dispatch error to store
    if (filteredError) {
      reduxStore.dispatch(errorAdd(ErrorService.mapAxiosError(filteredError)));
    }

    return Promise.reject(error);
  };
}
