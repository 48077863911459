import { DataTableColumnsType, LAYOUT_PAPER_PADDING } from '@riseart/dashboard';
import { GridColumns } from '@mui/x-data-grid';
import { DataGrid } from '../../common/data/Grid';
import { DataListFeed } from '../../data/ListFeed';
import {
  convertFiltersToApiPayload,
  convertSortToApiPayload,
} from '../../data/tables/filters/utils';

type Props = {
  controlled?: boolean;
  endpoint: string;
  endpointParams?: Record<string, any> | null;
  columns?: DataTableColumnsType;
  filterConfig: Record<string, any>[];
  sortConfig: Record<string, any>[];
  schema: GridColumns<any>;
  customData?: Record<string, any> | null;
  itemsPerPage?: number;
  itemMapper?: (item: Record<string, any>) => Record<string, any>;
  onSelect?: ((data: any) => any) | null;
  initialState?: Record<string, any>;
  inputParams?: Record<string, any>;
  onChangeParams?: ((params: any) => any) | undefined;
};

/**
 * DataList
 *
 * @param {Props} props
 * @returns
 */
export function DataList({
  controlled,
  endpoint,
  endpointParams = null,
  filterConfig,
  sortConfig,
  schema,
  itemMapper,
  onSelect,
  customData = null,
  itemsPerPage = 10,
  initialState,
  inputParams,
  onChangeParams,
}: Props): JSX.Element {
  return (
    <DataListFeed
      controlled={controlled}
      endpoint={endpoint}
      itemsPerPage={itemsPerPage}
      initialState={initialState}
      inputParams={inputParams}
      variablesMapper={({
        page = 1,
        itemsPerPage,
        sort,
        filters,
        ...qsParams
      }: Record<string, any>): Record<string, any> => {
        const combinedFilters =
          filters || (inputParams && inputParams.filters)
            ? { ...(filters || (inputParams && inputParams.filters)) }
            : null;

        if (combinedFilters && combinedFilters.columnField === '_selectedItems') {
          combinedFilters.columnField = 'id';
        }

        return {
          page,
          items: itemsPerPage || 10,
          ...endpointParams,
          ...convertSortToApiPayload(sort || (inputParams && inputParams.sort), sortConfig),
          ...convertFiltersToApiPayload(combinedFilters, filterConfig),
          ...qsParams,
        };
      }}
      onChangeParams={onChangeParams}
    >
      {({
        loading,
        items,
        pagination,
        qsParams,
        handleParamsChange,
        refetchListQuery,
      }: Record<string, any>) => {
        return (
          <DataGrid
            layoutPaperPadding={LAYOUT_PAPER_PADDING}
            columns={schema}
            rows={
              items &&
              (itemMapper ? items.map(itemMapper) : items).map((item: Record<string, any>) => ({
                ...item,
                rowData: item,
                customData: { ...customData, refetchListQuery, onSelect },
              }))
            }
            pagination={pagination}
            paramsState={qsParams}
            onChange={(params) => {
              handleParamsChange(params);
            }}
            loading={loading}
          />
        );
      }}
    </DataListFeed>
  );
}
