import querystring from 'query-string';
import { stringifyHash } from '@riseart/fe-utils';
import { assembleUrl } from './Route';
import {
  author as AUTHOR_ENUM,
  event as EVENT_ENUM,
  seller as SELLER_ENUM,
  user as USER_ENUM,
} from '../../../config/enumeration.js';

/**
 * UrlAssembler
 */
export const UrlAssembler: Record<string, any> = {
  locale: null,

  /**
   * config
   *
   * @param {Record<string, any>} locale
   * @returns {void}
   */
  config(locale: Record<string, any>): void {
    UrlAssembler.locale = locale;
  },

  /**
   * mergeOptions
   *
   * @param {Record<string, any>} options
   * @returns {Record<string, any>}
   */
  mergeOptions(options: Record<string, any> = {}): Record<string, any> {
    const { search, hash, locale } = options;
    const parsedSearch = typeof search === 'string' ? querystring.parse(search) : search;
    const urlOptions = {
      search: parsedSearch && querystring.stringify(parsedSearch),
      hash: hash && stringifyHash(hash),
    };

    return {
      ...options,
      ...(urlOptions.search ? { search: `?${urlOptions.search}` } : null),
      ...(urlOptions.hash ? { hash: urlOptions.hash } : null),
      locale: locale || UrlAssembler.locale,
    };
  },

  /**
   * byRouteKey
   *
   * @param {string} routeKey
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  byRouteKey(routeKey: string, options?: Record<string, any>): string | null {
    switch (routeKey) {
      default: {
        return assembleUrl(routeKey, UrlAssembler.mergeOptions(options));
      }
    }
  },

  /**
   * artList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artList(options: Record<string, any> = {}): string | null {
    return assembleUrl('artList', UrlAssembler.mergeOptions(options));
  },

  /**
   * addArtwork
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  addArtwork(options: Record<string, any> = {}): string | null {
    return assembleUrl('artAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * artDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('artDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'skus', subtab: null, ...params },
    });
  },

  /**
   * artEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('artEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * artistList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistList(options: Record<string, any> = {}): string | null {
    return assembleUrl('artistList', UrlAssembler.mergeOptions(options));
  },

  /**
   * artistDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('artistDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'details', subtab: null, ...params },
    });
  },

  /**
   * artistAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('artistAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * artistEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  artistEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('artistEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * partnerList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  partnerList(options: Record<string, any> = {}): string | null {
    return assembleUrl('partnerList', UrlAssembler.mergeOptions(options));
  },

  /**
   * partnerDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  partnerDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('partnerDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'artists', subtab: null, ...params },
    });
  },

  /**
   * partnerAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  partnerAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('partnerAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * partnerEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  partnerEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('partnerEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * sellerList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  sellerList(options: Record<string, any> = {}): string | null {
    return assembleUrl('sellerList', UrlAssembler.mergeOptions(options));
  },

  /**
   * sellerDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  sellerDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('sellerDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'artworks', subtab: null, ...params },
    });
  },

  /**
   * sellerAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  sellerAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('sellerAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * sellerEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  sellerEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('sellerEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * sellerEntityDetails
   *
   * @param {number} type
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  sellerEntityDetails(
    type: number,
    id: number | string,
    params: Record<string, any> = {},
  ): string | null {
    switch (type) {
      case SELLER_ENUM.type.ARTIST:
        return UrlAssembler.artistDetails(id, params);
      case SELLER_ENUM.type.GALLERY:
        return UrlAssembler.partnerDetails(id, params);
      case SELLER_ENUM.type.MANAGED:
      default:
        return null;
    }
  },

  /**
   * shippingList
   *
   * @param {number | string} sellerId
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  shippingList(sellerId: number | string, options: Record<string, any> = {}): string | null {
    return this.sellerDetails(sellerId, {
      ...options,
      params: { tab: 'shipping', subtab: null, ...(options.params || null) },
    });
  },

  /**
   * shippingRates
   *
   * @param {number | string} sellerId
   * @param {number | string} tableId
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  shippingRates(
    sellerId: number | string,
    tableId: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('sellerShippingRates', {
      ...UrlAssembler.mergeOptions(options),
      params: { id: sellerId, tableId, ...params },
    });
  },

  /**
   * collectionList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  collectionList(options: Record<string, any> = {}): string | null {
    return assembleUrl('collectionList', UrlAssembler.mergeOptions(options));
  },

  /**
   * collectionDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  collectionDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('collectionDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'details', subtab: null, ...params },
    });
  },

  /**
   * collectionAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  collectionAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('collectionAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * collectionEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  collectionEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('collectionEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * articleList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  articleList(options: Record<string, any> = {}): string | null {
    return assembleUrl('articleList', UrlAssembler.mergeOptions(options));
  },

  /**
   * articleDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  articleDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('articleDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'details', subtab: null, ...params },
    });
  },

  /**
   * articleAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  articleAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('articleAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * articleEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  articleEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('articleEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * articleEditBody
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  articleEditBody(
    id: number | string,
    locale: string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('articleEditBody', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, locale, ...params },
    });
  },

  /**
   * userList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  userList(options: Record<string, any> = {}): string | null {
    return assembleUrl('userList', UrlAssembler.mergeOptions(options));
  },

  /**
   * userDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  userDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return (
      assembleUrl('userDetailsTabs', {
        ...(UrlAssembler.mergeOptions(options) || null),
        params: { id, tab: 'accounts', subtab: null, ...params },
      }) || ''
    );
  },

  /**
   * userEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  userEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('userEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * eventList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  eventList(options: Record<string, any> = {}): string | null {
    return assembleUrl('eventList', UrlAssembler.mergeOptions(options));
  },

  /**
   * eventDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  eventDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('eventDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'related', subtab: null, ...params },
    });
  },

  /**
   * eventAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  eventAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('eventAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * eventEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  eventEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('eventEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * eventCreator
   *
   * @param {string} type
   * @param {string} role
   * @param {number | string}id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  eventCreator(
    type: string,
    role: string,
    id: number | string,
    params: Record<string, any> = {},
  ): string | null {
    switch (type) {
      case EVENT_ENUM.creator.ADMIN:
        return null;
      case EVENT_ENUM.creator.USER:
        return role === USER_ENUM.role.ARTIST
          ? UrlAssembler.artistDetails(id, params)
          : UrlAssembler.userDetails(id, params);
      case EVENT_ENUM.creator.PARTNER:
        return UrlAssembler.partnerDetails(id, params);
      default:
        return null;
    }
  },

  /**
   * fileList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  fileList(options: Record<string, any> = {}): string | null {
    return assembleUrl('fileList', UrlAssembler.mergeOptions(options));
  },

  /**
   * fileDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  fileDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('fileDetails', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * fileAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  fileAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('fileAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * fileEdit
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  fileEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('fileEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * authorList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  authorList(options: Record<string, any> = {}): string | null {
    return assembleUrl('authorList', UrlAssembler.mergeOptions(options));
  },

  /**
   * authorDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  authorDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('authorDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'details', ...params },
    });
  },

  /**
   * authorAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  authorAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('authorAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * authorEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  authorEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('authorEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * authorType
   *
   * @param {string} type
   * @param {string} role
   * @param {number | string}id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  authorType(type: string, id: number | string, params: Record<string, any> = {}): string | null {
    switch (type) {
      case AUTHOR_ENUM.type.ARTIST:
        return UrlAssembler.artistDetails(id, params);
      case AUTHOR_ENUM.type.USER:
        return UrlAssembler.userDetails(id, params);
      case EVENT_ENUM.creator.PARTNER:
        return UrlAssembler.partnerDetails(id, params);
      case AUTHOR_ENUM.type.ADMIN:
      default:
        return null;
    }
  },

  /**
   * system
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  system(options: Record<string, any> = {}): string | null {
    return this.cacheList(options);
  },

  /**
   * cacheList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cacheList(options: Record<string, any> = {}): string | null {
    return assembleUrl('cache', UrlAssembler.mergeOptions(options));
  },

  /**
   * articleCategoryList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  articleCategoryList(options: Record<string, any> = {}): string | null {
    return assembleUrl('articleCategories', UrlAssembler.mergeOptions(options));
  },

  /**
   * articleCategoryDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  articleCategoryDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('articleCategoryDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'details', subtab: null, ...params },
    });
  },

  /**
   * articleCategoryAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  articleCategoryAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('articleCategoryAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * articleCategoryEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  articleCategoryEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('articleCategoryEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * adminList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  adminList(options: Record<string, any> = {}): string | null {
    return assembleUrl('admin', UrlAssembler.mergeOptions(options));
  },

  /**
   * adminDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  adminDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('adminDetails', {
      ...UrlAssembler.mergeOptions(options),
      params: { ...params, id },
    });
  },

  /**
   * adminAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  adminAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('adminAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * adminEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  adminEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('adminEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * settingsList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  settingsList(options: Record<string, any> = {}): string | null {
    return assembleUrl('settings', UrlAssembler.mergeOptions(options));
  },

  /**
   * settingsDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  settingsDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('settingsDetails', {
      ...UrlAssembler.mergeOptions(options),
      params: { ...params, id },
    });
  },

  /**
   * settingsAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  settingsAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('settingsAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * settingsEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  settingsEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('settingsEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * cmsModuleInstanceList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsModuleInstanceList(options: Record<string, any> = {}): string | null {
    return assembleUrl('cmsModuleInstanceList', UrlAssembler.mergeOptions(options));
  },

  /**
   * cmsModuleInstanceDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsModuleInstanceDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('cmsModuleInstanceDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'attributes', subtab: null, ...params },
    });
  },

  /**
   * cmsModuleInstanceAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsModuleInstanceAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('cmsModuleInstanceAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * cmsModuleInstanceEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsModuleInstanceEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('cmsModuleInstanceEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * cmsModuleList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsModuleList(options: Record<string, any> = {}): string | null {
    return assembleUrl('cmsModuleList', UrlAssembler.mergeOptions(options));
  },

  /**
   * cmsModuleDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsModuleDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('cmsModuleInstanceDetails', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'attributes', subtab: null, ...params },
    });
  },

  /**
   * cmsModuleAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsModuleAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('cmsModuleAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * cmsModuleEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsModuleEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('cmsModuleEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * cmsViewList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsViewList(options: Record<string, any> = {}): string | null {
    return assembleUrl('cmsViewList', UrlAssembler.mergeOptions(options));
  },

  /**
   * cmsViewDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsViewDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('cmsViewDetails', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'placeholders', subtab: null, ...params },
    });
  },

  /**
   * cmsViewAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsViewAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('cmsViewAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * cmsViewEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsViewEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('cmsViewEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * cmsPageList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsPageList(options: Record<string, any> = {}): string | null {
    return assembleUrl('cmsPageList', UrlAssembler.mergeOptions(options));
  },

  /**
   * cmsPageDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsPageDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('cmsPageDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'details', subtab: null, ...params },
    });
  },

  /**
   * cmsPageAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsPageAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('cmsPageAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * cmsPageEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  cmsPageEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('cmsPageEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * offerList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  offerList(options: Record<string, any> = {}): string | null {
    return assembleUrl('offerList', UrlAssembler.mergeOptions(options));
  },

  /**
   * offerDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  offerDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('offerDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'logs', subtab: null, ...params },
    });
  },

  /**
   * offerAdd
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  offerAdd(options: Record<string, any> = {}): string | null {
    return assembleUrl('offerAdd', UrlAssembler.mergeOptions(options));
  },

  /**
   * offerEdit
   *
   * @param {number | string} id
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  offerEdit(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('offerEdit', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, ...params },
    });
  },

  /**
   * affiliateList
   *
   * @param {Record<string, any>} options
   * @returns {string | null}
   */
  affiliateList(options: Record<string, any> = {}): string | null {
    return assembleUrl('affiliateList', UrlAssembler.mergeOptions(options));
  },

  /**
   * affiliateDetails
   *
   * @param {number | string} id
   * @param {Record<string, any>} params
   * @returns {string | null}
   */
  affiliateDetails(
    id: number | string,
    { params = null, ...options }: Record<string, any> = {},
  ): string | null {
    return assembleUrl('affiliateDetailsTabs', {
      ...(UrlAssembler.mergeOptions(options) || null),
      params: { id, tab: 'artworks', subtab: null, ...params },
    });
  },

  /**
   * rootImageUrl
   *
   * @param {Record<string, any>} properties
   * @param {string} host
   * @returns {string}
   */
  rootImageUrl({ key, extension }: Record<string, any>, host: string): string {
    return `${host}/${key.replace(/(_)/g, '/')}.${extension}`;
  },
};
