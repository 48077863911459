import {
  Article as ArticleBaseModel,
  ArticlePaginationResult as ArticlePaginationResultBaseModel,
  ArticleCategory as ArticleCategoryBaseModel,
  ArticleLocale as ArticleLocaleBaseModel,
  ArticleRelation as ArticleRelationBaseModel,
  ArticleCategoryListResult as ArticleCategoryListResultBaseModel,
  ArticleCategoryPaginationResult as ArticleCategoryPaginationResultBaseModel,
  ArticleRelationPaginationResult as ArticleRelationPaginationResultBaseModel,
} from '@riseart/models';
import { article as ARTICLE_ENUM } from '../../config/enumeration.js';
import { ApiInvalidData } from '../errors/ApiInvalidData';
import { ImageHierarchicalArray } from './Image';
import { FormDataMixin, LocaleMixin } from './Core';
import { Author } from './Author';
import { PaginationInfo } from './List';

/**
 * ArticleCategoryLocale
 */
class ArticleCategoryLocale extends ArticleBaseModel {
  id: number;
  localeCode: string;
  name: string;
  uri: string;
  description: string;

  /**
   * constructor
   */
  constructor() {
    super();
  }
}

/**
 * ArticleCategory
 */
class ArticleCategory extends FormDataMixin(
  LocaleMixin(ArticleCategoryBaseModel, ArticleCategoryLocale),
) {
  id: number;
  type: string;
  name: string;
  uri: string;
  description: string;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  pageTitle: string;
  locales: Array<ArticleCategoryLocale>;

  /**
   * Constructor
   */
  constructor() {
    super();
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {Article}
   */
  public hydrateFromFormData(formData: Record<string, any>): Article {
    return this.hydrate({ id: this.id, ...formData });
  }

  /**
   * hydrateFromLocaleFormData
   *
   * @param {Record<string, any>} formData
   * @param {string} localeCode
   * @returns {ArtistLocale}
   */
  public hydrateFromLocaleFormData(
    formData: Record<string, any>,
    localeCode: string,
  ): ArticleCategoryLocale {
    const { uri, ...restFormData } = formData;
    const existingLocale = this.locales.find(
      (localeItem: Record<string, any>) => localeItem.localeCode === localeCode,
    );

    const SHOULD_GENERATE_ID = !restFormData.id;
    const NewLocale = new ArticleCategoryLocale().hydrate(
      { localeCode, ...(uri ? { uri } : null), ...restFormData },
      SHOULD_GENERATE_ID,
    );

    this.locales = existingLocale
      ? this.locales.map((localeItem: ArticleCategoryLocale) => {
          if (localeItem.localeCode === localeCode) {
            return NewLocale;
          }

          return localeItem;
        })
      : [...this.locales, NewLocale];

    return NewLocale;
  }

  /**
   * toFormData
   *
   * @param {Record<string, any>[]} formFields
   * @returns {Record<string, any>}
   */
  public toFormData(formFields: Record<string, any>[]): Record<string, any> {
    return {
      ...formFields.reduce((accumulator, { name }) => {
        return {
          ...accumulator,
          [name]: typeof this[name] !== 'undefined' ? this[name] : null,
        };
      }, {}),
    };
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const mappedData = super.mapFormToApiData(data, fieldsSchema, includeNullValues);
    // eslint-disable-next-line
    const { locales, ...restData } = mappedData;
    return restData;
  }
}

/**
 * ArticleLocale
 */
class ArticleLocale extends ArticleLocaleBaseModel {
  id: number;
  localeCode: string;
  slug: string;
  actionText: string;
  actionLink: string;
  title: string;
  summary: string;
  text: string;

  /**
   * constructor
   */
  constructor() {
    super();
  }
}

/**
 * Article
 */
class Article extends FormDataMixin(LocaleMixin(ArticleBaseModel, ArticleLocale)) {
  actionText: string;
  actionLink: string;
  author: Author;
  categories: Array<ArticleCategory>;
  id: number;
  images: ImageHierarchicalArray;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  pageTitle: string;
  publishedDate: string;
  slug: string;
  status: number;
  summary: string;
  tags: Array<string>;
  text: string;
  title: string;
  type: string;
  locales: Array<ArticleLocale>;

  /**
   * Constructor
   */
  constructor() {
    super();
  }

  /**
   * hydrateFromFormData
   *
   * @param {Record<string, any>} formData
   * @returns {Article}
   */
  public hydrateFromFormData(formData: Record<string, any>): Article {
    return this.hydrate({ id: this.id, ...formData });
  }

  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): Article {
    try {
      const { locales, author: authorData, images: imagesList, ...collectionData } = data || {};

      // Locales
      super.hydrateFromApiData({ locales });

      // Author
      const author: Author | null = authorData ? new Author().hydrateFromApiData(authorData) : null;

      // Images
      const images: ImageHierarchicalArray = new ImageHierarchicalArray().hydrateFromApiData(
        imagesList || [],
      );

      return this.hydrate({
        images,
        author,
        ...collectionData,
      });
    } catch (error) {
      throw new ApiInvalidData('Unable to load Collection from provided data');
    }
  }

  /**
   * mapFormToApiData
   *
   * @param {Record<string, any>} data
   * @param {Record<string, any>[]} fieldsSchema
   * @param {boolean} includeNullValues
   * @returns {Record<string, any>}
   */
  public static mapFormToApiData(
    data: Record<string, any>,
    fieldsSchema: Record<string, any>[],
    includeNullValues = true,
  ): Record<string, any> {
    const mappedData = super.mapFormToApiData(data, fieldsSchema, includeNullValues);
    // eslint-disable-next-line
    const { locales, ...restData } = mappedData;
    return restData;
  }

  /**
   * hydrateFromLocaleFormData
   *
   * @param {Record<string, any>} formData
   * @param {string} localeCode
   * @returns {ArtistLocale}
   */
  public hydrateFromLocaleFormData(
    formData: Record<string, any>,
    localeCode: string,
  ): ArticleLocale {
    const { slug, ...restFormData } = formData;
    const existingLocale = this.locales.find(
      (localeItem: Record<string, any>) => localeItem.localeCode === localeCode,
    );

    const SHOULD_GENERATE_ID = !restFormData.id;
    const NewLocale = new ArticleLocale().hydrate(
      { localeCode, ...(slug ? { slug } : null), ...restFormData },
      SHOULD_GENERATE_ID,
    );

    this.locales = existingLocale
      ? this.locales.map((localeItem: ArticleLocale) => {
          if (localeItem.localeCode === localeCode) {
            return NewLocale;
          }

          return localeItem;
        })
      : [...this.locales, NewLocale];

    return NewLocale;
  }

  /**
   * toFormData
   *
   * @param {Record<string, any>[]} formFields
   * @returns {Record<string, any>}
   */
  public toFormData(formFields: Record<string, any>[]): Record<string, any> {
    return {
      ...formFields.reduce((accumulator, { name }) => {
        if (name === 'authorId') {
          return {
            ...accumulator,
            [name]: (this.author && this.author.id) || null,
          };
        }

        if (name === 'tags') {
          return {
            ...accumulator,
            [name]:
              typeof this.tags === 'string'
                ? this.tags
                : (Array.isArray(this.tags) && this.tags.join(', ')) || null,
          };
        }

        return {
          ...accumulator,
          [name]: typeof this[name] !== 'undefined' ? this[name] : null,
        };
      }, {}),
    };
  }

  /**
   * isPublished
   *
   * @returns {boolean}
   */
  public get isPublished(): boolean {
    return this.status === ARTICLE_ENUM.status.PUBLISHED;
  }
}

/**
 * ArticlePaginationResult
 */
class ArticlePaginationResult extends ArticlePaginationResultBaseModel {
  pagination: PaginationInfo;

  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArticlePaginationResult {
    try {
      const { items, ...paginationData } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const ArticleModel = new Article();
            ArticleModel.hydrateFromApiData(item);

            return ArticleModel;
          })
        : {};

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      return this.hydrate({ items: listData, pagination });
    } catch (error) {
      throw new ApiInvalidData('Unable to load ArticlePaginationResult from provided data.');
    }
  }
}

/**
 * ArticleCategoryListResult
 */
class ArticleCategoryListResult extends ArticleCategoryListResultBaseModel {
  /**
   * constructor
   */
  constructor() {
    super();
  }
}

/**
 * ArticleCategoryPaginationResult
 */
class ArticleCategoryPaginationResult extends ArticleCategoryPaginationResultBaseModel {
  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArticleCategoryPaginationResult {
    try {
      const { items, ...paginationData } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const ArticleCategoryModel = new ArticleCategory();
            ArticleCategoryModel.hydrateFromApiData(item);

            return ArticleCategoryModel;
          })
        : {};

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      return this.hydrate({ items: listData, pagination });
    } catch (error) {
      throw new ApiInvalidData(
        'Unable to load ArticleCategoryPaginationResult from provided data.',
      );
    }
  }
}

/**
 * ArticleRelation
 */
class ArticleRelation extends ArticleRelationBaseModel {
  id: number;
  articleId: number;
  relationType: string;
  relationId: number;
  relationName: string;
  relationSlug: string;
  showInRelation: boolean;

  /**
   * Constructor
   */
  constructor() {
    super();
  }
}

/**
 * ArticleRelationPaginationResult
 */
class ArticleRelationPaginationResult extends ArticleRelationPaginationResultBaseModel {
  items: Array<ArticleRelation>;
  pagination: PaginationInfo;

  /**
   * hydrateFromApiData
   *
   * @param {data?: Record<string, any>} data
   * @returns {this}
   */
  public hydrateFromApiData(data?: Record<string, any>): ArticleRelationPaginationResult {
    try {
      const { items, ...paginationData } = data || {};

      const listData = items
        ? items.map((item: Record<string, any>) => {
            const ArticleRelationModel = new ArticleRelation();
            ArticleRelationModel.hydrate(item);

            return ArticleRelationModel;
          })
        : {};

      // Pagination
      const pagination: PaginationInfo = new PaginationInfo().hydrateFromApiData(paginationData);

      return this.hydrate({ items: listData, pagination });
    } catch (error) {
      throw new ApiInvalidData(
        'Unable to load ArticleRelationPaginationResult from provided data.',
      );
    }
  }
}

export {
  ArticleCategoryLocale,
  ArticleCategory,
  ArticleLocale,
  Article,
  ArticlePaginationResult,
  ArticleCategoryListResult,
  ArticleCategoryPaginationResult,
  ArticleRelationPaginationResult,
};
