import { FormattedMessage } from 'react-intl';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { Link } from '@riseart/dashboard';
import { DEFAULT_COLUMN_PROPS, dataPickerActionColumn } from '../utils';
import { UrlAssembler } from '../../../../services/riseart/utils/UrlAssembler';

export const AUTHOR_BASE_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage, showNameAsLink = false }) => [
  {
    field: 'id',
    type: 'number',
    headerName: formatMessage({ id: 'components.tables.columns.common.id' }),
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => params.value,
  },
  {
    ...DEFAULT_COLUMN_PROPS,
    field: 'fullName',
    type: 'string',
    flex: 1,
    headerName: formatMessage({ id: 'components.tables.columns.common.name' }),
    renderCell: ({ row }: Record<string, any>) =>
      showNameAsLink ? (
        <Link to={UrlAssembler.authorDetails(row.id)} title={row.fullName}>
          {row.fullName}
        </Link>
      ) : (
        row.fullName
      ),
  },
  {
    field: 'type',
    type: 'string',
    minWidth: 150,
    filterable: false,
    sortable: false,
    headerName: formatMessage({ id: 'components.tables.columns.common.type' }),
    renderCell: ({ row: data }: Record<string, any>) =>
      data && data.type ? <FormattedMessage id={`components.author.type.${data.type}`} /> : null,
  },
];

export const AUTHOR_DATAGRID_TABLE_SCHEMA: (
  properties: Record<string, any>,
) => GridColumns<any> = ({ formatMessage }) => [
  ...AUTHOR_BASE_DATAGRID_TABLE_SCHEMA({ formatMessage }),
  dataPickerActionColumn(formatMessage),
];
