export const FILTER_CONFIG = [
  { columnField: 'id', type: 'integer' },
  { columnField: 'type', apiField: 'type', type: 'discrete', filterType: 'basic' },
  { columnField: 'email', apiField: 'email', type: 'string' },
  { columnField: 'name', apiField: 'name', type: 'string' },
  { columnField: 'city', apiField: 'city', type: 'string' },
  {
    columnField: 'status',
    apiField: 'status',
    type: 'discrete',
    filterType: 'basic',
  },
  { columnField: 'country', apiField: 'country', type: 'discrete' },
];

export const SORT_CONFIG = [
  { columnField: 'id' },
  { columnField: 'type' },
  { columnField: 'email' },
  { columnField: 'name' },
  { columnField: 'city' },
  { columnField: 'country' },
];
